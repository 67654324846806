import { ImportExport, Search } from "@mui/icons-material";
import {
  Box,
  Typography,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Button,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs from "dayjs";
import React from "react";
import SurveyFormTable from "../approve-form/components/SurveyFormTable";

const DailyExportView = (props) => {
  const {
    handleGateStatusChange,
    gateStatus,
    handleSelectDateChange,
    handleSearchSurvey,
    selectDate,
    surveyList,
    tableColumns,
    clickViewSurveyFormDetail,
    onClickExportExcel,
  } = props;
  return (
    <Box
      sx={{
        flexGrow: 1,
        p: 3,
        backgroundColor: "white",
        minHeight: "100vh",
        overflow: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            "@media (max-width: 720px)": {
              flexDirection: "column",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              "@media (max-width: 720px)": {
                flexDirection: "row",
                justifyContent: "flex-start",
              },
            }}
          >
            <Typography
              fontWeight={"bold"}
              fontSize={24}
              borderBottom="2px solid #EFC531"
              display="inline"
            >
              ระบบ Export รายวัน
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          flexDirection: "row",
          marginTop: "1rem",
          "@media (max-width: 720px)": {
            flexDirection: "column",
          },
        }}
      >
        <FormControl
          sx={{
            width: "40%",
            "@media (max-width: 720px)": {
              width: "100%",
            },
          }}
        >
          <InputLabel id="surveyStatus">เลือกสถานะของ Survey</InputLabel>
          <Select
            labelId="surveyStatus"
            id="surveyStatus"
            value={gateStatus}
            label="เลือกสถานะ Survey"
            onChange={handleGateStatusChange}
          >
            <MenuItem value={"in"}>Gate in</MenuItem>
            <MenuItem value={"out"}>Gate out</MenuItem>
          </Select>
        </FormControl>

        <DesktopDatePicker
          label="วันที่เริ่มต้น"
          onChange={handleSelectDateChange}
          format="DD/MM/YYYY"
          sx={{
            width: "40%",
            marginLeft: "1%",
            "@media (max-width: 720px)": {
              width: "100%",
              marginLeft: 0,
              marginTop: "1%",
            },
          }}
        />
      </Box>
      <Button
        onClick={handleSearchSurvey}
        variant="contained"
        startIcon={<Search />}
        sx={{
          marginTop: 2,
          marginBottom: 5,
          backgroundColor: "#f1d15c",
          "&:hover": {
            backgroundColor: "#f6e299",
          },
          color: "black",
          width: "25%",
        }}
      >
        Search
      </Button>
      {surveyList.length > 0 && (
        <Box>
          <Typography fontWeight={"bold"}>
            ข้อมูล {gateStatus === "in" ? "Gate In " : "Gate Out"} วันที่{" "}
            {dayjs(selectDate).format("DD/MM/YYYY")} พบ {surveyList.length}{" "}
            รายการ
          </Typography>
          <SurveyFormTable
            listSurveyForm={surveyList}
            tableColumns={tableColumns}
            clickViewDetail={clickViewSurveyFormDetail}
          />
          <Button
            variant="contained"
            startIcon={<ImportExport />}
            sx={{
              marginLeft: 2,
              marginTop: 2,
              marginBottom: 5,
              backgroundColor: "#1F6E42",
              "&:hover": {
                backgroundColor: "#1F6E42",
              },
              color: "white",
              width: "25%",
            }}
            onClick={onClickExportExcel}
          >
            Export
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default DailyExportView;
