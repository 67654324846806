import React from "react";
import {
  Box,
  Typography,
  Divider,
  FormControlLabel,
  Checkbox,
  TextField,
  RadioGroup,
  FormLabel,
  Radio,
} from "@mui/material";

const OutsideForm = (props) => {
  const {
    outsideFormFields,
    handleOutsideFieldChange,
    handleLayPaper,
    handleRemarkLayPaper,
    handleStatus,
    handleChasis,
    handleRemark,
  } = props;

  return (
    <Box
      sx={{
        width: "49%",
        backgroundColor: "white",
        margin: "0 1%",
        padding: "1rem",
        borderRadius: "8px",
        border: "1px solid #B9C0E0",
        "@media (max-width: 960px)": {
          width: "98%",
        },
      }}
    >
      <Typography
        fontWeight={"bold"}
        fontSize={16}
        margin={"1rem"}
        align="center"
      >
        OUTSIDE
      </Typography>
      <Divider sx={{ borderColor: "#B9C0E0" }} />
      {outsideFormFields.map(({ id, key, label }) => (
        <RadioGroup
          key={id}
          sx={{
            marginTop: "1rem",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            textAlign: "center",
            alignItems: "center",
            "@media (max-width: 1300px)": {
              flexDirection: "column",
              alignItems: "normal",
              textAlign: "unset",
            },
          }}
          defaultValue={"OK"}
          onChange={handleOutsideFieldChange(key)}
          required
        >
          <FormLabel>{label}</FormLabel>
          <Box>
            <FormControlLabel
              value="OK"
              control={<Radio />}
              label="OK"
              sx={{
                marginLeft: "2rem",
                "@media (max-width: 960px)": {
                  marginLeft: "1rem",
                },
              }}
            />
            <FormControlLabel
              value="FIX"
              control={<Radio />}
              label="FIX"
              sx={{
                marginLeft: "2rem",
                "@media (max-width: 960px)": {
                  marginLeft: "1rem",
                },
              }}
            />
          </Box>
        </RadioGroup>
      ))}
      <Divider sx={{ borderColor: "#EFF1F3" }} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          marginTop: "1rem",
          "@media (max-width: 780px)": {
            flexDirection: "column",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Typography>LAY PAPER</Typography>
          <FormControlLabel
            control={<Checkbox />}
            label="Yes"
            sx={{
              marginTop: "6px",
            }}
            onChange={handleLayPaper}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "2rem",
            "@media (max-width: 780px)": {
              flexDirection: "column",
              marginTop: "1rem",
              marginLeft: "0",
            },
          }}
        >
          <Typography>Ramark LAY PAPER</Typography>
          <TextField
            variant="outlined"
            sx={{
              width: "100%",
            }}
            onChange={handleRemarkLayPaper}
          />
        </Box>
      </Box>
      <RadioGroup
        sx={{
          marginTop: "1rem",
          display: "flex",
          flexDirection: "row",
          textAlign: "center",
          alignItems: "center",
          "@media (max-width: 1300px)": {
            flexDirection: "column",
            alignItems: "normal",
            textAlign: "unset",
          },
        }}
        required
      >
        <FormLabel>Status</FormLabel>
        <Box>
          <RadioGroup onChange={handleStatus} defaultValue={"AV"}>
            <FormControlLabel
              value="AV"
              control={<Radio />}
              label="AV"
              sx={{
                marginLeft: "2rem",
                "@media (max-width: 960px)": {
                  marginLeft: "1rem",
                },
              }}
            />
            <FormControlLabel
              value="REWORK"
              control={<Radio />}
              label="REWORK"
              sx={{
                marginLeft: "2rem",
                "@media (max-width: 960px)": {
                  marginLeft: "1rem",
                },
              }}
            />
            <FormControlLabel
              value="REJECT"
              control={<Radio />}
              label="REJECT"
              sx={{
                marginLeft: "2rem",
                "@media (max-width: 960px)": {
                  marginLeft: "1rem",
                },
              }}
            />
          </RadioGroup>
        </Box>
      </RadioGroup>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          marginTop: "1rem",
          "@media (max-width: 780px)": {
            flexDirection: "column",
          },
        }}
      >
        {/* <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Typography>Chasis</Typography>
          <FormControlLabel
            control={<Checkbox />}
            label="OK"
            sx={{
              marginTop: "6px",
            }}
            onChange={handleChasis}
          />
        </Box> */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "2rem",
            "@media (max-width: 780px)": {
              flexDirection: "column",
              marginTop: "1rem",
              marginLeft: "0",
            },
          }}
        >
          <Typography>Remark</Typography>
          <TextField
            variant="outlined"
            sx={{
              width: "100%",
            }}
            onChange={handleRemark}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default OutsideForm;
