import React, { useState, useEffect } from "react";
import ExportView from "./ExportView";
import ApiHelper from "../../api/api_helper";
import LoadingIndicator from "../loading/LoadingIndicator";
import dayjs from "dayjs";
import { useDialog } from "../../context/dialog_context";
import * as XLSX from "xlsx";
import { useNavigate } from "react-router-dom";
const ExportController = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [booking, setBooking] = useState(null);
  const [shippingLir, setShippingLir] = useState(null);
  const [ci, setCi] = useState(null);
  const [cyDateStart, setCyDateStart] = useState(null);
  const [cyDateEnd, setCyDateEnd] = useState(null);
  const [loadingStart, setLoadingStart] = useState(null);
  const [loadingEnd, setLoadingEnd] = useState(null);
  const [containerNo, setContainerNo] = useState(null);

  const [bookingData, setBookingData] = useState([]);
  const [surveyData, setSurveyData] = useState([]);

  const { showDialog } = useDialog();
  const navigate = useNavigate();

  const handleChangeBooking = (value) => {
    setBooking(value);
  };

  const handleShippingLir = (value) => {
    setShippingLir(value);
  };

  const handleCi = (value) => {
    setCi(value);
  };

  const handleCyDateStart = (newValue) => {
    setCyDateStart(
      newValue ? dayjs(newValue).format("YYYY-MM-DD HH:mm:ss") : null
    );
  };
  const handleCyDateEnd = (newValue) => {
    setCyDateEnd(
      newValue ? dayjs(newValue).format("YYYY-MM-DD HH:mm:ss") : null
    );
  };

  const handleLoadingStart = (newValue) => {
    setLoadingStart(
      newValue ? dayjs(newValue).format("YYYY-MM-DD HH:mm:ss") : null
    );
  };

  const handleLoadingEnd = (newValue) => {
    setLoadingEnd(
      newValue ? dayjs(newValue).format("YYYY-MM-DD HH:mm:ss") : null
    );
  };

  const handleContainerNo = (value) => {
    setContainerNo(value);
  };

  const tableColumns = [
    { id: "1", label: "Cy Date", minWidth: 100, key: "cyDate" },
    { id: "2", label: "Loading Date", minWidth: 100, key: "loadingDate" },
    { id: "3", label: "Booking", minWidth: 100, key: "booking" },
    { id: "4", label: "Ci", minWidth: 100, key: "referrenceNo" },
    { id: "5", label: "การจัดการ", minWidth: 100, key: "Action" },
  ];

  const onClickSearch = async () => {
    try {
      setIsLoading(true);

      // Validate that at least one field must be filled
      const fields = [
        booking,
        shippingLir,
        ci,
        cyDateStart,
        cyDateEnd,
        loadingStart,
        loadingEnd,
        containerNo,
      ];
      const isAllFieldsEmpty = fields.every(
        (field) => field === null || field === ""
      );

      if (isAllFieldsEmpty) {
        throw new Error("Please fill in at least one field to search.");
      }

      const datePairs = [
        { start: cyDateStart, end: cyDateEnd, label: "CY Date" },
        { start: loadingStart, end: loadingEnd, label: "Loading Date" },
      ];

      for (let { start, end, label } of datePairs) {
        if ((start && !end) || (!start && end)) {
          throw new Error(`กรุณาเลือกวันจบ (${label})`);
        }
      }

      const response = await ApiHelper.get(
        `/booking/export?booking=${booking}&shippingLir=${shippingLir}&ci=${ci}&cyDateStart=${cyDateStart}&cyDateEnd=${cyDateEnd}&loadingStart=${loadingStart}&loadingEnd=${loadingEnd}&containerNo=${containerNo}`
      );

      if (response.status !== 200) {
        throw new Error("Failed to fetch data");
      }

      if (response.data.data.bookingList.length <= 0) {
        showDialog("ไม่พบข้อมูล", "ไม่พบข้อมูลที่จะ Export ในวันที่ดังกล่าว");
      } else {
        setBookingData(response.data.data.bookingList);
        setSurveyData(response.data.data.surveyList);
      }
    } catch (error) {
      showDialog("เกิดข้อผิดพลาด", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const onClickExportButton = async () => {
    setIsLoading(true); // Set loading to true while fetching data
    try {
      if (bookingData && bookingData.length > 0) {
        const workbook = XLSX.utils.book_new();
        const worksheet = {};

        // Initial setup for A1:T1 Title and A2:T2 Import Date
        worksheet["A1"] = { v: "Mahaporn Nakorn Group Co.,Ltd.", t: "s" };
        worksheet["A2"] = {
          v: "Date: " + dayjs().format("DD/MM/YYYY"),
          t: "s",
        };
        worksheet["!merges"] = [
          { s: { r: 0, c: 0 }, e: { r: 0, c: 19 } }, // Merge A1:T1
          { s: { r: 1, c: 0 }, e: { r: 1, c: 19 } }, // Merge A2:T2
        ];

        // Define headers
        const headers = [
          "No.",
          "Booking No.",
          "Booking Date",
          "Status",
          "Shipping Line",
          "Referrence No.",
          "CY Date",
          "Loading Date",
          "Closing Time",
          "CY Place",
          "Size",
          "Qty.",
        ];

        // Add and merge headers spanning two rows
        headers.forEach((header, index) => {
          const cell = { v: header, t: "s" };
          worksheet[XLSX.utils.encode_cell({ r: 2, c: index })] = cell;
          worksheet[XLSX.utils.encode_cell({ r: 3, c: index })] = cell;
          worksheet["!merges"].push({
            s: { r: 2, c: index },
            e: { r: 3, c: index },
          });
        });

        // Subheaders for "Move In"
        const subHeadersMoveIn = ["AV", "RW", "RJ", "In", "O/S"];
        const moveInStartCol = headers.length;
        worksheet[XLSX.utils.encode_cell({ r: 2, c: moveInStartCol })] = {
          v: "Move In",
          t: "s",
        };
        subHeadersMoveIn.forEach((header, index) => {
          worksheet[
            XLSX.utils.encode_cell({ r: 3, c: moveInStartCol + index })
          ] = { v: header, t: "s" };
        });
        worksheet["!merges"].push({
          s: { r: 2, c: moveInStartCol },
          e: { r: 2, c: moveInStartCol + subHeadersMoveIn.length - 1 },
        });

        // Subheaders for "Move Out"
        const subHeadersMoveOut = ["Out", "O/S"];
        const moveOutStartCol = moveInStartCol + subHeadersMoveIn.length;
        worksheet[XLSX.utils.encode_cell({ r: 2, c: moveOutStartCol })] = {
          v: "Move Out",
          t: "s",
        };
        subHeadersMoveOut.forEach((header, index) => {
          worksheet[
            XLSX.utils.encode_cell({ r: 3, c: moveOutStartCol + index })
          ] = { v: header, t: "s" };
        });
        worksheet["!merges"].push({
          s: { r: 2, c: moveOutStartCol },
          e: { r: 2, c: moveOutStartCol + subHeadersMoveOut.length - 1 },
        });

        // Header for "Location"
        const locationCol = moveOutStartCol + subHeadersMoveOut.length;
        worksheet[XLSX.utils.encode_cell({ r: 2, c: locationCol })] = {
          v: "Location",
          t: "s",
        };
        worksheet["!merges"].push({
          s: { r: 2, c: locationCol },
          e: { r: 3, c: locationCol },
        });

        // Define worksheet range to include headers
        worksheet["!ref"] = XLSX.utils.encode_range({
          s: { c: 0, r: 0 },
          e: { c: locationCol, r: 3 }, // Extend range to last header column and row
        });

        const formattedData = bookingData.map((item, index) => [
          index + 1, // No.
          item.booking,
          dayjs(item.importDate).format("DD/MM/YYYY"),
          item.bookingStatus,
          item.shippingLir,
          item.referrenceNo,
          dayjs(item.cyDate).format("DD/MM/YYYY"),
          dayjs(item.loadingDate).format("DD/MM/YYYY"),
          dayjs(item.closingDate).format("DD/MM/YYYY").toString() +
            " " +
            item.closingTime.substring(0, item.closingTime.length - 3),
          item.cyPlace,
          item.size,
          item.qty,
          item.AV,
          item.RW,
          item.RJ,
          item.In,
          parseInt(item.qty) - parseInt(item.In) > 0
            ? parseInt(item.qty) - parseInt(item.In)
            : 0,
          item.Out,
          parseInt(item.In) - parseInt(item.Out) > 0
            ? parseInt(item.In) - parseInt(item.Out)
            : 0,
          "MHP",
        ]);
        XLSX.utils.sheet_add_aoa(worksheet, formattedData, { origin: -1 });

        // Append worksheet and save
        XLSX.utils.book_append_sheet(workbook, worksheet, "Summary");

        const worksheet2 = setUpDetailWorkSheet();

        const formattedData2 = surveyData.map((item, index) => [
          index + 1, // No.
          item.Booking.booking,
          dayjs(item.Booking.importDate).format("DD/MM/YYYY"),
          item.Booking.shippingLir,
          item.Booking.ci,
          dayjs(item.Booking.cyDate).format("DD/MM/YYYY"),
          dayjs(item.Booking.loadingDate).format("DD/MM/YYYY"),
          dayjs(item.Booking.closingDate).format("DD/MM/YYYY").toString() +
            " " +
            item.Booking.closingTime.substring(
              0,
              item.Booking.closingTime.length - 3
            ),
          item.surveyCyPlace,
          item.containerId,
          item.size,
          item.OutsideForm.status,
          item.tareWeight,
          item.sealNo,
          item.approveDate ? dayjs(item.approveDate).format("DD/MM/YYYY") : "",
          item.gateOutDate ? dayjs(item.gateOutDate).format("DD/MM/YYYY") : "",
          item.license,
          item.trailer,
          item.Booking.planner,
          item.Booking.remark,
        ]);
        XLSX.utils.sheet_add_aoa(worksheet2, formattedData2, { origin: -1 });
        XLSX.utils.book_append_sheet(workbook, worksheet2, "Details");

        XLSX.writeFile(workbook, "BookingsData.xlsx");
      } else {
        showDialog("ไม่พบข้อมูล", "ไม่พบข้อมูลที่จะ Export ในวันที่ดังกล่าว");
      }
    } catch (error) {
      showDialog("เกิดข้อผิดพลาด", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const setUpDetailWorkSheet = () => {
    const worksheet = {};
    worksheet["A1"] = { v: "Mahaporn Nakorn Group Co.,Ltd.", t: "s" };
    worksheet["A2"] = { v: "Date: " + dayjs().format("DD/MM/YYYY"), t: "s" };
    worksheet["!merges"] = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 19 } }, // Merge A1:T1
      { s: { r: 1, c: 0 }, e: { r: 1, c: 19 } }, // Merge A2:T2
    ];

    const headers = [
      "No.",
      "Booking No.",
      "Booking Date",
      "Shipping Line",
      "Reference No.",
      "CY Date",
      "Loading Date",
      "Closing Time",
      "CY Place",
      "Container No.",
      "Size/Type",
      "Status",
      "Tare Weight",
      "Seal No.",
      "Gate In Date",
      "Gate Out Date",
      "License",
      "Allied Fleet",
      "Planner Name",
      "Remark",
    ];

    headers.forEach((header, index) => {
      const cell = { v: header, t: "s" };
      worksheet[XLSX.utils.encode_cell({ r: 2, c: index })] = cell; // Set header only once if not merging
    });

    worksheet["!ref"] = XLSX.utils.encode_range({
      s: { c: 0, r: 0 },
      e: { c: headers.length - 1, r: 2 },
    });

    return worksheet;
  };

  const clickViewDetail = (id) => {
    navigate(`/form-management/${id}`);
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <ExportView
      onClickExportButton={onClickExportButton}
      handleChangeBooking={handleChangeBooking}
      handleShippingLir={handleShippingLir}
      handleCi={handleCi}
      handleCyDateStart={handleCyDateStart}
      handleCyDateEnd={handleCyDateEnd}
      handleLoadingStart={handleLoadingStart}
      handleLoadingEnd={handleLoadingEnd}
      handleContainerNo={handleContainerNo}
      tableColumns={tableColumns}
      bookingData={bookingData}
      onClickSearch={onClickSearch}
      clickViewDetail={clickViewDetail}
      booking={booking}
      shippingLir={shippingLir}
      ci={ci}
      cyDateStart={cyDateStart}
      cyDateEnd={cyDateEnd}
      loadingStart={loadingStart}
      loadingEnd={loadingEnd}
      containerNo={containerNo}
    />
  );
};

export default ExportController;
