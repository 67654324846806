import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  Button,
  Box,
} from "@mui/material";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

const UserTable = (props) => {
  const { listUser, onClickEditButton, tableColumns, handleChangePage, page } =
    props;

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {tableColumns.map((column) => (
                <TableCell
                  key={column.id}
                  style={{ minWidth: column.minWidth }}
                  align="center"
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {listUser.map((data, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  {tableColumns.map((column) => {
                    const value = data[column.key];

                    return (
                      <TableCell key={column.id} align="center">
                        {column.key === "order" ? index + 1 : null}
                        {value}
                        {column.key === "Action" ? (
                          <React.Fragment>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                              }}
                            >
                              <Button
                                variant="contained"
                                style={{
                                  borderRadius: "50%",
                                  minWidth: 30,
                                  width: 30,
                                  height: 30,
                                  backgroundColor: "#EFC531",
                                }}
                                onClick={() => onClickEditButton(data.id)}
                              >
                                <BorderColorOutlinedIcon />
                              </Button>
                              <Button
                                variant="contained"
                                style={{
                                  borderRadius: "50%",
                                  minWidth: 30,
                                  width: 30,
                                  height: 30,
                                  marginLeft: "1rem",
                                  backgroundColor: "#DB2828",
                                }}
                              >
                                <DeleteOutlineOutlinedIcon />
                              </Button>
                            </Box>
                          </React.Fragment>
                        ) : null}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
                rowsPerPageOptions={[]}
                rowsPerPage={10}
                component="div"
                count={listUser.length}
                page={page}
                onPageChange={handleChangePage}
            /> */}
    </Paper>
  );
};

export default UserTable;
