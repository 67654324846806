import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  Typography,
} from "@mui/material";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import ReplayIcon from "@mui/icons-material/Replay";
import dayjs from "dayjs";
import { useAuth } from "../../../context/user_context";
import { CheckCircle, Close, SwapHoriz } from "@mui/icons-material";

const SurveyFormTable = (props) => {
  const {
    listSurveyForm,
    changeSurveyFormDetail,
    clickViewSurveyFormDetail,
    tableColumns,
    clickGateInAndGateOut,
    onClickSwap,
    clickViewDetailOnSurveyWaiting,
  } = props;
  const user = useAuth();

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  width: "8px",
                  padding: 0,
                }}
              ></TableCell>
              {tableColumns.map((column) => (
                <TableCell
                  key={column.id}
                  style={{ minWidth: column.minWidth }}
                  align="center"
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {listSurveyForm.map((data, dataIndex) => {
              const bgColor =
                data.gateStatus == "out"
                  ? "#FFE2E3"
                  : data.gateStatus == "waiting"
                  ? "#FBFFE6"
                  : null;
              return (
                <TableRow
                  hover={false}
                  role="checkbox"
                  tabIndex={-1}
                  key={dataIndex}
                  sx={{
                    backgroundColor: bgColor,
                    "&.MuiTableRow-hover": {
                      backgroundColor: "inherit",
                    },
                  }}
                >
                  <TableCell
                    sx={{
                      width: "8px",
                      backgroundColor:
                        data.gateStatus === "out"
                          ? "#FF0200"
                          : data.gateStatus === "waiting"
                          ? "#FFE087"
                          : null,
                      padding: 0,
                    }}
                  />
                  {tableColumns.map((column) => {
                    let value = data[column.key];

                    if (
                      value &&
                      (column.key == "gateOutDate" ||
                        column.key == "approveDate")
                    ) {
                      value = dayjs(value).format("DD/MM/YYYY HH:mm");
                    }

                    if (
                      (column.key === "trailer" ||
                        column.key === "truckLicense") &&
                      data.gateStatus === "in"
                    ) {
                      value = "-";
                    }

                    return (
                      <TableCell key={column.id} align="center">
                        {column.key === "status" ? (
                          <Typography>{data.OutsideForm.status}</Typography>
                        ) : column.key === "createdBy" ? (
                          data.User.firstname
                        ) : column.key == "id" ? (
                          <Typography>{dataIndex + 1}</Typography>
                        ) : column.key === "createdDate" ? (
                          dayjs(value).format("DD/MM/YYYY")
                        ) : (
                          value
                        )}
                        {column.key === "Detail" ? (
                          <React.Fragment>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                              }}
                            >
                              {data.gateStatus === "in" && (
                                <Button
                                  variant="contained"
                                  style={{
                                    borderRadius: "50%",
                                    minWidth: 30,
                                    width: 30,
                                    height: 30,
                                    backgroundColor: "#EFC531",
                                  }}
                                  onClick={() =>
                                    changeSurveyFormDetail(data.id)
                                  }
                                >
                                  <BorderColorOutlinedIcon />
                                </Button>
                              )}
                              <Button
                                variant="contained"
                                style={{
                                  borderRadius: "50%",
                                  minWidth: 30,
                                  width: 30,
                                  height: 30,
                                  marginLeft: "1rem",
                                  backgroundColor: "#036CFB",
                                }}
                                onClick={
                                  data.gateStatus === "waiting"
                                    ? () =>
                                        clickViewDetailOnSurveyWaiting(data.id)
                                    : () => clickViewSurveyFormDetail(data.id)
                                }
                              >
                                <VisibilityIcon />
                              </Button>
                            </Box>
                          </React.Fragment>
                        ) : null}
                        {column.key === "Action" &&
                        user.user.role === "admin" ? (
                          <React.Fragment>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                              }}
                            >
                              {data.gateStatus === "in" ? (
                                <Box>
                                  <Button
                                    variant="contained"
                                    style={{
                                      color: "black",
                                      backgroundColor: "#EFC531",
                                    }}
                                    startIcon={<ExitToAppIcon />}
                                    onClick={() => clickGateInAndGateOut(data)}
                                  >
                                    Gate out
                                  </Button>
                                  <Button
                                    variant="contained"
                                    style={{
                                      color: "white",
                                      backgroundColor: "#272974",
                                      marginLeft: "10px",
                                    }}
                                    startIcon={<SwapHoriz />}
                                    onClick={() => onClickSwap(data)}
                                  >
                                    Swap
                                  </Button>
                                </Box>
                              ) : data.gateStatus === "waiting" ? (
                                <Box margin={"10px"}>
                                  <Button
                                    variant="contained"
                                    style={{
                                      color: "white",
                                      backgroundColor: "#A2CD5D",
                                    }}
                                    startIcon={<CheckCircle />}
                                    onClick={() =>
                                      clickGateInAndGateOut(
                                        data,
                                        "confirmGateOut"
                                      )
                                    }
                                  >
                                    Confirm
                                  </Button>
                                  <Button
                                    variant="contained"
                                    style={{
                                      color: "white",
                                      backgroundColor: "#FE6471",
                                      marginLeft: "10px",
                                    }}
                                    startIcon={<Close />}
                                    onClick={() =>
                                      clickGateInAndGateOut(
                                        data,
                                        "rejectedGateOut"
                                      )
                                    }
                                  >
                                    Rejected
                                  </Button>
                                </Box>
                              ) : (
                                // GateOut is false
                                <Button
                                  variant="contained"
                                  style={{
                                    color: "black",
                                    backgroundColor: "#CBD1D7",
                                  }}
                                  startIcon={<ReplayIcon />}
                                  onClick={() => clickGateInAndGateOut(data)}
                                >
                                  Clear
                                </Button>
                              )}
                            </Box>
                          </React.Fragment>
                        ) : null}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
        rowsPerPageOptions={[]}
        rowsPerPage={10}
        component="div"
        count={listSurveyForm.length}
        page={page}
        onPageChange={handleChangePage}
      /> */}
    </Paper>
  );
};

export default SurveyFormTable;
