import React from "react";
import { Box, Typography } from "@mui/material";

const HomeView = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                minHeight: '100vh',
                overflow: 'auto',
            }}
        >
            <Typography
                fontWeight={'bold'} 
                fontSize={30}
                marginTop={-20}
            >
                บริษัท มหาพร ทรานสปอร์ต จำกัด
            </Typography>
            <Typography
                fontSize={24} 
            >
                Mahaporn Transport Co., Ltd.
            </Typography>
        </Box>
    );
};

export default HomeView;