import React, { useState } from "react";
import AddUserView from "./AddUserView";
import { useNavigate } from "react-router-dom";
import ApiHelper from "../../api/api_helper";
import LoadingIndicator from '../loading/LoadingIndicator';

const AddUserController = () => {
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [telephone, setTelephone] = useState('');
    const [role, setRole] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isLoading, setIsLoading] = useState('');

    const navigate = useNavigate();

    const handleFirstname = (event) => {
        setFirstname(event.target.value);
    }

    const handleLastname = (event) => {
        setLastname(event.target.value);
    }

    const handleTelephone = (event) => {
        setTelephone(event.target.value);
    }

    const handleRole = (event) => {
        setRole(event.target.value)
    }

    const handleUsername = (event) => {
        setUsername(event.target.value)
    }

    const handlePassword = (event) => {
        setPassword(event.target.value)
    }

    const handleConfirmPassword = (event) => {
        setConfirmPassword(event.target.value)
    }

    const previousPage = () => {
        navigate(-1);
    }

    const onClickSubmitButton = async () => {
        if (confirmPassword === password) {
            try {
                setIsLoading(true);
                const response = await ApiHelper.post('/auth/register',
                    {
                        username: username,
                        password: password,
                        firstname: firstname,
                        lastname: lastname,
                        role: role,
                        telephone: telephone
                    });
                if (response.status !== 201) {
                    throw response;
                };
                navigate(-1);
            } catch (error) {
                // Error handle
            } finally {
                setIsLoading(false);
            }
        } else {
        };
    };

    if (isLoading) {
        return <LoadingIndicator />
    };

    return <AddUserView
        handleFirstname={handleFirstname}
        handleLastname={handleLastname}
        handleTelephone={handleTelephone}
        handleRole={handleRole}
        handleUsername={handleUsername}
        handlePassword={handlePassword}
        handleConfirmPassword={handleConfirmPassword}
        onClickSubmitButton={onClickSubmitButton}
        previousPage={previousPage}
    />
}

export default AddUserController;