import React, { useEffect, useState } from "react";
import UserView from "./UserView";
import { useNavigate } from 'react-router-dom';
import ApiHelper from "../../api/api_helper";
import LoadingIndicator from "../loading/LoadingIndicator";

const UserController = () => {
    const [selectRole, setSelectRole] = useState('survey');
    const [listUser, setListUser] = useState([]);
    const [apiError, setApiError] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(0);

    const navigate = useNavigate();

    // Init state
    useEffect(() => { getListUser(selectRole) }, []);

    // Define Table Columns
    const tableColumns = [
        { id: '1', label: 'ลำดับ', minWidth: 50, key: "order" },
        { id: '2', label: 'ชื่อ - นามสกุล', minWidth: 100, key: "firstname" },
        { id: '3', label: 'เบอร์โทรศัพท์', minWidth: 100, key: "telephone" },
        { id: '4', label: 'ชื่อผู้ใช้งาน', minWidth: 100, key: "username" },
        { id: '5', label: 'การจัดการ', minWidth: 100, key: "Action" },
    ];

    // Change Page
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // Fetch User Data
    const getListUser = async (role) => {
        setSelectRole(role);
        try {
            setIsLoading(true);
            const response = await ApiHelper.get(`/auth/users?role=${role}`);
            if (response.status !== 200) {
                throw response;
            }
            setListUser(response.data.data);

        } catch (error) {
            setApiError(error.data);
        } finally {
            setIsLoading(false);
        }
    }

    // Go to add user screen
    const onClickAddButton = () => {
        navigate('/user/add')
    }

    // Go to edit user by id
    const onClickEditButton = (userId) => {
        navigate(`/user/${userId}/change`)
    }

    // If loading show loading screen
    if (isLoading) {
        return <LoadingIndicator />
    }

    return <UserView
        selectRole={selectRole}
        getListUser={getListUser}
        listUser={listUser}
        tableColumns={tableColumns}
        handleChangePage={handleChangePage}
        page={page}
        onClickAddButton={onClickAddButton} 
        onClickEditButton={onClickEditButton}
    />
}

export default UserController