import React, { useState, useEffect } from "react";
import FormManagementView from "./FormManagementView";
import ApiHelper from "../../api/api_helper";
import * as XLSX from "xlsx";
import LoadingIndicator from "../loading/LoadingIndicator";
import { useNavigate } from "react-router-dom";
import { useDialog } from "../../context/dialog_context";
import dayjs from "dayjs";
import { useSearch } from "../../context/form_management_search_contxt";

const FormManagementController = () => {
  // const [cyDate, setCyDate] = useState(null);
  // const [loadingDate, setLoadingDate] = useState(null);
  // const [ci, setCi] = useState(null);
  // const [booking, setBooking] = useState(null);
  const bookingOptions = ["all", "pending", "confirm", "cancel", "complete"];
  const [bookingStatus, setBookingStatus] = useState("all");

  const [listBooking, setListBooking] = useState([]);
  const [apiError, setApiError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);

  const navigate = useNavigate();
  const { showDialog } = useDialog();
  const {
    cyDate,
    setCyDate,
    loadingDate,
    setLoadingDate,
    ci,
    setCi,
    booking,
    setBooking,
    containerId,
    setContainerId,
  } = useSearch();

  // Init state
  useEffect(() => {
    if (
      cyDate ||
      loadingDate ||
      (ci && ci !== "") ||
      (booking && booking !== "") ||
      (containerId && containerId !== "")
    ) {
      getListBooking();
    } else {
      setListBooking([]);
    }
  }, [cyDate, loadingDate, ci, booking, bookingStatus, containerId]);

  // Define Table Columns
  const tableColumns = [
    // { id: "1", label: "Import Date", minWidth: 50, key: "importDate" },
    { id: "2", label: "Cy Date", minWidth: 50, key: "cyDate" },
    // { id: "3", label: "Loading Date", minWidth: 100, key: "loadingDate" },
    // { id: "4", label: "Closing Date", minWidth: 100, key: "closingDate" },
    // { id: "5", label: "Cosing Time", minWidth: 100, key: "closingTime" },
    // { id: "6", label: "CI", minWidth: 100, key: "ci" },
    { id: "7", label: "Booking", minWidth: 100, key: "booking" },
    // { id: "8", label: "Customer", minWidth: 100, key: "customer" },
    // { id: "9", label: "Country", minWidth: 100, key: "country" },
    // { id: "10", label: "Shipping Lir", minWidth: 100, key: "shippingLir" },
    // { id: "11", label: "Unit", minWidth: 100, key: "unit" },
    // { id: "12", label: "Cy Place", minWidth: 100, key: "cyPlace" },
    // { id: "13", label: "Cy Contact", minWidth: 100, key: "cyContact" },
    // { id: "14", label: "Remark", minWidth: 100, key: "remark" },
    // { id: "15", label: "Planner", minWidth: 100, key: "planer" },
    // { id: "16", label: "Status", minWidth: 100, key: "status" },
    // { id: "17", label: "Other", minWidth: 100, key: "other" },
    // { id: "18", label: "Sent", minWidth: 100, key: "sent" },
    // { id: "19", label: "Booking Status", minWidth: 100, key: "bookingStatus" },
    { id: "21", label: "status", minWidth: 100, key: "bookingStatus" },
    { id: "20", label: "การจัดการ", minWidth: 100, key: "Action" },
  ];

  // Change Page
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Fetch Booking Data
  const getListBooking = async () => {
    try {
      const response = await ApiHelper.get(
        `/booking/bookings?cyDate=${cyDate}&loadingDate=${loadingDate}&ci=${ci}&booking=${booking}&bookingStatus=${bookingStatus}&containerId=${containerId}`
      );
      setIsLoading(true);
      if (response.status !== 200) {
        throw response;
      }
      setListBooking(response.data.data);
    } catch (error) {
      setApiError(error.data);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCyDateChange = (value) => {
    setCyDate(value ? dayjs(value).format("YYYY-MM-DD") : null);
  };

  const handleLoadingDateChange = (value) => {
    setLoadingDate(value ? dayjs(value).format("YYYY-MM-DD") : null);
  };

  const handleCiChange = (event) => {
    setCi(event.target.value);
  };

  const handleBookingChange = (event) => {
    setBooking(event.target.value);
  };

  const handleBookingStatusChange = (event) => {
    setBookingStatus(event.target.value);
  };

  const handleContainerIdChange = (event) => {
    setContainerId(event.target.value);
  };

  // File upload section
  const [selectedFile, setSelectedFile] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [excelData, setExcelData] = useState([]);
  const [uploadError, setUploadError] = useState(null);

  const handleDialogClose = () => {
    setSelectedFile(null);
    setExcelData([]);
    setUploadError(null);
    setDialogOpen(false);
  };

  const handleFileChange = async (event) => {
    try {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        console.log(data);
        const workbook = XLSX.read(data, { type: "array" });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, {
          defval: "",
        });
        setExcelData(jsonData);
      };
      await new Promise((resolve) => {
        reader.readAsArrayBuffer(file);
        reader.onloadend = resolve;
      });

      setSelectedFile(file);
      setDialogOpen(true);
    } catch (error) {}
  };

  const handleUpload = async () => {
    try {
      const response = await ApiHelper.postExcelFile(
        "/booking/upload-booking",
        selectedFile
      );

      if (response.status !== 201) {
        setUploadError(response.data.data);
        throw response;
      }

      handleDialogClose();
      getListBooking();
    } catch (error) {
      // Error handling
      showDialog("เกิดข้อผิดพลาด", error.response.data.data);
      setDialogOpen(false);
      setApiError(error.data);
    }
  };

  const clickViewDetail = (id) => {
    const encodeBookingId = encodeURIComponent(id);
    navigate(`/form-management/${encodeBookingId}`);
  };

  const clickChangeDetail = (id) => {
    const encodeBookingId = encodeURIComponent(id);
    navigate(`/form-management/${encodeBookingId}/change`);
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <FormManagementView
      handleCyDateChange={handleCyDateChange}
      handleLoadingDateChange={handleLoadingDateChange}
      handleCiChange={handleCiChange}
      handleBookingChange={handleBookingChange}
      bookingStatus={bookingStatus}
      bookingOptions={bookingOptions}
      handleBookingStatusChange={handleBookingStatusChange}
      handleContainerIdChange={handleContainerIdChange}
      listBooking={listBooking}
      tableColumns={tableColumns}
      page={page}
      handleChangePage={handleChangePage}
      dialogOpen={dialogOpen}
      handleDialogClose={handleDialogClose}
      handleFileChange={handleFileChange}
      handleUpload={handleUpload}
      excelData={excelData}
      uploadError={uploadError}
      clickChangeDetail={clickChangeDetail}
      clickViewDetail={clickViewDetail}
      cyDate={cyDate}
      ci={ci}
      booking={booking}
      loadingDate={loadingDate}
      containerId={containerId}
    />
  );
};

export default FormManagementController;
