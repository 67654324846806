import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import CustomTextField from "./components/CustomTextField";
import SearchIcon from "@mui/icons-material/Search";
import SurveyFormTable from "./components/SurveyFormTable";

const ApproveFormView = (props) => {
  const {
    handleStartDateChange,
    handleEndDateChange,
    handleCiChange,
    listSurveyForm,
    tableColumns,
    page,
    handleChangePage,
    clickViewDetail,
  } = props;

  return (
    <Box
      sx={{
        flexGrow: 1,
        p: 3,
        backgroundColor: "white",
        minHeight: "100vh",
        overflow: "auto",
      }}
    >
      <Typography
        fontWeight={"bold"}
        fontSize={24}
        borderBottom="2px solid #EFC531"
        display="inline"
      >
        ระบบ Gate in
      </Typography>
      <Box>
        <Grid
          container
          spacing={2}
          sx={{
            marginTop: "0.5rem",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grid item xs={12} md={6} lg={4} display={"flex"}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container>
                <Grid item xs={12}>
                  <DemoContainer components={["DesktopDatePicker"]}>
                    <DesktopDatePicker
                      label="วันที่เริ่มต้น"
                      onChange={handleStartDateChange}
                      format="DD/MM/YYYY"
                      sx={{
                        width: "100%",
                      }}
                    />
                  </DemoContainer>
                </Grid>
              </Grid>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={6} lg={4} display={"flex"}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container>
                <Grid item xs={12}>
                  <DemoContainer components={["DesktopDatePicker"]}>
                    <DesktopDatePicker
                      label="วันที่สิ้นสุด"
                      onChange={handleEndDateChange}
                      format="DD/MM/YYYY"
                      sx={{
                        width: "100%",
                      }}
                    />
                  </DemoContainer>
                </Grid>
              </Grid>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={6} lg={4} display={"flex"}>
            <CustomTextField
              id="ci"
              label="ค้นหา CI"
              name="ci"
              placeholder="CI"
              icon={<SearchIcon />}
              onChange={handleCiChange}
            />
          </Grid>
        </Grid>
        <SurveyFormTable
          listSurveyForm={listSurveyForm}
          tableColumns={tableColumns}
          handleChangePage={handleChangePage}
          page={page}
          clickViewDetail={clickViewDetail}
        />
      </Box>
    </Box>
  );
};

export default ApproveFormView;
