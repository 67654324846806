import React from "react";
import { Box, Typography } from "@mui/material";
import ExportBookingView from "./components/ExportBookingView";

const ExportView = (props) => {
  const {
    onClickExportButton,
    handleChangeBooking,
    handleShippingLir,
    handleCi,
    handleCyDateStart,
    handleCyDateEnd,
    handleLoadingStart,
    handleLoadingEnd,
    handleContainerNo,
    tableColumns,
    bookingData,
    onClickSearch,
    clickViewDetail,
    booking,
    shippingLir,
    ci,
    cyDateStart,
    cyDateEnd,
    loadingStart,
    loadingEnd,
    containerNo,
  } = props;

  return (
    <Box
      sx={{
        flexGrow: 1,
        p: 3,
        backgroundColor: "white",
        minHeight: "100vh",
        overflow: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            "@media (max-width: 720px)": {
              flexDirection: "column",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              "@media (max-width: 720px)": {
                flexDirection: "row",
                justifyContent: "flex-start",
              },
            }}
          >
            <Typography
              fontWeight={"bold"}
              fontSize={24}
              borderBottom="2px solid #EFC531"
              display="inline"
            >
              ระบบ Export
            </Typography>
          </Box>
        </Box>
      </Box>
      <ExportBookingView
        onClickExportButton={onClickExportButton}
        handleChangeBooking={handleChangeBooking}
        handleShippingLir={handleShippingLir}
        handleCi={handleCi}
        handleCyDateStart={handleCyDateStart}
        handleCyDateEnd={handleCyDateEnd}
        handleLoadingStart={handleLoadingStart}
        handleLoadingEnd={handleLoadingEnd}
        handleContainerNo={handleContainerNo}
        tableColumns={tableColumns}
        bookingData={bookingData}
        onClickSearch={onClickSearch}
        clickViewDetail={clickViewDetail}
        booking={booking}
        shippingLir={shippingLir}
        ci={ci}
        cyDateStart={cyDateStart}
        cyDateEnd={cyDateEnd}
        loadingStart={loadingStart}
        loadingEnd={loadingEnd}
        containerNo={containerNo}
      />
    </Box>
  );
};

export default ExportView;
