import React from "react";
import { Box, Grid, Typography, Divider } from "@mui/material";

const OutsideForm = (props) => {
  const { outsideForm } = props;

  return (
    <Grid item xs={12} lg={6} display={"flex"}>
      <Box
        sx={{
          backgroundColor: "white",
          margin: "0 1%",
          padding: "1rem",
          borderRadius: "8px",
          border: "1px solid #B9C0E0",
          width: "100%",
        }}
      >
        <Typography
          fontWeight={"bold"}
          fontSize={16}
          margin={"1rem"}
          align="center"
        >
          OUTSIDE
        </Typography>
        <Divider sx={{ borderColor: "#B9C0E0" }} />
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          marginTop={"1rem"}
        >
          <Typography>DENT (บุบ ยุบ)</Typography>
          <Typography color={"#1364D7"}>{outsideForm.dentOutside}</Typography>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          marginTop={"1rem"}
        >
          <Typography>HANDLE (มือจับ)</Typography>
          <Typography color={"#1364D7"}>{outsideForm.handle}</Typography>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          marginTop={"1rem"}
        >
          <Typography>LOCK (ล็อคซีล)</Typography>
          <Typography color={"#1364D7"}>{outsideForm.lock}</Typography>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          marginTop={"1rem"}
        >
          <Typography>DOOR (ประตูตู้)</Typography>
          <Typography color={"#1364D7"}>{outsideForm.door}</Typography>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          marginTop={"1rem"}
        >
          <Typography>NUMBER (เลขตู้)</Typography>
          <Typography color={"#1364D7"}>{outsideForm.number}</Typography>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          marginTop={"1rem"}
        >
          <Typography>VENT (ช่องระบายอากาศ)</Typography>
          <Typography color={"#1364D7"}>{outsideForm.vent}</Typography>
        </Box>
        <Divider sx={{ borderColor: "#EFF1F3", marginTop: "1rem" }} />
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          marginTop={"1rem"}
        >
          <Typography>LAY PAPER</Typography>
          <Typography color={"#1364D7"}>{outsideForm.layPaper}</Typography>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          marginTop={"1rem"}
        >
          <Typography>Remark LAY PAPER</Typography>
          <Typography color={"#1364D7"}>
            {outsideForm.remarkLayPaper}
          </Typography>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          marginTop={"1rem"}
        >
          <Typography>Status</Typography>
          <Typography color={"#1364D7"}>{outsideForm.status}</Typography>
        </Box>
        {/* <Box 
                    display={"flex"} 
                    justifyContent={"space-between"}
                    marginTop={'1rem'}
                    gap={2}
                >
                    <Typography>Chasis</Typography>
                    <Typography color={'#1364D7'}>{outsideForm.chasis}</Typography>
                </Box> */}
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          marginTop={"1rem"}
          gap={2}
        >
          <Typography>Remark</Typography>
          <Typography color={"#1364D7"}>{outsideForm.remark}</Typography>
        </Box>
      </Box>
    </Grid>
  );
};

export default OutsideForm;
