import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ChangeFormDetailView from "./ChangeFormDetailView";
import ApiHelper from "../../api/api_helper";
import LoadingIndicator from "../loading/LoadingIndicator";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

const ChangeFormDetailController = () => {
  const { bookingId } = useParams();
  const decodedBookingId = decodeURIComponent(bookingId);

  const [isLoading, setIsLoading] = useState(true);
  const [booking, setBooking] = useState(null);
  const [apiError, setApiError] = useState(null);
  const [updateStatus, setUpdateStatus] = useState(null);

  // Use Navigate
  const navigate = useNavigate();

  const previousPage = () => {
    navigate(-1);
  };

  const handleSaveButton = async () => {
    try {
      const encodedBookingId = encodeURIComponent(bookingId);
      setIsLoading(true);
      const response = await ApiHelper.put(
        `/booking/update-booking/${encodedBookingId}`,
        booking
      );
      if (response.status !== 200) {
        throw response;
      }
      navigate(-1);
    } catch (error) {
      setUpdateStatus(error.data);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getBookingDetail();
  }, []);

  const getBookingDetail = async () => {
    try {
      setIsLoading(true);
      const response = await ApiHelper.get(
        `/booking/check?booking=${decodedBookingId}`
      );
      if (response.status !== 200) {
        throw response;
      }
      setBooking(response.data.data);
    } catch (error) {
      setApiError(error.data);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCyDateChange = (newCyDate) => {
    setBooking((prevData) => ({
      ...prevData,
      cyDate: newCyDate,
    }));
  };

  const handleLoadingDateChange = (newLoadingDate) => {
    setBooking((prevData) => ({
      ...prevData,
      loadingDate: newLoadingDate,
    }));
  };

  const handleClosingDateChange = (newClosingDate) => {
    setBooking((prevData) => ({
      ...prevData,
      closingDate: newClosingDate,
    }));
  };

  const handleLosingTimeChange = (event) => {
    let { value } = event.target;
    const onlyNums = value.replace(/[^0-9]/g, "");
    let formattedValue = value; // Default to original value if conditions below don't apply

    if (onlyNums.length <= 6) {
      // Split the numbers into HH, MM, SS
      if (onlyNums.length <= 2) {
        formattedValue = onlyNums;
      } else if (onlyNums.length <= 4) {
        formattedValue = onlyNums.slice(0, 2) + ":" + onlyNums.slice(2);
      } else {
        formattedValue =
          onlyNums.slice(0, 2) +
          ":" +
          onlyNums.slice(2, 4) +
          ":" +
          onlyNums.slice(4);
      }
    }
    setBooking((prevData) => ({
      ...prevData,
      closingTime: formattedValue, // Update closingTime, not losingDate
    }));
  };

  const handleCiChange = (event) => {
    setBooking((prevData) => ({
      ...prevData,
      ci: event.target.value,
    }));
  };

  const handleUnitChange = (event) => {
    setBooking((prevData) => ({
      ...prevData,
      unit: event.target.value,
    }));
  };

  const handleSizeChange = (event) => {
    setBooking((prevData) => ({
      ...prevData,
      size: event.target.value,
    }));
  };

  const handleCustomerChange = (event) => {
    setBooking((prevData) => ({
      ...prevData,
      customer: event.target.value,
    }));
  };

  const handleCountryChange = (event) => {
    setBooking((prevData) => ({
      ...prevData,
      country: event.target.value,
    }));
  };

  const handleShippingLirChange = (event) => {
    setBooking((prevData) => ({
      ...prevData,
      shippingLir: event.target.value,
    }));
  };

  const handleBookingChange = (event) => {
    setBooking((prevData) => ({
      ...prevData,
      booking: event.target.value,
    }));
  };

  const handleCyPlaceChange = (event) => {
    setBooking((prevData) => ({
      ...prevData,
      cyPlace: event.target.value,
    }));
  };

  const handleCyContactChange = (event) => {
    setBooking((prevData) => ({
      ...prevData,
      cyContact: event.target.value,
    }));
  };

  const handleRemarkChange = (event) => {
    setBooking((prevData) => ({
      ...prevData,
      remark: event.target.value,
    }));
  };

  const handlePlanerChange = (event) => {
    setBooking((prevData) => ({
      ...prevData,
      planner: event.target.value,
    }));
  };

  const handleStatusChange = (event) => {
    setBooking((prevData) => ({
      ...prevData,
      status: event.target.value,
    }));
  };

  const handleOtherChange = (event) => {
    setBooking((prevData) => ({
      ...prevData,
      other: event.target.value,
    }));
  };

  const handleSentChange = (event) => {
    setBooking((prevData) => ({
      ...prevData,
      sent: event.target.value,
    }));
  };

  const handleChangeStatus = (event) => {
    setBooking((prevData) => ({
      ...prevData,
      bookingStatus: event.target.value,
    }));
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <ChangeFormDetailView
      booking={booking}
      handleSaveButton={handleSaveButton}
      previousPage={previousPage}
      handleCyDateChange={handleCyDateChange}
      handleLoadingDateChange={handleLoadingDateChange}
      handleClosingDateChange={handleClosingDateChange}
      handleLosingTimeChange={handleLosingTimeChange}
      handleCiChange={handleCiChange}
      handleUnitChange={handleUnitChange}
      handleSizeChange={handleSizeChange}
      handleCustomerChange={handleCustomerChange}
      handleCountryChange={handleCountryChange}
      handleShippingLirChange={handleShippingLirChange}
      handleBookingChange={handleBookingChange}
      handleCyPlaceChange={handleCyPlaceChange}
      handleCyContactChange={handleCyContactChange}
      handleRemarkChange={handleRemarkChange}
      handlePlanerChange={handlePlanerChange}
      handleStatusChange={handleStatusChange}
      handleOtherChange={handleOtherChange}
      handleSentChange={handleSentChange}
      handleChangeStatus={handleChangeStatus}
    />
  );
};

export default ChangeFormDetailController;
