import React, { useEffect, useState } from "react";
import ChangeUserView from "./ChangeUserView";
import { useNavigate, useParams } from "react-router-dom";
import ApiHelper from "../../api/api_helper";
import LoadingIndicator from '../loading/LoadingIndicator';

const ChangeUserController = () => {
    const { userId } = useParams();

    const [userData, setUserData] = useState({});
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [apiError, setApiError] = useState('');
    const [isLoading, setIsLoading] = useState('');

    const navigate = useNavigate();

    // Initial State
    useEffect(() => {
        getUserById();
    }, [])

    // Fetch User Data
    const getUserById = async () => {
        try {
            setIsLoading(true);
            const response = await ApiHelper.get(`/auth/user?id=${userId}`);
            if (response.status !== 200) {
                throw response;
            }
            setUserData(response.data.data);

        } catch (error) {
            setApiError(error.data);
        } finally {
            setIsLoading(false);
        }
    };

    const updateUserDataByKey = (key, newValue) => {
        setUserData((prevState) => ({
            ...prevState,
            [key]: newValue,
        }));
    };

    const handlePassword = (event) => {
        setPassword(event.target.value);
    };

    const handleConfirmPassword = (event) => {
        setConfirmPassword(event.target.value)
    };

    const checkChangePassword = () => {
        if (password !== '' && password === confirmPassword) {
            return true;
        } else {
            return false;
        }
    };

    const previousPage = () => {
        navigate(-1);
    };

    const onClickSubmitButton = async () => {
        if (checkChangePassword()) {
            updateUserDataByKey('password', password)
            console.log(userData);
        };
    };

    if (isLoading) {
        return <LoadingIndicator />
    };

    return <ChangeUserView
        userData={userData}
        updateUserDataByKey={updateUserDataByKey}
        handlePassword={handlePassword}
        handleConfirmPassword={handleConfirmPassword}
        onClickSubmitButton={onClickSubmitButton}
        previousPage={previousPage}
    />
}

export default ChangeUserController;