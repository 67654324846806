import React from "react";
import {
  Box,
  Typography,
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormLabel,
  Divider,
  Button,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

const AddUserView = (props) => {
  const {
    handleFirstname,
    handleLastname,
    handleTelephone,
    handleRole,
    handleUsername,
    handlePassword,
    handleConfirmPassword,
    onClickSubmitButton,
    previousPage,
  } = props;
  return (
    <Box
      sx={{
        flexGrow: 1,
        p: 3,
        backgroundColor: "white",
        minHeight: "100vh",
        overflow: "auto",
      }}
    >
      <Typography
        fontWeight={"bold"}
        fontSize={24}
        borderBottom="2px solid #EFC531"
        display="inline"
      >
        เพิ่มสมาชิก
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          mt: "2rem",
          "@media (max-width: 720px)": {
            flexDirection: "column",
            gap: 1,
          },
        }}
      >
        <Box
          sx={{
            width: "49%",
            "@media (max-width: 720px)": {
              width: "100%",
            },
          }}
        >
          <Typography>ชื่อ</Typography>
          <TextField
            label=""
            placeholder="ชื่อ"
            onChange={handleFirstname}
            sx={{
              width: "100%",
            }}
          />
        </Box>
        <Box
          sx={{
            width: "49%",
            "@media (max-width: 720px)": {
              width: "100%",
            },
          }}
        >
          <Typography>นามสกุล</Typography>
          <TextField
            label=""
            placeholder="นามสกุล"
            onChange={handleLastname}
            sx={{
              width: "100%",
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          marginTop: "1rem",
        }}
      >
        <Typography>เบอร์โทรศัพท์</Typography>
        <TextField
          label=""
          placeholder="เบอร์โทรศัพท์"
          onChange={handleTelephone}
          sx={{
            width: "100%",
          }}
        />
      </Box>
      <Box
        sx={{
          marginTop: "1rem",
        }}
      >
        <Typography>Username</Typography>
        <TextField
          label=""
          placeholder="Username"
          onChange={handleUsername}
          sx={{
            width: "100%",
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginTop: "1rem",
        }}
      >
        <RadioGroup
          sx={{
            display: "flex",
            flexDirection: "row",
            textAlign: "center",
            alignItems: "center",
            "@media (max-width: 900px)": {
              flexDirection: "column",
              alignItems: "normal",
              textAlign: "unset",
            },
          }}
          onChange={handleRole}
        >
          <FormLabel>ประเภทสมาชิก</FormLabel>
          <FormControlLabel
            value="general"
            control={<Radio />}
            label="บุคคลทั่วไป"
            sx={{
              marginLeft: "2rem",
              "@media (max-width: 900px)": {
                marginLeft: "1rem",
              },
            }}
          />
          <FormControlLabel
            value="survey"
            control={<Radio />}
            label="Survey"
            sx={{
              marginLeft: "2rem",
              "@media (max-width: 900px)": {
                marginLeft: "1rem",
              },
            }}
          />
          <FormControlLabel
            value="admin"
            control={<Radio />}
            label="เจ้าหน้าที่"
            sx={{
              marginLeft: "2rem",
              "@media (max-width: 900px)": {
                marginLeft: "1rem",
              },
            }}
          />
        </RadioGroup>
      </Box>
      <Divider />
      <Box
        sx={{
          marginTop: "1rem",
        }}
      >
        <Typography>Password</Typography>
        <TextField
          label=""
          type="password"
          onChange={handlePassword}
          sx={{
            width: "100%",
          }}
        />
      </Box>
      <Box
        sx={{
          marginTop: "1rem",
        }}
      >
        <Typography>Confirm Password</Typography>
        <TextField
          label=""
          type="password"
          sx={{
            width: "100%",
          }}
          onChange={handleConfirmPassword}
        />
      </Box>
      <Box
        sx={{
          marginTop: "2rem",
          color: "#CBCBCB",
        }}
      >
        <Typography>รหัสผ่านต้องประกอบด้วย</Typography>
        <ul style={{ paddingLeft: "2rem" }}>
          <li>ความยาวอย่างน้อย 8-128 ตัวอักษร</li>
          <li>ตัวอักษร 0-9 อย่างน้อย 1 ตัวอักษร</li>
          <li>ตัวอักษร a-z อย่างน้อย 1 ตัวอักษร</li>
          <li>ตัวอักษร A-Z อย่างน้อย 1 ตัวอักษร</li>
          <li>
            สัญลักษณ์พิเศษอย่างน้อย 1 ตัว (!?@#$%^&*(){}_+-=/|) ยกเว้นช่องว่าง
          </li>
        </ul>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "2rem",
          gap: 2,
          "@media (max-width: 360px)": {
            flexDirection: "column",
            gap: 1,
          },
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={onClickSubmitButton}
          sx={{
            width: "30%",
            "@media (max-width: 720px)": {
              width: "50%",
            },
            "@media (max-width: 360px)": {
              width: "100%",
            },
          }}
        >
          บันทึก
        </Button>
        <Button
          component="label"
          sx={{
            color: "black",
            border: "1px solid black",
            "&:hover": {
              backgroundColor: "grey",
              color: "white",
            },
            width: "30%",
            "@media (max-width: 720px)": {
              width: "50%",
            },
            "@media (max-width: 360px)": {
              width: "100%",
            },
          }}
          onClick={previousPage}
        >
          ยกเลิก
        </Button>
      </Box>
    </Box>
  );
};

export default AddUserView;
