import React from "react";
import { Box, Typography, Button, IconButton } from "@mui/material";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import InsideForm from "./InsideForm";
import OutsideForm from "./OutsideForm";
import ImageUpload from "./ImageUpload";

const ListForm = (props) => {
  const {
    insideFormFields,
    handleInsideFieldChange,
    outsideFormFields,
    handleOutsideFieldChange,
    handleLayPaper,
    handleRemarkLayPaper,
    handleStatus,
    handleChasis,
    handleRemark,
    showListForm,
    handleShowListForm,
    images,
    handleImageChange,
    handleClick,
    handleDeleteImage,
    fileInputRef,
  } = props;

  return (
    <Box>
      {showListForm ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#EFF1F3",
              overflow: "auto",
              width: "100%",
              marginLeft: "1rem",
            }}
          >
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "1rem",
                }}
              >
                <FactCheckIcon sx={{ fontSize: "3rem" }} />
                <Typography
                  fontWeight={"bold"}
                  fontSize={16}
                  marginLeft={"1rem"}
                >
                  ตรวจสอบรายการ
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                "@media (max-width: 960px)": {
                  flexDirection: "column",
                  gap: "1rem",
                },
              }}
            >
              <InsideForm
                insideFormFields={insideFormFields}
                handleInsideFieldChange={handleInsideFieldChange}
              />
              <OutsideForm
                outsideFormFields={outsideFormFields}
                handleOutsideFieldChange={handleOutsideFieldChange}
                handleLayPaper={handleLayPaper}
                handleRemarkLayPaper={handleRemarkLayPaper}
                handleStatus={handleStatus}
                handleChasis={handleChasis}
                handleRemark={handleRemark}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                marginTop: "1rem",
                marginLeft: "1rem",
              }}
            >
              <ImageUpload
                handleImageChange={handleImageChange}
                handleClick={handleClick}
                handleDeleteImage={handleDeleteImage}
                images={images}
                fileInputRef={fileInputRef}
              />
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <IconButton
            sx={{
              borderRadius: "50%",
            }}
            onClick={handleShowListForm}
          >
            <CheckCircleOutlineIcon
              sx={{
                height: "3rem",
                width: "3rem",
                color: "#F4D772",
              }}
            />
          </IconButton>
          <Box
            sx={{
              backgroundColor: "#EFF1F3",
              overflow: "auto",
              width: "100%",
              marginLeft: "1rem",
            }}
          >
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "1rem",
                }}
              >
                <FactCheckIcon sx={{ fontSize: "3rem" }} />
                <Typography
                  fontWeight={"bold"}
                  fontSize={16}
                  marginLeft={"1rem"}
                >
                  ตรวจสอบรายการ
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ListForm;
