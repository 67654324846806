import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  Button,
  Box,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import dayjs from "dayjs";
import { CheckCircle, Close } from "@mui/icons-material";

const SurveyFormTable = (props) => {
  const {
    listSurveyForm,
    tableColumns,
    clickViewDetail,
    openFrom = "",
    approveGateOut = () => {},
    rejectGateOut = () => {},
  } = props;

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {tableColumns.map((column) => (
                <TableCell
                  key={column.id}
                  style={{ minWidth: column.minWidth }}
                  align="center"
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {listSurveyForm.map((data, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  {tableColumns.map((column) => {
                    return (
                      <TableCell key={column.id} align="center">
                        {column.key === "order" && index + 1}
                        {column.key == "createdDate" &&
                          dayjs(data["createdDate"]).format("DD/MM/YYYY HH:mm")}
                        {column.key === "booking" && data["Booking"]["ci"]}
                        {column.key === "requestBy" &&
                          data["User"]["firstname"]}
                        {column.key === "bookingId" &&
                          data["Booking"]["booking"]}
                        {column.key === "requestGateOutDate" &&
                          data["requestGateOutDate"] &&
                          dayjs(data["requestGateOutDate"]).format(
                            "DD/MM/YYYY HH:mm"
                          )}
                        {column.key === "license" && data["license"]}
                        {column.key === "truckLicense" && data["truckLicense"]}
                        {column.key === "trailer" && data["trailer"]}
                        {column.key === "containerId" && data["containerId"]}
                        {column.key === "Action" && (
                          <React.Fragment>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                              }}
                            >
                              <Button
                                variant="contained"
                                style={{
                                  borderRadius: "50%",
                                  minWidth: 30,
                                  width: 30,
                                  height: 30,
                                  backgroundColor: "#036CFB",
                                }}
                                onClick={() => clickViewDetail(data.id)}
                              >
                                <VisibilityIcon />
                              </Button>

                              {openFrom === "gateOut" && (
                                <Box>
                                  <Button
                                    variant="contained"
                                    style={{
                                      color: "white",
                                      backgroundColor: "#A2CD5D",
                                      borderRadius: "50%",
                                      marginLeft: "10px",
                                      minWidth: 30,
                                      width: 30,
                                      height: 30,
                                    }}
                                    onClick={() => approveGateOut(data)}
                                  >
                                    <CheckCircle />
                                  </Button>
                                  <Button
                                    variant="contained"
                                    style={{
                                      color: "white",
                                      backgroundColor: "#FE6471",
                                      marginLeft: "10px",
                                      borderRadius: "50%",
                                      minWidth: 30,
                                      width: 30,
                                      height: 30,
                                    }}
                                    onClick={() => rejectGateOut(data)}
                                  >
                                    <Close />
                                  </Button>
                                </Box>
                              )}
                            </Box>
                          </React.Fragment>
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
        rowsPerPageOptions={[]}
        rowsPerPage={-1}
        component="div"
        count={listSurveyForm.length}
        page={page}
        onPageChange={handleChangePage}
      /> */}
    </Paper>
  );
};
export default SurveyFormTable;
