import React from "react";
import {
  Button,
  Avatar,
  TextField,
  Link,
  Box,
  Typography,
  InputAdornment,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ErrorIcon from "@mui/icons-material/Error";
import "./Login.css";

const LoginView = (props) => {
  const {
    loginUser,
    handleTogglePassword,
    showPassword,
    handlePasswordChange,
    handleusernameChange,
    formError,
    apiError,
    openDialog,
    handleCloseDialog,
  } = props;

  return (
    <Box className="background">
      <Box
        className="box"
        sx={{
          backgroundColor: "rgba(249, 232, 194, 0.9)",
        }}
      >
        <Avatar
          src="/logo.png"
          sx={{
            margin: "1rem",
            width: "128px",
            height: "128px",
          }}
        />
        <Typography>บริษัท มหาพร ทรานสปอร์ต จำกัด</Typography>
        <Typography>เข้าสู่ระบบ</Typography>
        <Box>
          <TextField
            required
            fullWidth
            margin="normal"
            id="username"
            label="ชื่อผู้ใช้งาน"
            name="username"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonIcon />
                </InputAdornment>
              ),
              sx: {
                borderRadius: 16,
                backgroundColor: "",
              },
            }}
            onChange={handleusernameChange}
            error={Boolean(formError.username)}
            helperText={formError.username}
          />
          <TextField
            required
            fullWidth
            margin="normal"
            id="password"
            label="รหัสผ่าน"
            name="password"
            type={showPassword ? "text" : "password"}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <IconButton onClick={handleTogglePassword}>
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              ),
              sx: {
                borderRadius: 16,
              },
            }}
            onChange={handlePasswordChange}
            error={Boolean(formError.password)}
            helperText={formError.password}
          />

          <Box display="flex" justifyContent="flex-end">
            <Link href="#" style={{ textDecoration: "none", color: "black" }}>
              ลืมรหัสผ่าน ?
            </Link>
          </Box>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            onClick={loginUser}
            sx={{
              mt: 3,
              mb: 2,
              borderRadius: 16,
              height: 48,
              color: "white",
            }}
          >
            เข้าสู่ระบบ
          </Button>
        </Box>
        {apiError ? (
          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            maxWidth={"sm"}
            fullWidth
          >
            <DialogTitle display={"flex"} alignItems={"center"} color={"red"}>
              <ErrorIcon fontSize="large" style={{ marginRight: "8px" }} />
              Login Fail
            </DialogTitle>
            <DialogContent>
              <DialogContentText>Error : {apiError}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog}>OK</Button>
            </DialogActions>
          </Dialog>
        ) : null}
      </Box>
    </Box>
  );
};

export default LoginView;
