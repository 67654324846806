import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  Button,
  Box,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Edit } from "@mui/icons-material";
import { useAuth } from "../../../context/user_context";

const BookingTable = (props) => {
  const { listBooking, tableColumns, clickViewDetail, clickChangeDetail } =
    props;

  const user = useAuth();

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 600 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {tableColumns.map((column) => (
                <TableCell key={column.id} style={{ minWidth: column.minWidt }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {listBooking.map((data, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  {tableColumns.map((column, index) => {
                    const value = data[column.key] ? data[column.key] : "-";

                    return (
                      <TableCell key={column.id}>
                        {column.key === "bookingStatus" ? (
                          value === "pending" ? (
                            <Typography
                              padding={"8px 16px"}
                              display={"inline-block"}
                              borderRadius={"20px"}
                              backgroundColor={"#D9E9FF"}
                              color={"#036CFB"}
                              border={"1px solid #036CFB"}
                            >
                              {value}
                            </Typography>
                          ) : value === "confirm" ? (
                            <Typography
                              padding={"8px 16px"}
                              display={"inline-block"}
                              borderRadius={"20px"}
                              backgroundColor={"black"}
                              color={"white"}
                              border={"1px solid white"}
                            >
                              {value}
                            </Typography>
                          ) : value === "cancel" ? (
                            <Typography
                              padding={"8px 16px"}
                              display={"inline-block"}
                              borderRadius={"20px"}
                              backgroundColor={"#FADFDF"}
                              color={"#E35656"}
                              border={"1px solid #E35656"}
                            >
                              {value}
                            </Typography>
                          ) : value === "complete" ? (
                            <Typography
                              padding={"8px 16px"}
                              display={"inline-block"}
                              borderRadius={"20px"}
                              backgroundColor={"#DCEDE5"}
                              color={"#349366"}
                              border={"1px solid #349366"}
                            >
                              {value}
                            </Typography>
                          ) : null
                        ) : column.key === "Action" ? (
                          ""
                        ) : (
                          value
                        )}
                        {column.key === "Action" ? (
                          <React.Fragment>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <Button
                                variant="contained"
                                style={{
                                  borderRadius: "50%",
                                  minWidth: 30,
                                  width: 30,
                                  height: 30,
                                  marginLeft: "1rem",
                                  backgroundColor: "#036CFB",
                                }}
                                onClick={() => clickViewDetail(data.booking)}
                              >
                                <VisibilityIcon />
                              </Button>
                              {/* <Button
                                variant="contained"
                                style={{
                                  borderRadius: "50%",
                                  minWidth: 30,
                                  width: 30,
                                  height: 30,
                                  marginLeft: "1rem",
                                  backgroundColor: "#DB2828",
                                }}
                              >
                                <DeleteOutlineOutlinedIcon />
                              </Button> */}
                              {user.user.role === "admin" && (
                                <Button
                                  variant="contained"
                                  style={{
                                    borderRadius: "50%",
                                    minWidth: 30,
                                    width: 30,
                                    height: 30,
                                    marginLeft: "1rem",
                                    backgroundColor: "#ECBA61",
                                  }}
                                  onClick={() =>
                                    clickChangeDetail(data.booking)
                                  }
                                >
                                  <Edit />
                                </Button>
                              )}
                            </Box>
                          </React.Fragment>
                        ) : null}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default BookingTable;
