import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ApiHelper from "../../api/api_helper";
import LoadingIndicator from "../loading/LoadingIndicator";
import { useNavigate } from "react-router-dom";
import ChangeSurveyFormDetailView from "./ChangeSurveyFormDetailView";
import { useDialog } from "../../context/dialog_context";

const ChangeSurveyFormDetailController = () => {
  const { _, surveyFormId } = useParams();

  const [surveyForm, setSurveyForm] = useState(null);
  const [apiError, setApiError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const { showDialog } = useDialog();

  useEffect(() => {
    getSurveyById();
  }, []);

  const getSurveyById = async () => {
    try {
      const response = await ApiHelper.get(`/survey/${surveyFormId}`);
      setIsLoading(true);

      if (response.status !== 200) {
        throw response;
      }

      setSurveyForm(response.data.data);
    } catch (error) {
      setApiError(error.data);
    } finally {
      setIsLoading(false);
    }
  };

  const updateSurveyFormByKey = (key, value) => {
    setSurveyForm((prevState) => {
      if (prevState.InsideForm.hasOwnProperty(key)) {
        // If the key is within InsideForm, update it
        return {
          ...prevState,
          InsideForm: {
            ...prevState.InsideForm,
            [key]: value,
          },
        };
      } else if (prevState.OutsideForm.hasOwnProperty(key)) {
        // If the key is within OutsideForm, update it
        return {
          ...prevState,
          OutsideForm: {
            ...prevState.OutsideForm,
            [key]: value,
          },
        };
      } else {
        // Otherwise, update the top-level key
        return {
          ...prevState,
          [key]: value,
        };
      }
    });
  };

  // UploadImageForm Section
  const [images, setImages] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [pendingDeleteions, setPendingDeletions] = useState([]);

  const changeDataSurveyForm = async () => {
    try {
      const response = await ApiHelper.put(
        `/survey/${surveyFormId}/change`,
        surveyForm
      );

      if (response !== 200) {
        throw response;
      }
    } catch (error) {}
  };

  // Submit Button
  const submitForm = async () => {
    try {
      setIsLoading(true);
      await changeDataSurveyForm();

      const responseImage = await ApiHelper.uploadImage(
        `/survey/upload/${surveyFormId}`,
        images
      );
      const responseDeleteImage = await ApiHelper.delete(
        `/survey/image/${surveyFormId}/delete?imageIds=${pendingDeleteions}`
      );
      if (responseImage.status !== 200 || responseDeleteImage.status !== 200) {
        showDialog("Fail", "Updated Fail");
      }
      showDialog("Success", "Updated Completed");
      navigate(-1);
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const navigate = useNavigate();

  const previousPage = () => {
    navigate(-1);
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <ChangeSurveyFormDetailView
      surveyForm={surveyForm}
      updateSurveyFormByKey={updateSurveyFormByKey}
      previousPage={previousPage}
      submitForm={submitForm}
      images={images}
      setImages={setImages}
      setPendingDeletions={setPendingDeletions}
    />
  );
};

export default ChangeSurveyFormDetailController;
