import React from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import QuizIcon from "@mui/icons-material/Quiz";
import InformationForm from "./components/InformationForm";
import ListForm from "./components/ListForm";
import ErrorIcon from "@mui/icons-material/Error";
import Autocomplete from "@mui/material/Autocomplete";

const RequestFormView = (props) => {
  const {
    booking,
    showContent,
    handleBookingChange,
    handleSearchBooking,

    location,
    size,
    handleContainerIdChange,
    handleLocationChange,
    handleSizeChange,
    handleTareWeightChange,
    handleSealNoChange,
    handleContainerTypeChange,
    handleLicenseChange,

    insideFormFields,
    handleInsideFieldChange,

    outsideFormFields,
    handleOutsideFieldChange,
    handleLayPaper,
    handleRemarkLayPaper,
    handleStatus,
    handleChasis,
    handleRemark,

    showListForm,
    handleShowListForm,
    images,
    handleImageChange,
    handleClick,
    handleDeleteImage,
    fileInputRef,
    submitForm,
    apiError,
    openDialog,
    handleCloseDialog,
    listBooking,
    selectBooking,
    handleChangeSurveyCyPlace,
    surveyCyPlace,
  } = props;

  return (
    <Box>
      <Box
        sx={{ flexGrow: 1, p: 3, backgroundColor: "white", overflow: "auto" }}
      >
        <Typography
          fontWeight={"bold"}
          fontSize={24}
          borderBottom="2px solid #EFC531"
          display="inline"
        >
          แบบฟอร์ม
        </Typography>
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              mt: "2rem",
              width: "100%",
            }}
          >
            <Autocomplete
              onInputChange={handleBookingChange}
              options={listBooking}
              getOptionLabel={(option) => option.booking}
              renderInput={(params) => (
                <TextField {...params} label="Booking" />
              )}
              sx={{
                width: "40%",
                "@media (max-width: 960px)": {
                  width: "100%",
                },
              }}
            />
            <Button
              type="submit"
              variant="contained"
              sx={{
                marginLeft: "1rem",
                borderRadius: 4,
                color: "black",
                backgroundColor: "#EFC531",
                "&:hover": {
                  backgroundColor: "#EFC531",
                },
                "@media (max-width: 960px)": {
                  marginLeft: "0",
                  marginTop: "1rem",
                },
              }}
              onClick={handleSearchBooking}
            >
              Scan Text
            </Button>

            {apiError ? (
              <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                maxWidth={"sm"}
                fullWidth
              >
                <DialogTitle
                  display={"flex"}
                  alignItems={"center"}
                  color={"red"}
                >
                  <ErrorIcon fontSize="large" style={{ marginRight: "8px" }} />
                  Search booking again
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>{apiError}</DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseDialog}>OK</Button>
                </DialogActions>
              </Dialog>
            ) : null}
          </Box>
          {booking && (
            <Typography sx={{ marginY: 2 }}>
              {" "}
              Booking : <b>{booking}</b>
            </Typography>
          )}
        </Box>
        <Divider sx={{ margin: "2rem 0" }} />

        {showContent ? (
          <InformationForm
            location={location}
            size={size}
            handleContainerIdChange={handleContainerIdChange}
            handleLocationChange={handleLocationChange}
            handleSizeChange={handleSizeChange}
            handleTareWeightChange={handleTareWeightChange}
            handleSealNoChange={handleSealNoChange}
            handleContainerTypeChange={handleContainerTypeChange}
            handleLicenseChange={handleLicenseChange}
            selectBooking={selectBooking}
            handleChangeSurveyCyPlace={handleChangeSurveyCyPlace}
            surveyCyPlace={surveyCyPlace}
          />
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              minHeight: "50vh",
              overflow: "auto",
            }}
          >
            <QuizIcon
              sx={{
                color: "#DCE0E6",
                fontSize: 128,
              }}
            />
            <Typography
              marginTop={"1rem"}
              fontWeight={"bold"}
              fontSize={20}
              display="inline"
            >
              กรุณากรอกค้นหา Booking No.
            </Typography>
          </Box>
        )}
      </Box>
      {showContent ? (
        <Box
          sx={{
            marginTop: "2rem",
            flexGrow: 1,
            p: 3,
            backgroundColor: "white",
            overflow: "auto",
          }}
        >
          <ListForm
            insideFormFields={insideFormFields}
            handleInsideFieldChange={handleInsideFieldChange}
            outsideFormFields={outsideFormFields}
            handleOutsideFieldChange={handleOutsideFieldChange}
            handleLayPaper={handleLayPaper}
            handleRemarkLayPaper={handleRemarkLayPaper}
            handleStatus={handleStatus}
            handleChasis={handleChasis}
            handleRemark={handleRemark}
            showListForm={showListForm}
            handleShowListForm={handleShowListForm}
            images={images}
            handleImageChange={handleImageChange}
            handleClick={handleClick}
            handleDeleteImage={handleDeleteImage}
            fileInputRef={fileInputRef}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginRight: "1rem",
              marginBottom: "1rem",
            }}
          >
            <Button
              type="submit"
              variant="contained"
              sx={{
                marginLeft: "1rem",
                marginTop: "1rem",
                borderRadius: 4,
                color: "white",
                width: "15%",
                height: "3rem",
              }}
              onClick={submitForm}
            >
              บันทึก
            </Button>
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

export default RequestFormView;
