import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { UserProvider } from "./context/user_context";
import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { DialogProvider } from "./context/dialog_context";
import { SearchProvider } from "./context/form_management_search_contxt";
import CustomDialog from "./components/CustomDialog";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const theme = createTheme({
  typography: {
    fontFamily: ["IBM Plex Sans Thai"].join(","),
    fontSize: 14,
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={theme}>
          <UserProvider>
            <DialogProvider>
              <SearchProvider>
                <App />
              </SearchProvider>
              <CustomDialog />
            </DialogProvider>
          </UserProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
