import React from "react";
import { useDialog } from "../context/dialog_context";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";

const CustomDialog = () => {
  const { open, title, details, hideDialog } = useDialog();

  return (
    <Dialog open={open} onClose={hideDialog}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{details}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={hideDialog} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;
