import React from "react";
import {
  Box,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  FormControlLabel,
  RadioGroup,
  FormLabel,
  Radio,
  Button,
  Typography,
} from "@mui/material";

const InformationForm = (props) => {
  const {
    location,
    size,
    handleContainerIdChange,
    handleLocationChange,
    handleSizeChange,
    handleTareWeightChange,
    handleSealNoChange,
    handleContainerTypeChange,
    handleLicenseChange,
    selectBooking,
    handleChangeSurveyCyPlace,
    surveyCyPlace,
  } = props;

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        <TextField
          required
          id="containerId"
          label="Container No."
          name="containerId"
          onChange={handleContainerIdChange}
          sx={{
            width: "40%",
            borderRadius: "1",
            "@media (max-width: 1200px)": {
              width: "100%",
              marginBottom: "1rem",
            },
          }}
        />
        <FormControl
          sx={{
            marginLeft: "1%",
            width: "9%",
            "@media (max-width: 1200px)": {
              width: "49%",
              marginBottom: "1rem",
              marginLeft: "0",
            },
            "@media (max-width: 360px)": {
              width: "100%",
              marginBottom: "1rem",
            },
          }}
        >
          <InputLabel id="location">Location</InputLabel>
          <Select
            labelId="location"
            id="location"
            label="Location"
            value={location}
            onChange={handleLocationChange}
            defaultValue={"MHP"}
          >
            <MenuItem value={"MHP"}>MHP</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="Size"
          value={size}
          disabled
          sx={{
            marginLeft: "1%",
            width: "9%",
            "@media (max-width: 1200px)": {
              width: "49%",
              marginBottom: "1rem",
              marginLeft: "1%",
            },
            "@media (max-width: 360px)": {
              width: "100%",
              marginBottom: "1rem",
            },
          }}
        />
        <TextField
          required
          id="tareWeight"
          label="Tare Weight"
          name="tareWeight"
          onChange={handleTareWeightChange}
          sx={{
            marginLeft: "1%",
            width: "39%",
            borderRadius: "1",
            "@media (max-width: 1200px)": {
              width: "100%",
              marginLeft: "0",
            },
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          marginTop: "1rem",
        }}
      >
        <TextField
          required
          id="sealNo"
          label="Seal No."
          name="sealNo"
          onChange={handleSealNoChange}
          sx={{
            width: "40%",
            borderRadius: "1",
            "@media (max-width: 1200px)": {
              width: "100%",
            },
          }}
        />
        <TextField
          required
          value={surveyCyPlace}
          disabled
          id="Container Cy Place"
          label="Container Cy Place"
          name="Container Cy Place"
          onChange={handleChangeSurveyCyPlace}
          sx={{
            width: "40%",
            borderRadius: "1",
            "@media (max-width: 1200px)": {
              width: "100%",
              marginTop: "1rem",
              marginLeft: 0,
            },
            marginLeft: "1%",
          }}
        />
      </Box>
      <Box>
        <RadioGroup
          onChange={handleContainerTypeChange}
          sx={{
            marginTop: "1rem",
            display: "flex",
            flexDirection: "row",
            textAlign: "center",
            alignItems: "center",
            "@media (max-width: 960px)": {
              flexDirection: "column",
              alignItems: "normal",
              textAlign: "unset",
            },
          }}
          defaultValue={"dry container"}
        >
          <FormLabel>Container Type</FormLabel>
          <FormControlLabel
            value="dry container"
            control={<Radio />}
            label="DRY CONTAINER"
            sx={{
              marginLeft: "2rem",
              "@media (max-width: 960px)": {
                marginLeft: "1rem",
              },
            }}
          />
          <FormControlLabel
            value="seabulk"
            control={<Radio />}
            label="SEABULK"
            sx={{
              marginLeft: "2rem",
              "@media (max-width: 960px)": {
                marginLeft: "1rem",
              },
            }}
          />
          <FormControlLabel
            value="csi"
            control={<Radio />}
            label="CSI"
            sx={{
              marginLeft: "2rem",
              "@media (max-width: 960px)": {
                marginLeft: "1rem",
              },
            }}
          />
        </RadioGroup>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          marginTop: "1rem",
        }}
      >
        <TextField
          required
          id="license"
          label="ทะเบียนรถ"
          name="license"
          onChange={handleLicenseChange}
          sx={{
            width: "40%",
            borderRadius: "1",
            "@media (max-width: 1200px)": {
              width: "50%",
            },
            "@media (max-width: 960px)": {
              width: "100%",
            },
          }}
        />
        <FormControl
          sx={{
            marginLeft: "1%",
            width: "19%",
            "@media (max-width: 1200px)": {
              width: "49%",
            },
            "@media (max-width: 960px)": {
              width: "100%",
              marginTop: "1rem",
              marginLeft: "0",
            },
          }}
        >
          {/* <InputLabel id="location">Other</InputLabel>
          <Select
            labelId="other"
            id="other"
            label="Other"
            value={other}
            onChange={handleOtherChange}
          >
            <MenuItem value={"Premium"}>Premium</MenuItem>
            <MenuItem value={"Normal"}>Normal</MenuItem>
          </Select> */}
          <TextField
            label="Booking Remark"
            value={selectBooking.other}
            disabled
          />
        </FormControl>
      </Box>
    </Box>
  );
};

export default InformationForm;
