import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ApiHelper from "../../api/api_helper";
import { Box, Typography, Button, Grid, Paper } from '@mui/material';
import LoadingIndicator from "../loading/LoadingIndicator";
import { useNavigate } from "react-router-dom";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import InsideFormView from "./components/InsideFormView";
import OutsideFormView from "./components/OutsideFormView";

const SurveyFormsDetailView = () => {
    const { _, surveyFormId } = useParams();

    const [surveyForm, setSurveyForm] = useState(null);
    const [images, setImages] = useState([]);
    const [apiError, setApiError] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        getSurveyById();
    }, []);

    const getSurveyById = async () => {
        try {
            const response = await ApiHelper.get(`/survey/${surveyFormId}`);
            setIsLoading(true);

            if (response.status !== 200) {
                throw response;
            }

            setSurveyForm(response.data.data);
            setImages(response.data.data.SurveyImages);
        } catch (error) {
            setApiError(error.data)
        } finally {
            setIsLoading(false);
        }
    };

    const openImageInNewWindow = (imageUrl) => {
        window.open(process.env.REACT_APP_API_URL + imageUrl, '_blank');
    };

    const previousPage = () => {
        navigate(-1);
    };

    if (isLoading) {
        return <LoadingIndicator />
    };

    return (
        <Box>
            <Box sx={{ flexGrow: 1, p: 3, backgroundColor: "white", overflow: 'auto', borderRadius: 2 }} >
                <Typography fontWeight={'bold'} fontSize={24} borderBottom='2px solid #EFC531' display='inline'>
                    รายละเอียดแบบฟอร์ม
                </Typography>

                <Grid container spacing={2} sx={{
                    mt: '2rem',
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    <Grid item xs={12} lg={4} display={'flex'} >
                        <Typography>Container No. </Typography>
                        <Typography ml={2} color={'#1364D7'}> {surveyForm.containerId}</Typography>
                    </Grid>
                    <Grid item xs={12} lg={4} display={'flex'} >
                        <Typography>Booking No. </Typography>
                        <Typography ml={2} color={'#1364D7'}> {surveyForm.Booking.booking}</Typography>
                    </Grid>
                    <Grid item xs={12} lg={4} display={'flex'} >
                        <Typography>Location </Typography>
                        <Typography ml={2} color={'#1364D7'}> {surveyForm.location ? surveyForm.location : '-'}</Typography>
                    </Grid>
                    <Grid item xs={12} lg={4} display={'flex'} >
                        <Typography>Size </Typography>
                        <Typography ml={2} color={'#1364D7'}> {surveyForm.size ? surveyForm.size : '-'}</Typography>
                    </Grid>
                    <Grid item xs={12} lg={4} display={'flex'} >
                        <Typography>Tare Weight </Typography>
                        <Typography ml={2} color={'#1364D7'}> {surveyForm.tareWeight ? surveyForm.tareWeight : '-'}</Typography>
                    </Grid>
                    <Grid item xs={12} lg={4} display={'flex'} >
                        <Typography>Seal No. </Typography>
                        <Typography ml={2} color={'#1364D7'}> {surveyForm.sealNo ? surveyForm.sealNo : '-'}</Typography>
                    </Grid>
                    <Grid item xs={12} lg={4} display={'flex'} >
                        <Typography>Container Type </Typography>
                        <Typography ml={2} color={'#1364D7'}> {surveyForm.containerType ? surveyForm.containerType : '-'}</Typography>
                    </Grid>
                    <Grid item xs={12} lg={4} display={'flex'} >
                        <Typography>ทะเบียนรถ </Typography>
                        <Typography ml={2} color={'#1364D7'}> {surveyForm.license ? surveyForm.license : '-'}</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{
                    mt: '1rem',
                    display: 'flex',
                }}>
                    <InsideFormView
                        insideForm={surveyForm.InsideForm}
                    />
                    <OutsideFormView
                        outsideForm={surveyForm.OutsideForm}
                    />
                </Grid>
                <Box marginTop={'1rem'}>
                    <Typography fontWeight={'bold'} fontSize={18}>
                        รูปภาพ
                    </Typography>
                    <Box
                        sx={{
                            backgroundColor: 'white',
                            marginTop: '1rem',
                            padding: '1rem',
                            borderRadius: '8px',
                            border: '1px solid #B9C0E0',
                        }}
                    >
                        { images.length > 0 ? (
                            <Grid
                                container 
                                spacing={2} 
                            >
                                {images.map((image, index) => (
                                    <Grid item xs={12} sm={6} md={3} lg={2} key={index}>
                                        <img
                                            src={process.env.REACT_APP_API_URL + image.imageUrl}
                                            alt={`Uploaded ${index}`}
                                            style={{ width: '100%', height: 'auto', minWidth: '100px', minHeight: '100px', borderRadius: '4px', cursor: 'zoom-in', }}
                                            onClick={() => openImageInNewWindow(image.imageUrl)}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        ) : (
                            <Box>
                                No image
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>
            <Button
                variant="contained"
                component="label"
                startIcon={<ChevronLeftIcon />}
                sx={{
                    marginTop: '1rem',
                    backgroundColor: '#262527',
                    '&:hover': {
                        backgroundColor: '#262527',
                    },
                    color: 'white',
                    borderRadius: '10px',
                    fontSize: '1rem'
                }}
                onClick={previousPage}
            >
                ย้อนกลับ
            </Button>
        </Box>
    );
};

export default SurveyFormsDetailView;