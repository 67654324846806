import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ApiHelper from "../../api/api_helper";
import GateoutView from "./GateoutView";
import LoadingIndicator from "../loading/LoadingIndicator";
import { useDialog } from "../../context/dialog_context";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";

const GateoutController = () => {
  const [booking, setBooking] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [listBooking, setListBooking] = useState([]);
  const { showDialog } = useDialog();
  const [listSurveyForm, setListSurveyForm] = useState([]);
  const [openConfirmGateOutPopup, setOpenConfirmGateOutPopup] = useState(false);
  const [openRejectedGateOutPopup, setOpenRejectedGateOutPopup] =
    useState(false);
  const [selectSurvey, setSelectSurvey] = useState();

  // Init state
  const navigate = useNavigate();

  const handleBookingChange = (event, newValue) => {
    setBooking(newValue);
  };

  const clickViewDetailOnSurveyWaiting = (id) => {
    navigate(`/gate-out-detail/${id}`);
  };

  const clickSearchBooking = () => {
    const encodeBookingId = encodeURIComponent(booking);
    navigate(`/gate-out/${encodeBookingId}`);
  };

  const handleCloseConfirmGateOupPopup = () => {
    setOpenConfirmGateOutPopup(false);
  };

  const handleCloseRejectedGateOupPopup = () => {
    setOpenRejectedGateOutPopup(false);
  };

  useEffect(() => {
    if (booking) {
      getListBooking();
    }
  }, [booking]);

  const getListBooking = async () => {
    try {
      const response = await ApiHelper.get(
        `/booking/bookings?cyDate=null&loadingDate=null&ci=null&booking=${booking}&bookingStatus=all`
      );
      setIsLoading(true);
      if (response.status !== 200) {
        throw response;
      }
      setListBooking(response.data.data);
    } catch (error) {
      showDialog("เกิดข้อผิดพลาด", "ไม่สามารถดึงข้อมูล Booking ได้");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getListSurveyForm();
  }, []);

  const getListSurveyForm = async () => {
    try {
      const response = await ApiHelper.get(`/survey?gateStatus=waiting`);
      setIsLoading(true);
      if (response.status !== 200) {
        throw response;
      }
      setListSurveyForm(response.data.data);
    } catch (error) {
      showDialog("เกิดข้อผิดพลาด", "ไม่สามารถดึงข้อมูล Booking ได้");
    } finally {
      setIsLoading(false);
    }
  };

  const tableColumns = [
    { id: "1", label: "ลำดับ", minWidth: 50, key: "order" },
    { id: "4", label: "Booking", minWidth: 100, key: "bookingId" },
    {
      id: "2",
      label: "วันที่ Request",
      minWidth: 100,
      key: "requestGateOutDate",
    },
    { id: "3", label: "Container ID", minWidth: 100, key: "containerId" },
    { id: "6", label: "Truck License", minWidth: 100, key: "truckLicense" },
    { id: "7", label: "Trailer", minWidth: 100, key: "trailer" },
    { id: "8", label: "Action", minWidth: 100, key: "Action" },
  ];

  const onClickApprove = (survey) => {
    setSelectSurvey(survey);
    setOpenConfirmGateOutPopup(true);
  };

  const onClickReject = (survey) => {
    setSelectSurvey(survey);
    setOpenRejectedGateOutPopup(true);
  };

  const approveButton = async () => {
    try {
      setIsLoading(true);
      let updateSurveyData = selectSurvey;
      updateSurveyData.updateFrom = "approveGateOut";
      updateSurveyData.gateStatus = "out";
      const response = await ApiHelper.put(
        `survey/${updateSurveyData.id}/change`,
        updateSurveyData
      );

      if (response.status !== 200) {
        throw response;
      }
      showDialog("อัพเดทข้อมูลสำเร็จแล้ว", "");
      getListSurveyForm();
    } catch (error) {
      showDialog("เกิดข้อผิดพลาด", error.message);
    } finally {
      setIsLoading(false);
      handleCloseConfirmGateOupPopup();
    }
  };

  const notApproveButton = async () => {
    try {
      setIsLoading(true);
      let updateSurveyData = selectSurvey;
      updateSurveyData.gateStatus = "in";
      updateSurveyData.requestGateOutDate = null;
      const response = await ApiHelper.put(
        `survey/${updateSurveyData.id}}/change`,
        updateSurveyData
      );

      if (response.status !== 200) {
        throw response;
      }
      showDialog("อัพเดทข้อมูลสำเร็จแล้ว", "");
      getListSurveyForm();
    } catch (error) {
      showDialog("เกิดข้อผิดพลาด", error.message);
    } finally {
      setIsLoading(false);
      handleCloseRejectedGateOupPopup();
    }
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <div>
      <GateoutView
        listSurveyForm={listSurveyForm}
        tableColumns={tableColumns}
        listBooking={listBooking}
        handleBookingChange={handleBookingChange}
        clickViewDetailOnSurveyWaiting={clickViewDetailOnSurveyWaiting}
        clickSearchBooking={clickSearchBooking}
        onClickApprove={onClickApprove}
        onClickReject={onClickReject}
      />
      <Dialog
        open={openConfirmGateOutPopup}
        onClose={handleCloseConfirmGateOupPopup}
      >
        <DialogContent>
          <Typography sx={{ fontWeight: "bold", fontSize: 32 }}>
            คุณต้องการที่จะ Gate out ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              marginTop: "1rem",
              width: "10%",
              color: "white",
              borderRadius: "10px",
              fontSize: "1rem",
              backgroundColor: "#ED5768",
              "&:hover": {
                backgroundColor: "#ED5768",
              },
            }}
            onClick={handleCloseConfirmGateOupPopup}
          >
            ยกเลิก
          </Button>
          <Button
            variant="contained"
            sx={{
              marginTop: "1rem",
              width: "10%",
              color: "white",
              borderRadius: "10px",
              fontSize: "1rem",
              backgroundColor: "#4AAEAF",
              "&:hover": {
                backgroundColor: "#4AAEAF",
              },
            }}
            onClick={approveButton}
          >
            ยืนยัน
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openRejectedGateOutPopup}
        onClose={handleCloseRejectedGateOupPopup}
      >
        <DialogContent>
          <Typography
            sx={{ fontWeight: "bold", fontSize: 32, textAlign: "center" }}
          >
            คุณต้องการที่จะยกเลิกการรอการ Gate out ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              marginTop: "1rem",
              width: "10%",
              color: "white",
              borderRadius: "10px",
              fontSize: "1rem",
              backgroundColor: "#ED5768",
              "&:hover": {
                backgroundColor: "#ED5768",
              },
            }}
            onClick={handleCloseRejectedGateOupPopup}
          >
            ยกเลิก
          </Button>
          <Button
            variant="contained"
            sx={{
              marginTop: "1rem",
              width: "10%",
              color: "white",
              borderRadius: "10px",
              fontSize: "1rem",
              backgroundColor: "#4AAEAF",
              "&:hover": {
                backgroundColor: "#4AAEAF",
              },
            }}
            onClick={notApproveButton}
          >
            ยืนยัน
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default GateoutController;
