import React from "react";
import dayjs from "dayjs";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  capitalize,
  Grid,
  TextField,
} from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import CustomTextField from "./components/CustomTextField";
import SearchIcon from "@mui/icons-material/Search";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import DownloadIcon from "@mui/icons-material/Download";
import BookingTable from "./components/BookingTable";

const FormManagementView = (props) => {
  const {
    handleCyDateChange,
    handleLoadingDateChange,
    handleCiChange,
    handleBookingChange,
    bookingStatus,
    bookingOptions,
    handleBookingStatusChange,
    handleContainerIdChange,
    listBooking,
    tableColumns,
    page,
    handleChangePage,
    dialogOpen,
    handleDialogClose,
    handleFileChange,
    handleUpload,
    excelData,
    uploadError,
    clickChangeDetail,
    clickViewDetail,
    cyDate,
    ci,
    booking,
    loadingDate,
    containerId,
  } = props;

  const formattedBookings = listBooking.map((booking) => ({
    ...booking,
    cyDate: booking.cyDate ? dayjs(booking.cyDate).format("DD/MM/YYYY") : null,
    loadingDate: booking.loadingDate
      ? dayjs(booking.loadingDate).format("DD/MM/YYYY")
      : null,
    closingDate: booking.closingDate
      ? dayjs(booking.closingDate).format("DD/MM/YYYY")
      : null,
    losingTime: booking.losingTime
      ? dayjs(booking.losingTime).format("DD/MM/YYYY")
      : null,
    importDate: booking.importDate
      ? dayjs(booking.importDate).format("DD/MM/YYYY")
      : null,
  }));

  return (
    <Box
      sx={{
        flexGrow: 1,
        p: 3,
        backgroundColor: "white",
        minHeight: "100vh",
        overflow: "auto",
      }}
    >
      <Typography
        fontWeight={"bold"}
        fontSize={24}
        borderBottom="2px solid #EFC531"
        display="inline"
      >
        หน้าการจัดการ
      </Typography>
      <Box>
        <Grid
          container
          spacing={2}
          sx={{
            marginTop: "0.5rem",
            display: "flex",
            alignItems: "center",
          }}
        >
          {/* <Grid item xs={12} md={6} lg={3} display={"flex"}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container>
                <Grid item xs={12}>
                  <DemoContainer components={["DesktopDatePicker"]}>
                    <DesktopDatePicker
                      label="วันที่เริ่มต้น"
                      onChange={handleStartDateChange}
                      format="DD/MM/YYYY"
                      sx={{
                        width: "100%",
                      }}
                    />
                  </DemoContainer>
                </Grid>
              </Grid>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={6} lg={3} display={"flex"}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container>
                <Grid item xs={12}>
                  <DemoContainer components={["DesktopDatePicker"]}>
                    <DesktopDatePicker
                      label="วันที่สิ้นสุด"
                      onChange={handleEndDateChange}
                      format="DD/MM/YYYY"
                      sx={{
                        width: "100%",
                      }}
                    />
                  </DemoContainer>
                </Grid>
              </Grid>
            </LocalizationProvider>
          </Grid> */}
          <Grid item xs={12} md={6} lg={3} display={"flex"}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container>
                <Grid item xs={12}>
                  <DesktopDatePicker
                    label="CY Date"
                    format="DD/MM/YYYY"
                    value={cyDate ? dayjs(cyDate) : null}
                    onChange={handleCyDateChange}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                    sx={{
                      width: "100%",
                    }}
                  />
                </Grid>
              </Grid>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={6} lg={3} display={"flex"}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container>
                <Grid item xs={12}>
                  <DesktopDatePicker
                    label="Loading Date"
                    format="DD/MM/YYYY"
                    value={loadingDate ? dayjs(loadingDate) : null}
                    onChange={handleLoadingDateChange}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                    sx={{
                      width: "100%",
                    }}
                  />
                </Grid>
              </Grid>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={6} lg={3} display={"flex"}>
            <CustomTextField
              id="ci"
              label="ค้นหา CI"
              name="ci"
              placeholder="CI"
              icon={<SearchIcon />}
              onChange={handleCiChange}
              value={ci}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3} display={"flex"}>
            <CustomTextField
              id="booking"
              label="ค้นหา Booking"
              name="booking"
              placeholder="Booking"
              icon={<LibraryBooksIcon />}
              onChange={handleBookingChange}
              value={booking}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3} display={"flex"}>
            <FormControl margin="dense" sx={{ width: "100%" }}>
              <InputLabel id="bookingStatus">Booking Status</InputLabel>
              <Select
                labelId="bookingStatus"
                id="bookingStatus"
                value={bookingStatus}
                onChange={handleBookingStatusChange}
                label="bookingStatus"
              >
                {bookingOptions.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {capitalize(option)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={3} display={"flex"}>
            <CustomTextField
              id="containerId"
              label="ค้นหา Container ID"
              name="containerId"
              placeholder="Container ID"
              icon={<SearchIcon />}
              onChange={handleContainerIdChange}
              value={containerId}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "flex-end",
        }}
      >
        <label htmlFor="raised-button-file">
          <Button
            variant="contained"
            component="label"
            startIcon={<DownloadIcon />}
            sx={{
              marginLeft: "1rem",
              marginRight: "10px",
              backgroundColor: "#1F6E42",
              "&:hover": {
                backgroundColor: "#1F6E42",
              },
              color: "white",
              borderRadius: "10px",
              "@media (max-width: 480px)": {
                marginTop: "1rem",
              },
            }}
          >
            นำเข้าไฟล์ Excel
            <input
              type="file"
              accept=".xlsx, .xls"
              style={{ display: "none" }}
              onChange={(event) => handleFileChange(event)}
            />
          </Button>
          <a download="template" href="./template-form.xlsx">
            ตัวอย่าง รูปแบบไฟล์ที่ต้อง Import{" "}
          </a>
          <Dialog open={dialogOpen} onClose={handleDialogClose} maxWidth={"lg"}>
            <DialogTitle>Review Excel Data</DialogTitle>
            <DialogContent>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      {excelData.length > 0 &&
                        Object.keys(excelData[0]).map((key) => (
                          <TableCell key={key}>{key}</TableCell>
                        ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {excelData.map((row, index) => (
                      <TableRow key={index}>
                        {Object.values(row).map((value, colIndex) => (
                          <TableCell key={colIndex}>{value}</TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleUpload}
                color="primary"
                sx={{
                  color: "white",
                  backgroundColor: "#4CAF50",
                  "&:hover": { backgroundColor: "#45a049" },
                }}
              >
                Confirm
              </Button>
              <Button
                onClick={handleDialogClose}
                color="primary"
                sx={{
                  color: "white",
                  backgroundColor: "#f44336",
                  "&:hover": { backgroundColor: "#d32f2f" },
                }}
              >
                Cancel
              </Button>
            </DialogActions>
            {uploadError ? (
              <Typography sx={{ marginLeft: "1rem", color: "red" }}>
                Status: {uploadError}
              </Typography>
            ) : null}
          </Dialog>
        </label>
      </Box>
      <BookingTable
        listBooking={formattedBookings}
        tableColumns={tableColumns}
        handleChangePage={handleChangePage}
        page={page}
        clickChangeDetail={clickChangeDetail}
        clickViewDetail={clickViewDetail}
      />
    </Box>
  );
};

export default FormManagementView;
