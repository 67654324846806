import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/user_context";
import LoginView from "./LoginView";
import ApiHelper from "../../api/api_helper";

const LoginController = () => {
  // Use Navigate
  const navigate = useNavigate();

  // Init state

  useEffect(() => {
    const token = localStorage.getItem("auth_token");
    if (token) {
      user.setIsAuth(true);
      navigate("/");
    }
  });

  // Use context
  const user = useAuth();

  // Define state
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [formError, setFormError] = useState({ username: "", password: "" });
  const [apiError, setApiError] = useState("");
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // Set Show password
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  // onsubmit login button
  const loginUser = async () => {
    try {
      const response = await ApiHelper.post("/auth/login", {
        username: username,
        password: password,
      });
      if (response.status !== 200) {
        throw response.data.data;
      }
      localStorage.setItem("auth_token", response.data.token);
      user.setUser(response.data.user);
      user.setIsAuth(true);
      navigate("/");
    } catch (error) {
      user.setIsAuth(false);
      setApiError(error);
      handleOpenDialog();
    }
  };

  // Set value username field
  const handleusernameChange = (event) => {
    setUsername(event.target.value);
    if (event.target.value === "") {
      setFormError({ ...formError, username: "username is required" });
    } else {
      setFormError({ ...formError, username: "" });
    }
  };

  // Set value password field
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    if (event.target.value === "") {
      setFormError({ ...formError, password: "Password is required" });
    } else {
      setFormError({ ...formError, password: "" });
    }
  };

  return (
    <LoginView
      loginUser={loginUser}
      handleTogglePassword={handleTogglePassword}
      showPassword={showPassword}
      handlePasswordChange={handlePasswordChange}
      handleusernameChange={handleusernameChange}
      formError={formError}
      apiError={apiError}
      openDialog={openDialog}
      handleCloseDialog={handleCloseDialog}
    />
  );
};

export default LoginController;
