import React from 'react';
import './LoadingIndicator.css';
import { Box } from '@mui/material'

const LoadingIndicator = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <div className="spinner"></div>
        </Box>
    );
}

export default LoadingIndicator;