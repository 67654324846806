import React from "react";
import { Box, Button, Grid, Typography, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import ExportBookingTable from "./ExportBookingTable";
import dayjs from "dayjs";

const ExportBookingView = (props) => {
  const {
    onClickExportButton,
    handleChangeBooking,
    handleShippingLir,
    handleCi,
    handleCyDateStart,
    handleCyDateEnd,
    handleLoadingStart,
    handleLoadingEnd,
    handleContainerNo,
    tableColumns,
    bookingData,
    onClickSearch,
    clickViewDetail,
    booking,
    shippingLir,
    ci,
    cyDateStart,
    cyDateEnd,
    loadingStart,
    loadingEnd,
    containerNo,
  } = props;
  return (
    <Box>
      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        Export Booking
      </Typography>
      <Grid container spacing={3} alignItems="center">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid item xs={12} sm={6} md={3}>
            <DesktopDatePicker
              label="CY Start Date"
              format="DD/MM/YYYY"
              value={cyDateStart ? dayjs(cyDateStart) : null}
              onChange={handleCyDateStart}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DesktopDatePicker
              label="CY End Date"
              format="DD/MM/YYYY"
              value={cyDateEnd ? dayjs(cyDateEnd) : null}
              onChange={handleCyDateEnd}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DesktopDatePicker
              label="Loading Start Date"
              format="DD/MM/YYYY"
              value={loadingStart ? dayjs(loadingStart) : null}
              onChange={handleLoadingStart}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DesktopDatePicker
              label="Loading End Date"
              format="DD/MM/YYYY"
              value={loadingEnd ? dayjs(loadingEnd) : null}
              onChange={handleLoadingEnd}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </Grid>
          {/* Text Fields */}
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Booking"
              fullWidth
              value={booking}
              onChange={(e) => handleChangeBooking(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Shipping Line"
              fullWidth
              value={shippingLir}
              onChange={(e) => handleShippingLir(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="CI Number"
              fullWidth
              value={ci}
              onChange={(e) => handleCi(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Container No"
              fullWidth
              value={containerNo}
              onChange={(e) => handleContainerNo(e.target.value)}
            />
          </Grid>
        </LocalizationProvider>
        <Grid item xs={6}>
          <Button
            variant="contained"
            startIcon={<InsertDriveFileIcon />}
            sx={{
              marginTop: 2,
              marginBottom: 5,
              backgroundColor: "#f1d15c",
              "&:hover": {
                backgroundColor: "#f6e299",
              },
              color: "black",
              width: "25%",
            }}
            onClick={onClickSearch}
          >
            Search
          </Button>
          {bookingData && bookingData.length > 0 && (
            <Button
              variant="contained"
              startIcon={<InsertDriveFileIcon />}
              sx={{
                marginLeft: 2,
                marginTop: 2,
                marginBottom: 5,
                backgroundColor: "#1F6E42",
                "&:hover": {
                  backgroundColor: "#1F6E42",
                },
                color: "white",
                width: "25%",
              }}
              onClick={onClickExportButton}
            >
              Export
            </Button>
          )}
        </Grid>

        {bookingData && bookingData.length > 0 && (
          <ExportBookingTable
            listBooking={bookingData}
            tableColumns={tableColumns}
            clickViewDetail={clickViewDetail}
          />
        )}
      </Grid>
    </Box>
  );
};

export default ExportBookingView;
