import React, { useState } from "react";
import './User.css'
import { Box, Typography, Button } from "@mui/material";
import UserTable from "./components/UserTable";
import CustomButton from "./components/CustomButton";
import AirlineSeatReclineNormalIcon from '@mui/icons-material/AirlineSeatReclineNormal';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';

const UserView = (props) => {
    const {
        selectRole,
        getListUser,
        listUser,
        tableColumns,
        handleChangePage,
        page,
        onClickAddButton,
        onClickEditButton
    } = props;

    const handleButtonClick = (userType) => {
        getListUser(userType);
    };

    return (
        <Box sx={{ flexGrow: 1, p: 3, backgroundColor: "white", minHeight: '100vh', overflow: 'auto' }} >
            <Typography fontWeight={'bold'} fontSize={24} borderBottom='2px solid #EFC531' display='inline'>
                ระบบจัดการสมาชิก
            </Typography>
            <Box 
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    mt: '2rem',
                    '@media (max-width: 760px)': {
                        flexDirection: 'column',
                        gap: 1
                    },
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '50%',
                        justifyContent: 'space-between',
                        '@media (max-width: 760px)': {
                            width: '100%'
                        },
                    }}
                >
                    <CustomButton 
                        icon={<AirlineSeatReclineNormalIcon />}
                        text="Survey"
                        isActive={selectRole === 'survey'}
                        onClick={() => handleButtonClick('survey')}
                    />
                    <CustomButton 
                        icon={<PersonIcon />}
                        text="admin"
                        isActive={selectRole === 'admin'}
                        onClick={() => handleButtonClick('admin')}
                    />
                    <CustomButton 
                        icon={<GroupIcon />}
                        text="general"
                        isActive={selectRole === 'general'}
                        onClick={() => handleButtonClick('general')}
                    />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '50%',
                        justifyContent: 'flex-end',
                        '@media (max-width: 760px)': {
                            width: '100%',
                        },
                    }}
                >
                    <Button
                        variant="contained"
                        startIcon={<PersonAddAlt1Icon />}
                        onClick={onClickAddButton}
                        sx={{
                            width: '40%',
                            backgroundColor: '#262527',
                            '&:hover': {
                                backgroundColor: '#262527',
                            },
                            color: 'white',
                            borderRadius: '10px'
                        }}
                    >
                        เพิ่มสมาชิก
                    </Button>
                </Box>
            </Box>
            <UserTable listUser={listUser} onClickEditButton={onClickEditButton} tableColumns={tableColumns} handleChangePage={handleChangePage} page={page} />
        </Box>
    )
}

export default UserView;