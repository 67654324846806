import React, { createContext, useContext, useState } from "react";

// Context creation
const SearchContext = createContext();

export const useSearch = () => useContext(SearchContext);

export const SearchProvider = ({ children }) => {
  const [cyDate, setCyDate] = useState(null);
  const [loadingDate, setLoadingDate] = useState(null);
  const [ci, setCi] = useState(null);
  const [booking, setBooking] = useState(null);
  const [containerId, setContainerId] = useState(null);

  return (
    <SearchContext.Provider
      value={{
        cyDate,
        setCyDate,
        loadingDate,
        setLoadingDate,
        ci,
        setCi,
        booking,
        setBooking,
        containerId,
        setContainerId,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
