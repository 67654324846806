import React, { useState, useEffect, useRef } from "react";
import RequestFormView from "./RequestFormView";
import ApiHelper from "../../api/api_helper";
import LoadingIndicator from "../loading/LoadingIndicator";
import { useDialog } from "../../context/dialog_context";
import { useNavigate } from "react-router-dom";

const RequestFormController = () => {
  const [booking, setBooking] = useState("");

  const navigate = useNavigate();

  // Information Form Section
  const [containerId, setContainerId] = useState(null);
  const [location, setLocation] = useState("MHP");
  const [size, setSize] = useState(10);
  const [tareWeight, setTareWeight] = useState(null);
  const [sealNo, setSealNo] = useState(null);
  const [containerType, setContainerType] = useState("dry container");
  const [license, setLicense] = useState(null);
  const [showContent, setShowContent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [listBooking, setListBooking] = useState([]);
  const { showDialog } = useDialog();
  const [selectBooking, setSelectBooking] = useState(null);
  const [surveyCyPlace, setSurveyCyPlacee] = useState(null);

  useEffect(() => {
    if (booking) {
      getListBooking();
    }
  }, [booking]);

  const getListBooking = async () => {
    try {
      const response = await ApiHelper.get(
        `/booking/bookings?cyDate=null&loadingDate=null&ci=null&booking=${booking}&bookingStatus=all`
      );
      setIsLoading(true);
      if (response.status !== 200) {
        throw response;
      }
      setListBooking(response.data.data);
    } catch (error) {
      setApiError(error.data);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBookingChange = (event, newValue) => {
    setBooking(newValue);
  };

  const handleSearchBooking = async () => {
    try {
      setIsLoading(true);
      const response = await ApiHelper.get(`/booking/check?booking=${booking}`);

      if (response.status !== 200) {
        setApiError(response.data.data);
        setOpenDialog(true);
        setShowContent(false);
        return;
      }

      setSelectBooking(response.data.data);
      setSurveyCyPlacee(response.data.data.cyPlace);
      setSize(response.data.data.size);
      setShowContent(true);
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const handleContainerIdChange = (event) => {
    setContainerId(event.target.value);
  };

  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };

  const handleSizeChange = (event) => {
    setSize(event.target.value);
  };

  const handleTareWeightChange = (event) => {
    setTareWeight(event.target.value);
  };

  const handleSealNoChange = (event) => {
    setSealNo(event.target.value);
  };

  const handleContainerTypeChange = (event) => {
    setContainerType(event.target.value);
  };

  const handleLicenseChange = (event) => {
    setLicense(event.target.value);
  };

  const handleChangeSurveyCyPlace = (event) => {
    setSurveyCyPlacee(event.target.value);
  };

  // ListForm Section
  const [showListForm, setShowListForm] = useState(true);

  const handleShowListForm = () => {
    setShowListForm(!showListForm);
  };

  // ListForm Section
  // InsideForm Section
  const insideFormFields = [
    { id: "1", key: "dry", label: "DRY (แห้งสะอาด)" },
    { id: "2", key: "gouge", label: "GOUGE (เสี้ยน)" },
    { id: "3", key: "light", label: "LIGHT (รอยรั่ว)" },
    { id: "4", key: "dirty", label: "DIRTY (คราบสกปรก)" },
    { id: "5", key: "rusty", label: "RUSTY (สนิม)" },
    { id: "6", key: "odor", label: "ODOR (กลิ่นเหม็น)" },
    { id: "7", key: "nail", label: "NAIL (ตะปู)" },
    { id: "8", key: "insect", label: "INSECT (ซากสัตว์)" },
    { id: "9", key: "oldGoods", label: "OLD GOODS (สินค้าเก่า)" },
    { id: "10", key: "dentInside", label: "DENT (บุบ ยุบ)" },
  ];

  const [insideFieldValues, setInsideFieldValues] = useState({});

  const handleInsideFieldChange = (key) => (event) => {
    const newValue = event.target.value;

    setInsideFieldValues((prevValues) => ({
      ...prevValues,
      [key]: newValue,
    }));
  };
  // OutsideForm Section
  const outsideFormFields = [
    { id: "11", key: "dentOutside", label: "DENT (บุบ ยุบ)" },
    { id: "12", key: "handle", label: "HANDLE (มือจับ)" },
    { id: "13", key: "lock", label: "LOCK (ล็อคซีล)" },
    { id: "14", key: "door", label: "DOOR (ประตูตู้)" },
    { id: "15", key: "number", label: "NUMBER (เลขตู้)" },
    { id: "16", key: "vent", label: "VENT (ช่องระบายอากาศ)" },
  ];

  const [outsideFieldValues, setOutsideFieldValues] = useState({});
  const [layPaper, setLayPaper] = useState(false);
  const [remarkLayPaper, setRemarkLayPaper] = useState(null);
  const [status, setStatus] = useState("AV");
  const [chasis, setChasis] = useState(false);
  const [remark, setRemark] = useState(null);

  const handleOutsideFieldChange = (key) => (event) => {
    const newValue = event.target.value;

    setOutsideFieldValues((prevValues) => ({
      ...prevValues,
      [key]: newValue,
    }));
  };

  useEffect(() => {
    const initialInsideValues = insideFormFields.reduce(
      (acc, field) => ({
        ...acc,
        [field.key]: "OK",
      }),
      {}
    );

    const initialOutsideValues = outsideFormFields.reduce(
      (acc, field) => ({
        ...acc,
        [field.key]: "OK",
      }),
      {}
    );

    setInsideFieldValues(initialInsideValues);
    setOutsideFieldValues(initialOutsideValues);
  }, []);

  const handleLayPaper = (event) => {
    setLayPaper(event.target.checked);
  };

  const handleRemarkLayPaper = (event) => {
    setRemarkLayPaper(event.target.value);
  };

  const handleStatus = (event) => {
    setStatus(event.target.value);
  };

  const handleChasis = (event) => {
    setChasis(event.target.checked);
  };

  const handleRemark = (event) => {
    setRemark(event.target.value);
  };

  const [images, setImages] = useState([]);
  const fileInputRef = useRef(null);

  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);
    const newImages = files.map((file) => ({
      url: URL.createObjectURL(file),
      file: file,
    }));
    setImages(newImages);
  };

  const handleDeleteImage = (index) => {
    URL.revokeObjectURL(images[index].url);
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const isFieldValid = (fieldValue) => {
    return fieldValue !== null && fieldValue !== undefined && fieldValue !== "";
  };

  const validateFields = (data) => {
    const invalidFields = [];
    const nonRequiredFields = ["remarkLayPaper", "remark"]; // List non-required fields

    for (let key in data) {
      if (
        typeof data[key] === "object" &&
        data[key] !== null &&
        !Array.isArray(data[key])
      ) {
        // Recursive check for nested fields
        const nestedInvalidFields = validateFields(data[key]);
        if (nestedInvalidFields !== true) {
          nestedInvalidFields.forEach((nestedKey) =>
            invalidFields.push(`${key}.${nestedKey}`)
          );
        }
      } else {
        if (!nonRequiredFields.includes(key) && !isFieldValid(data[key])) {
          invalidFields.push(key); // Collect names of invalid fields
        }
      }
    }

    return invalidFields.length === 0 ? true : invalidFields;
  };

  const createSurveyForm = async () => {
    const data = {
      booking,
      containerId,
      location,
      tareWeight,
      sealNo,
      containerType,
      license,
      layPaper,
      remarkLayPaper,
      status,
      chasis,
      remark,
      size,
      surveyCyPlace,
    };

    const finalData = Object.assign(
      {},
      data,
      insideFieldValues,
      outsideFieldValues
    );
    const validationResult = validateFields(finalData);
    if (validationResult !== true) {
      const missingFields = validationResult.join(", ");
      showDialog("ไม่สามารถสร้างฟอร์มได้", `กรุณาใส่ข้อมูล : ${missingFields}`);
      return;
    }

    if (!images || images.length <= 0) {
      showDialog("ไม่สามารถสร้างฟอร์มได้", `กรุณาอัพโหลดรูปภาพ`);
      return;
    }

    if (!containerId || containerId.length !== 11) {
      showDialog("ไม่สามารถสร้างฟอร์มได้", "ContainerId ต้องมี 11 ตัวอักษร");
      return;
    }

    try {
      setIsLoading(true);
      const response = await ApiHelper.post(`/survey/create`, finalData);

      if (response.status !== 201) {
        throw response;
      }

      const responseImage = await ApiHelper.uploadImage(
        `/survey/upload/${response.data.data.id}`,
        images
      );

      console.log(images);

      if (responseImage.status !== 200) {
        throw new Error("Image upload failed");
      }

      showDialog(
        "สร้างฟอร์มสำเร็จ",
        "กรุณาติดต่อเจ้าหน้าที่เพื่อดำเนินการต่อไป"
      );
      navigate("/");
    } catch (error) {
      console.log(error);
      showDialog("ไม่สามารถสร้างฟอร์มได้", error.data.data || "Unknown error");
    } finally {
      setImages([]);
      setIsLoading(false);
    }
  };

  // Submit Button
  const submitForm = async () => {
    try {
      await createSurveyForm();
    } catch (error) {
      showDialog("สร้างฟอร์มไม่สำเร็จ", `สาเหตุ : ${error.message}`);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <RequestFormView
      booking={booking}
      showContent={showContent}
      handleBookingChange={handleBookingChange}
      handleSearchBooking={handleSearchBooking}
      location={location}
      size={size}
      handleContainerIdChange={handleContainerIdChange}
      handleLocationChange={handleLocationChange}
      handleSizeChange={handleSizeChange}
      handleTareWeightChange={handleTareWeightChange}
      handleSealNoChange={handleSealNoChange}
      handleContainerTypeChange={handleContainerTypeChange}
      handleLicenseChange={handleLicenseChange}
      insideFormFields={insideFormFields}
      handleInsideFieldChange={handleInsideFieldChange}
      outsideFormFields={outsideFormFields}
      handleOutsideFieldChange={handleOutsideFieldChange}
      handleLayPaper={handleLayPaper}
      handleRemarkLayPaper={handleRemarkLayPaper}
      handleStatus={handleStatus}
      handleChasis={handleChasis}
      handleRemark={handleRemark}
      showListForm={showListForm}
      handleShowListForm={handleShowListForm}
      images={images}
      handleImageChange={handleImageChange}
      handleClick={handleClick}
      handleDeleteImage={handleDeleteImage}
      submitForm={submitForm}
      apiError={apiError}
      openDialog={openDialog}
      handleCloseDialog={handleCloseDialog}
      listBooking={listBooking}
      selectBooking={selectBooking}
      fileInputRef={fileInputRef}
      handleChangeSurveyCyPlace={handleChangeSurveyCyPlace}
      surveyCyPlace={surveyCyPlace}
    />
  );
};

export default RequestFormController;
