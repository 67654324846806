import React from "react";
import { Box, Typography, Grid, Button } from "@mui/material";
import SurveyFormTable from "./components/SurveyFormTable";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import dayjs from "dayjs";

const FormDetailView = (props) => {
  const {
    booking,
    tableColumns,
    changeSurveyFormDetail,
    clickViewSurveyFormDetail,
    previousPage,
    handleChangePage,
    page,
    clickGateInAndGateOut,
    onClickSwap,
    clickViewDetailOnSurveyWaiting,
  } = props;

  const gateOutList = booking.SurveyForms.filter(
    (item) => item.gateStatus === "out"
  );

  return (
    <Box>
      <Box
        sx={{
          flexGrow: 1,
          p: 3,
          backgroundColor: "white",
          overflow: "auto",
          borderRadius: 2,
        }}
      >
        <Box display={"flex"} sx={{ alignItems: "center" }}>
          <Typography
            fontWeight={"bold"}
            fontSize={24}
            borderBottom="2px solid #EFC531"
            display="inline"
          >
            หน้าการจัดการ
          </Typography>
          <Box
            sx={{
              backgroundColor: "#d7942d",
              marginLeft: 4,
              padding: 2,
              borderRadius: 16,
              color: "white",
            }}
          >
            <Typography fontWeight={"bold"} fontSize={24}>
              {booking.booking ? booking.booking : "-"}
            </Typography>
          </Box>
        </Box>
        <Grid
          container
          spacing={2}
          sx={{
            mt: "1rem",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grid item xs={12} lg={4} display={"flex"}>
            <Typography>Import date </Typography>
            <Typography ml={2} color={"#1364D7"}>
              {" "}
              {booking.importDate
                ? dayjs(booking.importDate).format("DD/MM/YYYY")
                : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={4} display={"flex"}>
            <Typography>Cy Date</Typography>
            <Typography ml={2} color={"#1364D7"}>
              {" "}
              {booking.cyDate
                ? dayjs(booking.cyDate).format("DD/MM/YYYY")
                : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={4} display={"flex"}>
            <Typography>Loading Date</Typography>
            <Typography ml={2} color={"#1364D7"}>
              {" "}
              {booking.loadingDate
                ? dayjs(booking.loadingDate).format("DD/MM/YYYY")
                : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={4} display={"flex"}>
            <Typography>Closing Data</Typography>
            <Typography ml={2} color={"#1364D7"}>
              {" "}
              {booking.closingDate
                ? dayjs(booking.closingDate).format("DD/MM/YYYY")
                : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={4} display={"flex"}>
            <Typography>Closing Time</Typography>
            <Typography ml={2} color={"#1364D7"}>
              {" "}
              {booking.closingTime ? booking.closingTime : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={4} display={"flex"}>
            <Typography>CI</Typography>
            <Typography ml={2} color={"#1364D7"}>
              {" "}
              {booking.ci ? booking.ci : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={4} display={"flex"}>
            <Typography>Unit</Typography>
            <Typography ml={2} color={"#1364D7"}>
              {" "}
              {booking.unit ? booking.unit : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={4} display={"flex"}>
            <Typography>Size</Typography>
            <Typography ml={2} color={"#1364D7"}>
              {" "}
              {booking.size ? booking.size : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={4} display={"flex"}>
            <Typography>Customer</Typography>
            <Typography ml={2} color={"#1364D7"}>
              {" "}
              {booking.customer ? booking.customer : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={4} display={"flex"}>
            <Typography>Country</Typography>
            <Typography ml={2} color={"#1364D7"}>
              {" "}
              {booking.country ? booking.country : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={4} display={"flex"}>
            <Typography>Shipping Line</Typography>
            <Typography ml={2} color={"#1364D7"}>
              {" "}
              {booking.shippingLir ? booking.shippingLir : "-"}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            lg={4}
            display={"flex"}
            sx={{ alignItems: "center" }}
          >
            <Typography>Booking</Typography>

            <Typography ml={2} color={"#1364D7"}>
              {" "}
              {booking.booking ? booking.booking : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={4} display={"flex"}>
            <Typography>Cy Place</Typography>
            <Typography ml={2} color={"#1364D7"}>
              {" "}
              {booking.cyPlace ? booking.cyPlace : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={4} display={"flex"}>
            <Typography>Cy PIC</Typography>
            <Typography ml={2} color={"#1364D7"}>
              {" "}
              {booking.cyPic ? booking.cyPic : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={4} display={"flex"}>
            <Typography>Cy Contact</Typography>
            <Typography ml={2} color={"#1364D7"}>
              {" "}
              {booking.cyContact ? booking.cyContact : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={4} display={"flex"}>
            <Typography>Remark</Typography>
            <Typography ml={2} color={"#1364D7"}>
              {" "}
              {booking.remark ? booking.remark : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={4} display={"flex"}>
            <Typography>Planner</Typography>
            <Typography ml={2} color={"#1364D7"}>
              {" "}
              {booking.planner ? booking.planner : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={4} display={"flex"}>
            <Typography>Status</Typography>
            <Typography ml={2} color={"#1364D7"}>
              {" "}
              {booking.status ? booking.status : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={4} display={"flex"}>
            <Typography>Other</Typography>
            <Typography ml={2} color={"#1364D7"}>
              {" "}
              {booking.other ? booking.other : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={4} display={"flex"}>
            <Typography>SENT</Typography>
            <Typography ml={2} color={"#1364D7"}>
              {" "}
              {booking.sent ? booking.sent : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={4} display={"flex"} alignItems={"center"}>
            <Typography>Booking status</Typography>
            {booking.bookingStatus === "pending" ? (
              <Typography
                ml={2}
                padding={"8px 16px"}
                display={"inline-block"}
                borderRadius={"20px"}
                backgroundColor={"#D9E9FF"}
                color={"#036CFB"}
                border={"1px solid #036CFB"}
              >
                {booking.bookingStatus}
              </Typography>
            ) : booking.bookingStatus === "confirm" ? (
              <Typography
                ml={2}
                padding={"8px 16px"}
                display={"inline-block"}
                borderRadius={"20px"}
                backgroundColor={"black"}
                color={"white"}
                border={"1px solid white"}
              >
                {booking.bookingStatus}
              </Typography>
            ) : booking.bookingStatus === "cancel" ? (
              <Typography
                ml={2}
                padding={"8px 16px"}
                display={"inline-block"}
                borderRadius={"20px"}
                backgroundColor={"#FADFDF"}
                color={"#E35656"}
                border={"1px solid #E35656"}
              >
                {booking.bookingStatus}
              </Typography>
            ) : booking.bookingStatus === "complete" ? (
              <Typography
                ml={2}
                padding={"8px 16px"}
                display={"inline-block"}
                borderRadius={"20px"}
                backgroundColor={"#DCEDE5"}
                color={"#349366"}
                border={"1px solid #349366"}
              >
                {booking.bookingStatus}
              </Typography>
            ) : (
              "-"
            )}
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          p: 3,
          backgroundColor: "white",
          overflow: "auto",
          borderRadius: 2,
        }}
        mt={2}
      >
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <Typography
              fontWeight={"bold"}
              fontSize={24}
              borderBottom="2px solid #EFC531"
              display="inline"
            >
              รายการทั้งหมด
            </Typography>
            <Typography
              ml={4}
              backgroundColor={"#FAEBD7"}
              padding={1}
              borderRadius={8}
            >
              Qty {booking.unit}
            </Typography>
            <Typography
              ml={4}
              backgroundColor={"#E4E4E4"}
              padding={1}
              borderRadius={8}
              minWidth={50}
              textAlign={"center"}
            >
              In {booking?.SurveyForms?.length}
            </Typography>
            <Typography
              ml={4}
              backgroundColor={"#FFE2E3"}
              padding={1}
              borderRadius={8}
            >
              Out {gateOutList?.length}
            </Typography>
            <Typography
              ml={4}
              backgroundColor={"#F7B627"}
              padding={1}
              borderRadius={8}
            >
              Sum of Waiting Out of TM{" "}
              {booking?.SurveyForms?.length - gateOutList?.length}
            </Typography>
          </Box>
          {/* <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"flex-end"}
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#EFC531",
                      "&.Mui-checked": { color: "#EFC531" },
                      "& .MuiSvgIcon-root": { fontSize: 32 },
                    }}
                  />
                }
                label="Gate out"
              />
            </FormGroup>
          </Box> */}
        </Box>
        <SurveyFormTable
          listSurveyForm={booking.SurveyForms}
          changeSurveyFormDetail={changeSurveyFormDetail}
          clickViewSurveyFormDetail={clickViewSurveyFormDetail}
          tableColumns={tableColumns}
          handleChangePage={handleChangePage}
          page={page}
          clickGateInAndGateOut={clickGateInAndGateOut}
          onClickSwap={onClickSwap}
          clickViewDetailOnSurveyWaiting={clickViewDetailOnSurveyWaiting}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: "1rem",
          }}
        >
          <Box backgroundColor={"#CBD1D7"} height={"2rem"} width={"4px"} />
          <Typography marginLeft={"0.5rem"}>Gate in</Typography>
          <Box
            marginLeft={"1rem"}
            backgroundColor={"#FF0200"}
            height={"2rem"}
            width={"4px"}
          />
          <Typography marginLeft={"0.5rem"}>Gate out</Typography>
          <Box
            marginLeft={"1rem"}
            backgroundColor={"#FFE087"}
            height={"2rem"}
            width={"4px"}
          />
          <Typography marginLeft={"0.5rem"}>Waiting For Gateout</Typography>
        </Box>
      </Box>
      <Button
        variant="contained"
        component="label"
        startIcon={<ChevronLeftIcon />}
        sx={{
          marginTop: "1rem",
          backgroundColor: "#262527",
          "&:hover": {
            backgroundColor: "#262527",
          },
          color: "white",
          borderRadius: "10px",
          fontSize: "1rem",
        }}
        onClick={previousPage}
      >
        ย้อนกลับ
      </Button>
    </Box>
  );
};

export default FormDetailView;
