import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ApproveFormView from "./ApproveFormView";
import ApiHelper from "../../api/api_helper";

const ApproveFormController = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [ci, setCi] = useState(null);

  const [listSurveyForm, setListSurveyForm] = useState([]);
  const [apiError, setApiError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);

  // Init state
  useEffect(() => {
    getListSurveyForm();
  }, []);

  const navigate = useNavigate();

  // Define Table Columns
  const tableColumns = [
    { id: "1", label: "ลำดับ", minWidth: 50, key: "order" },
    { id: "2", label: "วันที่ Request", minWidth: 100, key: "createdDate" },
    { id: "3", label: "CI", minWidth: 100, key: "booking" },
    { id: "4", label: "Container ID", minWidth: 100, key: "containerId" },
    { id: "5", label: "Request By", minWidth: 100, key: "requestBy" },
    { id: "6", label: "ดูรายละเอียด", minWidth: 100, key: "Action" },
  ];

  // Fetch Survey Form Data
  const getListSurveyForm = async () => {
    try {
      const response = await ApiHelper.get(`/survey?isConfirm=waiting`);
      setIsLoading(true);
      if (response.status !== 200) {
        throw response;
      }
      setListSurveyForm(response.data.data);
    } catch (error) {
      setApiError(error.data);
    } finally {
      setIsLoading(false);
    }
  };

  // Change Page
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleStartDateChange = (value) => {
    setStartDate(value);
  };

  const handleEndDateChange = (value) => {
    setEndDate(value);
  };

  const handleCiChange = (event) => {
    setCi(event.target.value);
  };

  const clickViewDetail = (id) => {
    navigate(`/approve-form/${id}`);
  };

  return (
    <ApproveFormView
      handleStartDateChange={handleStartDateChange}
      handleEndDateChange={handleEndDateChange}
      handleCiChange={handleCiChange}
      listSurveyForm={listSurveyForm}
      tableColumns={tableColumns}
      page={page}
      handleChangePage={handleChangePage}
      clickViewDetail={clickViewDetail}
    />
  );
};

export default ApproveFormController;
