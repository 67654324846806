import axios from "axios";
import { useNavigate } from "react-router-dom";

const BASE_URL = process.env.REACT_APP_API_URL; // Replace with your API base URL

const apiClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
  validateStatus: (status) => status >= 200 && status <= 500,
});

// Request Interceptor
apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("auth_token");
    if (token) {
      config.headers.Authorization = `${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response Interceptor
apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
      console.error("Unauthorized!");
      // Handle further if needed (e.g., redirect to login page)
      const navigate = useNavigate();
      localStorage.removeItem("auth_token");
      navigate("/login");
    }
    return Promise.reject(error);
  }
);

// API Helper
const ApiHelper = {
  get: async (url, params) => {
    try {
      const response = await apiClient.get(url, {
        params,
      });
      if (response.status === 401 || response.status === 403) {
        window.dispatchEvent(new CustomEvent("unauthorized", { detail: {} }));
      }
      return response;
    } catch (error) {
      throw error.response;
    }
  },

  post: async (url, data) => {
    try {
      const response = await apiClient.post(url, data);
      if (response.status === 401 || response.status === 403) {
        window.dispatchEvent(new CustomEvent("unauthorized", { detail: {} }));
      }
      return response;
    } catch (error) {
      throw error;
    }
  },

  put: async (url, data) => {
    try {
      const response = await apiClient.put(url, data);
      if (response.status === 401 || response.status === 403) {
        window.dispatchEvent(new CustomEvent("unauthorized", { detail: {} }));
      }
      return response;
    } catch (error) {
      throw error;
    }
  },

  delete: async (url) => {
    try {
      const response = await apiClient.delete(url);
      if (response.status === 401 || response.status === 403) {
        window.dispatchEvent(new CustomEvent("unauthorized", { detail: {} }));
      }
      return response;
    } catch (error) {
      throw error;
    }
  },

  uploadImage: async (url, images) => {
    try {
      const formData = new FormData();
      images.forEach((image) => {
        if (
          image.file &&
          (image.file instanceof Blob || image.file instanceof File)
        ) {
          formData.append("images", image.file);
        } else {
          console.error("Invalid file object:", image);
        }
      });
      const response = await apiClient.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 401 || response.status === 403) {
        window.dispatchEvent(new CustomEvent("unauthorized", { detail: {} }));
      }

      return response;
    } catch (error) {
      throw error;
    }
  },

  postExcelFile: async (url, file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await apiClient.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 401 || response.status === 403) {
        window.dispatchEvent(new CustomEvent("unauthorized", { detail: {} }));
      }

      return response;
    } catch (error) {
      throw error;
    }
  },
};

export default ApiHelper;
