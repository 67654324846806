import React, { useEffect, useState } from "react";
import DailyExportView from "./DailyExportView";
import dayjs from "dayjs";
import { useDialog } from "../../context/dialog_context";
import LoadingIndicator from "../loading/LoadingIndicator";
import ApiHelper from "../../api/api_helper";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";

const DailyExportController = () => {
  const [selectDate, setSelectDate] = useState();
  const [gateStatus, setGateStatus] = useState("in");
  const { showDialog } = useDialog();
  const [isLoading, setIsLoading] = useState(false);
  const [surveyList, setSurveyList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setSurveyList([]);
  }, [gateStatus, selectDate]);

  const handleGateStatusChange = (event) => {
    setGateStatus(event.target.value);
  };

  const handleSelectDateChange = (newValue) => {
    setSelectDate(
      newValue ? dayjs(newValue).format("YYYY-MM-DD HH:mm:ss") : null
    );
  };

  const tableColumns = [
    { id: "1", label: "ลำดับ", minWidth: 50, key: "order" },
    { id: "2", label: "license", minWidth: 100, key: "license" },
    { id: "3", label: "Container ID", minWidth: 100, key: "containerId" },
    { id: "4", label: "Request By", minWidth: 100, key: "requestBy" },
    { id: "5", label: "booking", minWidth: 100, key: "bookingId" },
  ];

  const handleSearchSurvey = async () => {
    try {
      setIsLoading(true);
      if (!selectDate) {
        showDialog("เกิดข้อผิดพลาด", "กรุณาเลือกวันที่จะ Export");
        return;
      }

      const response = await ApiHelper.get(
        `/survey/daily-export?selectDate=${selectDate}&gateStatus=${gateStatus}`
      );

      if (response.status !== 200) {
        throw new Error("Failed to fetch data");
      }

      if (response.data.data.length <= 0) {
        showDialog("ไม่พบข้อมูล", "ไม่มีข้อมูลในวันที่เลือก");
        return;
      }

      setSurveyList(response.data.data);
    } catch (error) {
      showDialog("เกิดข้อผิดพลาด", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const clickViewSurveyFormDetail = (id) => {
    navigate(`/survey/${id}`);
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  const clickExportExcel = () => {
    try {
      const workbook = XLSX.utils.book_new();
      const worksheet = {};
      worksheet["A1"] = { v: "Mahaporn Nakorn Group Co.,Ltd.", t: "s" };
      worksheet["A2"] = {
        v:
          `Daily ${gateStatus === "in" ? "Gate In" : "Gate Out"} Date: ` +
          dayjs(selectDate).format("DD/MM/YYYY"),
        t: "s",
      };
      worksheet["!merges"] = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 19 } }, // Merge A1:T1
        { s: { r: 1, c: 0 }, e: { r: 1, c: 19 } }, // Merge A2:T2
      ];

      const headers = [
        "No.",
        "Location",
        "DATE/TIME",
        "DEPOT",
        "ALLIED FLEET",
        "LICENSE",
        "CONTAINER NO.",
        "SIZE/TYPE",
        "BOOKING NO.",
        "SHIPPING LINE",
        "CY DATE",
        "LOADING DATE",
        "TARE WEIGHT",
        "ADVANCED",
        "CI",
        "SEAL NO.",
        "STATUS",
        "INVOICE No.",
        "ใบเสร็จ",
      ];

      headers.forEach((header, index) => {
        const cell = { v: header, t: "s" };
        worksheet[XLSX.utils.encode_cell({ r: 2, c: index })] = cell; // Set header only once if not merging
      });

      const columnWidths = [
        { wch: 5 }, // No.
        { wch: 5 }, // Location
        { wch: 25 }, // DATE/TIME
        { wch: 25 }, // DEPOT
        { wch: 5 }, // ALLIED FLEET
        { wch: 10 },
        { wch: 20 },
        { wch: 10 },
        { wch: 15 },
        { wch: 30 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 5 },
        { wch: 10 },
        { wch: 10 },
      ];
      worksheet["!cols"] = columnWidths;

      worksheet["!ref"] = XLSX.utils.encode_range({
        s: { c: 0, r: 0 },
        e: { c: headers.length - 1, r: 2 },
      });

      const formattedData = surveyList.map((item, index) => [
        index + 1, // No.
        "MHP",
        gateStatus == "in"
          ? dayjs(item.approveDate).format("DD/MM/YYYY HH:mm:ss")
          : dayjs(item.gateOutDate).format("DD/MM/YYYY HH:mm:ss"),
        item.surveyCyPlace,
        item.trailer,
        gateStatus == "in" ? item.license : item.truckLicense,
        item.containerId,
        item.size,
        item.booking,
        item.Booking.shippingLir,
        dayjs(item.Booking.cyDate).format("DD/MM/YYYY"),
        dayjs(item.Booking.loadingDate).format("DD/MM/YYYY"),
        item.tareWeight,
        "",
        item.Booking.ci,
        item.sealNo,
        item.OutsideForm.status,
        "",
        "",
      ]);
      XLSX.utils.sheet_add_aoa(worksheet, formattedData, { origin: -1 });

      // Append worksheet and save
      XLSX.utils.book_append_sheet(workbook, worksheet, "Report");

      XLSX.writeFile(
        workbook,
        `Gate${gateStatus}${dayjs(selectDate).format("YYYYMMDD")}.xlsx`
      );
    } catch (error) {
      showDialog("เกิดข้อผิดพลาด", error.message);
    }
  };

  return (
    <DailyExportView
      gateStatus={gateStatus}
      handleGateStatusChange={handleGateStatusChange}
      handleSelectDateChange={handleSelectDateChange}
      handleSearchSurvey={handleSearchSurvey}
      selectDate={selectDate}
      surveyList={surveyList}
      tableColumns={tableColumns}
      clickViewSurveyFormDetail={clickViewSurveyFormDetail}
      onClickExportExcel={clickExportExcel}
    />
  );
};

export default DailyExportController;
