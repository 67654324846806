import React from "react";
import dayjs from "dayjs";
import {
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

const ChangeFormDetailView = (props) => {
  const {
    booking,
    handleSaveButton,
    previousPage,
    handleCyDateChange,
    handleLoadingDateChange,
    handleClosingDateChange,
    handleLosingTimeChange,
    handleCiChange,
    handleUnitChange,
    handleSizeChange,
    handleCustomerChange,
    handleCountryChange,
    handleShippingLirChange,
    handleBookingChange,
    handleCyPlaceChange,
    handleCyContactChange,
    handleRemarkChange,
    handlePlanerChange,
    handleStatusChange,
    handleOtherChange,
    handleSentChange,
    handleChangeStatus,
  } = props;

  return (
    <Box
      sx={{
        flexGrow: 1,
        p: 3,
        backgroundColor: "white",
        overflow: "auto",
        borderRadius: 2,
      }}
    >
      <Typography
        fontWeight={"bold"}
        fontSize={24}
        borderBottom="2px solid #EFC531"
        display="inline"
      >
        แก้ไขข้อมูล
      </Typography>

      <Grid
        container
        spacing={2}
        sx={{
          marginTop: "0.5rem",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Grid item xs={12} md={6} display={"flex"}>
          <TextField
            margin="normal"
            variant="outlined"
            label="Import Date"
            value={
              booking.importDate
                ? dayjs(booking.importDate).format("DD/MM/YYYY")
                : ""
            }
            disabled
            sx={{
              width: "100%",
            }}
          ></TextField>
        </Grid>
        <Grid item xs={12} md={6} display={"flex"} width={"100%"}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid container>
              <Grid item xs={12}>
                <DemoContainer components={["DesktopDatePicker"]}>
                  <DesktopDatePicker
                    label="Cy Date"
                    value={dayjs(booking.cyDate)}
                    format="DD/MM/YYYY"
                    sx={{
                      width: "100%",
                    }}
                    onChange={handleCyDateChange}
                  />
                </DemoContainer>
              </Grid>
            </Grid>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={6} display={"flex"} width={"100%"}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid container>
              <Grid item xs={12}>
                <DemoContainer components={["DesktopDatePicker"]}>
                  <DesktopDatePicker
                    label="Loading Date"
                    format="DD/MM/YYYY"
                    sx={{
                      width: "100%",
                    }}
                    value={dayjs(booking.loadingDate)}
                    onChange={handleLoadingDateChange}
                  />
                </DemoContainer>
              </Grid>
            </Grid>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={6} display={"flex"} width={"100%"}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid container>
              <Grid item xs={12}>
                <DemoContainer components={["DesktopDatePicker"]}>
                  <DesktopDatePicker
                    label="Closing Date"
                    format="DD/MM/YYYY"
                    sx={{
                      width: "100%",
                    }}
                    value={dayjs(booking.closingDate)}
                    onChange={handleClosingDateChange}
                  />
                </DemoContainer>
              </Grid>
            </Grid>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={6} display={"flex"} width={"100%"}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  label="Closing Time"
                  value={booking.closingTime}
                  onChange={handleLosingTimeChange}
                  placeholder="__:__:__"
                  fullWidth
                />
              </Grid>
            </Grid>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={6} display={"flex"}>
          <TextField
            margin="normal"
            variant="outlined"
            label="CI"
            value={booking.ci ? booking.ci : ""}
            sx={{
              width: "100%",
            }}
            onChange={handleCiChange}
          ></TextField>
        </Grid>
        <Grid item xs={12} md={6} display={"flex"}>
          <TextField
            margin="normal"
            variant="outlined"
            label="Unit"
            value={booking.unit ? booking.unit : ""}
            sx={{
              width: "100%",
            }}
            onChange={handleUnitChange}
          ></TextField>
        </Grid>
        <Grid item xs={12} md={6} display={"flex"}>
          <TextField
            margin="normal"
            variant="outlined"
            label="Size"
            value={booking.size ? booking.size : ""}
            sx={{
              width: "100%",
            }}
            onChange={handleSizeChange}
          ></TextField>
        </Grid>
        <Grid item xs={12} md={6} display={"flex"}>
          <TextField
            margin="normal"
            variant="outlined"
            label="Customer"
            value={booking.customer ? booking.customer : ""}
            sx={{
              width: "100%",
            }}
            onChange={handleCustomerChange}
          ></TextField>
        </Grid>
        <Grid item xs={12} md={6} display={"flex"}>
          <TextField
            margin="normal"
            variant="outlined"
            label="Country"
            value={booking.country ? booking.country : ""}
            sx={{
              width: "100%",
            }}
            onChange={handleCountryChange}
          ></TextField>
        </Grid>
        <Grid item xs={12} md={6} display={"flex"}>
          <TextField
            margin="normal"
            variant="outlined"
            label="Shipping Lir"
            value={booking.shippingLir ? booking.shippingLir : ""}
            sx={{
              width: "100%",
            }}
            onChange={handleShippingLirChange}
          ></TextField>
        </Grid>
        <Grid item xs={12} md={6} display={"flex"}>
          <TextField
            margin="normal"
            variant="outlined"
            label="Booking"
            disabled
            value={booking.booking ? booking.booking : ""}
            sx={{
              width: "100%",
            }}
            onChange={handleBookingChange}
          ></TextField>
        </Grid>
        <Grid item xs={12} md={6} display={"flex"}>
          <TextField
            margin="normal"
            variant="outlined"
            label="Cy Place"
            value={booking.cyPlace ? booking.cyPlace : ""}
            sx={{
              width: "100%",
            }}
            onChange={handleCyPlaceChange}
          ></TextField>
        </Grid>
        <Grid item xs={12} md={6} display={"flex"}>
          <TextField
            margin="normal"
            variant="outlined"
            label="Cy Contact"
            value={booking.cyContact ? booking.cyContact : ""}
            sx={{
              width: "100%",
            }}
            onChange={handleCyContactChange}
          ></TextField>
        </Grid>
        <Grid item xs={12} md={6} display={"flex"}>
          <TextField
            margin="normal"
            variant="outlined"
            label="Remark"
            value={booking.remark ? booking.remark : ""}
            sx={{
              width: "100%",
            }}
            onChange={handleRemarkChange}
          ></TextField>
        </Grid>
        <Grid item xs={12} md={6} display={"flex"}>
          <TextField
            margin="normal"
            variant="outlined"
            label="Planer"
            value={booking.planner ? booking.planner : ""}
            sx={{
              width: "100%",
            }}
            onChange={handlePlanerChange}
          ></TextField>
        </Grid>
        <Grid item xs={12} md={6} display={"flex"}>
          <TextField
            margin="normal"
            variant="outlined"
            label="Status"
            value={booking.status ? booking.status : ""}
            sx={{
              width: "100%",
            }}
            onChange={handleStatusChange}
          ></TextField>
        </Grid>
        <Grid item xs={12} md={6} display={"flex"}>
          <TextField
            margin="normal"
            variant="outlined"
            label="Other"
            value={booking.other ? booking.other : ""}
            sx={{
              width: "100%",
            }}
            onChange={handleOtherChange}
          ></TextField>
        </Grid>
        <Grid item xs={12} md={6} display={"flex"}>
          <TextField
            margin="normal"
            variant="outlined"
            label="SENT"
            value={booking.sent ? booking.sent : ""}
            sx={{
              width: "100%",
            }}
            onChange={handleSentChange}
          ></TextField>
        </Grid>
        <Grid item xs={12} md={6} display={"flex"}>
          <FormControl fullWidth>
            <InputLabel id="Booking Status">Booking Status</InputLabel>
            <Select
              labelId="Booking Status"
              id="Booking Status"
              label="Booking Status"
              value={booking.bookingStatus ? booking.bookingStatus : ""}
              onChange={handleChangeStatus}
            >
              <MenuItem value="pending">Pending</MenuItem>
              <MenuItem value="confirm">Confirm</MenuItem>
              <MenuItem value="cancel">Cancel</MenuItem>
              <MenuItem value="complete">Complete</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          justifyContent: "center",
        }}
      >
        <Button
          variant="contained"
          component="label"
          sx={{
            marginTop: "1rem",
            width: "10%",
            backgroundColor: "#056DF9",
            "&:hover": {
              backgroundColor: "#056DF9",
            },
            color: "white",
            borderRadius: "10px",
            fontSize: "1rem",
          }}
          onClick={handleSaveButton}
        >
          บันทึก
        </Button>
        <Button
          component="label"
          sx={{
            marginTop: "1rem",
            width: "10%",
            color: "black",
            borderRadius: "10px",
            fontSize: "1rem",
            border: "1px solid black",
            "&:hover": {
              backgroundColor: "grey",
              color: "white",
            },
          }}
          onClick={previousPage}
        >
          ยกเลิก
        </Button>
      </Box>
    </Box>
  );
};

export default ChangeFormDetailView;
