import React from 'react';
import { Button, Typography } from '@mui/material';

const CustomButton = ({ icon, text, isActive, onClick }) => {
  return (
    <Button
      variant="contained"
      startIcon={icon}
      sx={{
        width: '30%',
        boxShadow: 'none',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        borderRadius: '10px 10px 0 0',
        background: isActive ? 'linear-gradient(180deg, #FEE49E, #ECBA61)' : 'white',
        color: isActive ? 'black' : 'grey',
        '&:hover': {
          background: isActive ? 'linear-gradient(180deg, #FEE49E, #ECBA61)' : 'white',
          boxShadow: 'none',
        },
      }}
      onClick={onClick}
    >
      <Typography variant="button">{text}</Typography>
    </Button>
  );
};

export default CustomButton;
