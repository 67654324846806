import React, { createContext, useState, useContext } from "react";

const DialogContext = createContext();

export const useDialog = () => {
  return useContext(DialogContext);
};

export const DialogProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [details, setDetails] = useState("");

  const showDialog = (dialogTitle, dialogDetails) => {
    setTitle(dialogTitle);
    setDetails(dialogDetails);
    setOpen(true);
  };

  const hideDialog = () => {
    setOpen(false);
    setTitle("");
    setDetails("");
  };

  return (
    <DialogContext.Provider
      value={{ open, title, details, showDialog, hideDialog }}
    >
      {children}
    </DialogContext.Provider>
  );
};
