import React from "react";
import {
  Box,
  Typography,
  Divider,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  Radio,
} from "@mui/material";

const InsideForm = (props) => {
  const { insideFormFields, handleInsideFieldChange } = props;

  return (
    <Box
      sx={{
        width: "49%",
        backgroundColor: "white",
        margin: "0 1%",
        padding: "1rem",
        borderRadius: "8px",
        border: "1px solid #B9C0E0",
        "@media (max-width: 960px)": {
          width: "98%",
        },
      }}
    >
      <Typography
        fontWeight={"bold"}
        fontSize={16}
        margin={"1rem"}
        align="center"
      >
        INSIDE
      </Typography>
      <Divider sx={{ borderColor: "#B9C0E0" }} />
      {insideFormFields.map(({ id, key, label }) => (
        <RadioGroup
          key={id}
          sx={{
            marginTop: "1rem",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            textAlign: "center",
            alignItems: "center",
            "@media (max-width: 1300px)": {
              flexDirection: "column",
              alignItems: "normal",
              textAlign: "unset",
            },
          }}
          onChange={handleInsideFieldChange(key)}
          required
          defaultValue={"OK"}
        >
          <FormLabel>{label}</FormLabel>
          <Box>
            <FormControlLabel
              value="OK"
              control={<Radio />}
              label="OK"
              sx={{
                marginLeft: "2rem",
                "@media (max-width: 960px)": {
                  marginLeft: "1rem",
                },
              }}
            />
            <FormControlLabel
              value="FIX"
              control={<Radio />}
              label="FIX"
              sx={{
                marginLeft: "2rem",
                "@media (max-width: 960px)": {
                  marginLeft: "1rem",
                },
              }}
            />
          </Box>
        </RadioGroup>
      ))}
    </Box>
  );
};

export default InsideForm;
