import React from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormLabel,
  IconButton,
  Grid,
  Checkbox,
  Paper,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import EditUploadImage from "./components/EditUploadImage";

const ChangeSurveyFormDetailView = (props) => {
  const {
    surveyForm,
    updateSurveyFormByKey,
    previousPage,
    submitForm,
    images,
    setImages,
    setPendingDeletions,
  } = props;

  return (
    <Box>
      <Box
        sx={{
          flexGrow: 1,
          p: 3,
          backgroundColor: "white",
          overflow: "auto",
          borderRadius: 2,
        }}
      >
        <Typography
          fontWeight={"bold"}
          fontSize={24}
          borderBottom="2px solid #EFC531"
          display="inline"
        >
          แก้ไขแบบฟอร์ม
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            mt: "2rem",
            width: "100%",
          }}
        >
          <TextField
            required
            id="booking"
            label="Booking No."
            name="booking"
            value={surveyForm.Booking.booking}
            sx={{
              width: "40%",
              borderRadius: "1",
              "@media (max-width: 960px)": {
                width: "100%",
              },
            }}
            disabled
          />
        </Box>
        <Divider sx={{ margin: "2rem 0" }} />
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            <TextField
              required
              id="containerId"
              label="Container No."
              name="containerId"
              value={surveyForm.containerId}
              onChange={(event) =>
                updateSurveyFormByKey("containerId", event.target.value)
              }
              sx={{
                width: "40%",
                borderRadius: "1",
                "@media (max-width: 1200px)": {
                  width: "100%",
                  marginBottom: "1rem",
                },
              }}
            />
            <FormControl
              sx={{
                marginLeft: "1%",
                width: "9%",
                "@media (max-width: 1200px)": {
                  width: "49%",
                  marginBottom: "1rem",
                  marginLeft: "0",
                },
                "@media (max-width: 360px)": {
                  width: "100%",
                  marginBottom: "1rem",
                },
              }}
            >
              <InputLabel id="location">Location</InputLabel>
              <Select
                labelId="location"
                id="location"
                label="Location"
                value={surveyForm.location}
                onChange={(event) =>
                  updateSurveyFormByKey("location", event.target.value)
                }
              >
                <MenuItem value="MHP">MHP</MenuItem>
              </Select>
            </FormControl>
            <TextField
              required
              id="size"
              label="size"
              name="size"
              value={surveyForm.size}
              onChange={(event) =>
                updateSurveyFormByKey("size", event.target.value)
              }
              sx={{
                marginLeft: "1%",
                width: "9%",
                "@media (max-width: 1200px)": {
                  width: "49%",
                  marginBottom: "1rem",
                  marginLeft: "2%",
                },
                "@media (max-width: 360px)": {
                  width: "100%",
                  marginBottom: "1rem",
                },
              }}
            />

            <TextField
              required
              id="tareWeight"
              label="Tare Weight"
              name="tareWeight"
              value={surveyForm.tareWeight}
              onChange={(event) =>
                updateSurveyFormByKey("tareWeight", event.target.value)
              }
              sx={{
                marginLeft: "1%",
                width: "39%",
                borderRadius: "1",
                "@media (max-width: 1200px)": {
                  width: "100%",
                  marginLeft: "0",
                },
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              marginTop: "1rem",
            }}
          >
            <TextField
              required
              id="sealNo"
              label="Seal No."
              name="sealNo"
              value={surveyForm.sealNo}
              onChange={(event) =>
                updateSurveyFormByKey("sealNo", event.target.value)
              }
              sx={{
                width: "40%",
                borderRadius: "1",
                "@media (max-width: 1200px)": {
                  width: "100%",
                },
              }}
            />

            <TextField
              required
              id="Container Cy Place"
              label="Container Cy Place"
              name="Container Cy Place"
              value={surveyForm.surveyCyPlace}
              onChange={(event) =>
                updateSurveyFormByKey("surveyCyPlace", event.target.value)
              }
              sx={{
                width: "40%",
                borderRadius: "1",
                "@media (max-width: 1200px)": {
                  width: "100%",
                  marginTop: "1rem",
                  marginLeft: 0,
                },
                marginLeft: "1%",
              }}
            />
          </Box>
          <Box>
            <RadioGroup
              value={surveyForm.containerType}
              onChange={(event) =>
                updateSurveyFormByKey("containerType", event.target.value)
              }
              sx={{
                marginTop: "1rem",
                display: "flex",
                flexDirection: "row",
                textAlign: "center",
                alignItems: "center",
                "@media (max-width: 960px)": {
                  flexDirection: "column",
                  alignItems: "normal",
                  textAlign: "unset",
                },
              }}
            >
              <FormLabel>Container Type</FormLabel>
              <FormControlLabel
                value="dry container"
                control={<Radio />}
                label="DRY CONTAINER"
                sx={{
                  marginLeft: "2rem",
                  "@media (max-width: 960px)": {
                    marginLeft: "1rem",
                  },
                }}
              />
              <FormControlLabel
                value="seabulk"
                control={<Radio />}
                label="SEABULK"
                sx={{
                  marginLeft: "2rem",
                  "@media (max-width: 960px)": {
                    marginLeft: "1rem",
                  },
                }}
              />
              <FormControlLabel
                value="csi"
                control={<Radio />}
                label="CSI"
                sx={{
                  marginLeft: "2rem",
                  "@media (max-width: 960px)": {
                    marginLeft: "1rem",
                  },
                }}
              />
            </RadioGroup>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              marginTop: "1rem",
            }}
          >
            <TextField
              required
              id="license"
              label="ทะเบียนรถ"
              name="license"
              value={surveyForm.license}
              onChange={(event) =>
                updateSurveyFormByKey("license", event.target.value)
              }
              sx={{
                width: "40%",
                borderRadius: "1",
                "@media (max-width: 1200px)": {
                  width: "50%",
                },
                "@media (max-width: 960px)": {
                  width: "100%",
                },
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          marginTop: "2rem",
          flexGrow: 1,
          p: 3,
          backgroundColor: "white",
          overflow: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#EFF1F3",
              overflow: "auto",
              width: "100%",
              marginLeft: "1rem",
            }}
          >
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "1rem",
                }}
              >
                <FactCheckIcon sx={{ fontSize: "3rem" }} />
                <Typography
                  fontWeight={"bold"}
                  fontSize={16}
                  marginLeft={"1rem"}
                >
                  ตรวจสอบรายการ
                </Typography>
              </Box>
            </Box>
            <Grid
              container
              spacing={2}
              sx={{
                mt: "1rem",
                display: "flex",
              }}
            >
              <Grid item xs={12} lg={6} display={"flex"}>
                <Box
                  sx={{
                    backgroundColor: "white",
                    margin: "0 1%",
                    padding: "1rem",
                    borderRadius: "8px",
                    border: "1px solid #B9C0E0",
                    width: "100%",
                  }}
                >
                  <Typography
                    fontWeight={"bold"}
                    fontSize={16}
                    margin={"1rem"}
                    align="center"
                  >
                    INSIDE
                  </Typography>
                  <Divider sx={{ borderColor: "#B9C0E0" }} />
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    marginTop={"1rem"}
                  >
                    <Typography>DRY (แห้งสะอาด)</Typography>
                    <RadioGroup
                      value={surveyForm.InsideForm.dry}
                      onChange={(event) =>
                        updateSurveyFormByKey("dry", event.target.value)
                      }
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        textAlign: "center",
                        alignItems: "center",
                      }}
                    >
                      <FormControlLabel
                        value={"OK"}
                        control={<Radio />}
                        label="OK"
                      />
                      <FormControlLabel
                        value={"FIX"}
                        control={<Radio />}
                        label="FIX"
                      />
                    </RadioGroup>
                  </Box>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography>GOUGE (เสี้ยน)</Typography>
                    <RadioGroup
                      value={surveyForm.InsideForm.gouge}
                      onChange={(event) =>
                        updateSurveyFormByKey("gouge", event.target.value)
                      }
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        textAlign: "center",
                        alignItems: "center",
                      }}
                    >
                      <FormControlLabel
                        value={"OK"}
                        control={<Radio />}
                        label="OK"
                      />
                      <FormControlLabel
                        value={"FIX"}
                        control={<Radio />}
                        label="FIX"
                      />
                    </RadioGroup>
                  </Box>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography>LIGHT (รอยรั่ว)</Typography>
                    <RadioGroup
                      value={surveyForm.InsideForm.light}
                      onChange={(event) =>
                        updateSurveyFormByKey("light", event.target.value)
                      }
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        textAlign: "center",
                        alignItems: "center",
                      }}
                    >
                      <FormControlLabel
                        value={"OK"}
                        control={<Radio />}
                        label="OK"
                      />
                      <FormControlLabel
                        value={"FIX"}
                        control={<Radio />}
                        label="FIX"
                      />
                    </RadioGroup>
                  </Box>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography>DIRTY (คราบสกปรก)</Typography>
                    <RadioGroup
                      value={surveyForm.InsideForm.dirty}
                      onChange={(event) =>
                        updateSurveyFormByKey("dirty", event.target.value)
                      }
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        textAlign: "center",
                        alignItems: "center",
                      }}
                    >
                      <FormControlLabel
                        value={"OK"}
                        control={<Radio />}
                        label="OK"
                      />
                      <FormControlLabel
                        value={"FIX"}
                        control={<Radio />}
                        label="FIX"
                      />
                    </RadioGroup>
                  </Box>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography>RUSTY (สนิม)</Typography>
                    <RadioGroup
                      value={surveyForm.InsideForm.rusty}
                      onChange={(event) =>
                        updateSurveyFormByKey("rusty", event.target.value)
                      }
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        textAlign: "center",
                        alignItems: "center",
                      }}
                    >
                      <FormControlLabel
                        value={"OK"}
                        control={<Radio />}
                        label="OK"
                      />
                      <FormControlLabel
                        value={"FIX"}
                        control={<Radio />}
                        label="FIX"
                      />
                    </RadioGroup>
                  </Box>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography>ODOR (กลิ่นเหม็น)</Typography>
                    <RadioGroup
                      value={surveyForm.InsideForm.odor}
                      onChange={(event) =>
                        updateSurveyFormByKey("odor", event.target.value)
                      }
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        textAlign: "center",
                        alignItems: "center",
                      }}
                    >
                      <FormControlLabel
                        value={"OK"}
                        control={<Radio />}
                        label="OK"
                      />
                      <FormControlLabel
                        value={"FIX"}
                        control={<Radio />}
                        label="FIX"
                      />
                    </RadioGroup>
                  </Box>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography>NAIL (ตะปู)</Typography>
                    <RadioGroup
                      value={surveyForm.InsideForm.nail}
                      onChange={(event) =>
                        updateSurveyFormByKey("nail", event.target.value)
                      }
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        textAlign: "center",
                        alignItems: "center",
                      }}
                    >
                      <FormControlLabel
                        value={"OK"}
                        control={<Radio />}
                        label="OK"
                      />
                      <FormControlLabel
                        value={"FIX"}
                        control={<Radio />}
                        label="FIX"
                      />
                    </RadioGroup>
                  </Box>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography>INSECT (ซากสัตว์)</Typography>
                    <RadioGroup
                      value={surveyForm.InsideForm.insect}
                      onChange={(event) =>
                        updateSurveyFormByKey("insect", event.target.value)
                      }
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        textAlign: "center",
                        alignItems: "center",
                      }}
                    >
                      <FormControlLabel
                        value={"OK"}
                        control={<Radio />}
                        label="OK"
                      />
                      <FormControlLabel
                        value={"FIX"}
                        control={<Radio />}
                        label="FIX"
                      />
                    </RadioGroup>
                  </Box>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography>OLD GOODS (สินค้าเก่า)</Typography>
                    <RadioGroup
                      value={surveyForm.InsideForm.oldGoods}
                      onChange={(event) =>
                        updateSurveyFormByKey("oldGoods", event.target.value)
                      }
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        textAlign: "center",
                        alignItems: "center",
                      }}
                    >
                      <FormControlLabel
                        value={"OK"}
                        control={<Radio />}
                        label="OK"
                      />
                      <FormControlLabel
                        value={"FIX"}
                        control={<Radio />}
                        label="FIX"
                      />
                    </RadioGroup>
                  </Box>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography>DENT (บุบ ยุบ)</Typography>
                    <RadioGroup
                      value={surveyForm.InsideForm.dentInside}
                      onChange={(event) =>
                        updateSurveyFormByKey("dentInside", event.target.value)
                      }
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        textAlign: "center",
                        alignItems: "center",
                      }}
                    >
                      <FormControlLabel
                        value={"OK"}
                        control={<Radio />}
                        label="OK"
                      />
                      <FormControlLabel
                        value={"FIX"}
                        control={<Radio />}
                        label="FIX"
                      />
                    </RadioGroup>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} lg={6} display={"flex"}>
                <Box
                  sx={{
                    backgroundColor: "white",
                    margin: "0 1%",
                    padding: "1rem",
                    borderRadius: "8px",
                    border: "1px solid #B9C0E0",
                    width: "100%",
                  }}
                >
                  <Typography
                    fontWeight={"bold"}
                    fontSize={16}
                    margin={"1rem"}
                    align="center"
                  >
                    OUTSIDE
                  </Typography>
                  <Divider sx={{ borderColor: "#B9C0E0" }} />
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    marginTop={"1rem"}
                  >
                    <Typography>DENT (บุบ ยุบ)</Typography>
                    <RadioGroup
                      value={surveyForm.OutsideForm.dentOutside}
                      onChange={(event) =>
                        updateSurveyFormByKey("dentOutside", event.target.value)
                      }
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        textAlign: "center",
                        alignItems: "center",
                      }}
                    >
                      <FormControlLabel
                        value={"OK"}
                        control={<Radio />}
                        label="OK"
                      />
                      <FormControlLabel
                        value={"FIX"}
                        control={<Radio />}
                        label="FIX"
                      />
                    </RadioGroup>
                  </Box>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography>HANDLE (มือจับ)</Typography>
                    <RadioGroup
                      value={surveyForm.OutsideForm.handle}
                      onChange={(event) =>
                        updateSurveyFormByKey("handle", event.target.value)
                      }
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        textAlign: "center",
                        alignItems: "center",
                      }}
                    >
                      <FormControlLabel
                        value={"OK"}
                        control={<Radio />}
                        label="OK"
                      />
                      <FormControlLabel
                        value={"FIX"}
                        control={<Radio />}
                        label="FIX"
                      />
                    </RadioGroup>
                  </Box>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography>LOCK (ล็อคซีล)</Typography>
                    <RadioGroup
                      value={surveyForm.OutsideForm.lock}
                      onChange={(event) =>
                        updateSurveyFormByKey("lock", event.target.value)
                      }
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        textAlign: "center",
                        alignItems: "center",
                      }}
                    >
                      <FormControlLabel
                        value={"OK"}
                        control={<Radio />}
                        label="OK"
                      />
                      <FormControlLabel
                        value={"FIX"}
                        control={<Radio />}
                        label="FIX"
                      />
                    </RadioGroup>
                  </Box>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography>DOOR (ประตูตู้)</Typography>
                    <RadioGroup
                      value={surveyForm.OutsideForm.door}
                      onChange={(event) =>
                        updateSurveyFormByKey("door", event.target.value)
                      }
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        textAlign: "center",
                        alignItems: "center",
                      }}
                    >
                      <FormControlLabel
                        value={"OK"}
                        control={<Radio />}
                        label="OK"
                      />
                      <FormControlLabel
                        value={"FIX"}
                        control={<Radio />}
                        label="FIX"
                      />
                    </RadioGroup>
                  </Box>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography>NUMBER (เลขตู้)</Typography>
                    <RadioGroup
                      value={surveyForm.OutsideForm.number}
                      onChange={(event) =>
                        updateSurveyFormByKey("number", event.target.value)
                      }
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        textAlign: "center",
                        alignItems: "center",
                      }}
                    >
                      <FormControlLabel
                        value={"OK"}
                        control={<Radio />}
                        label="OK"
                      />
                      <FormControlLabel
                        value={"FIX"}
                        control={<Radio />}
                        label="FIX"
                      />
                    </RadioGroup>
                  </Box>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography>VENT (ช่องระบายอากาศ)</Typography>
                    <RadioGroup
                      value={surveyForm.OutsideForm.vent}
                      onChange={(event) =>
                        updateSurveyFormByKey("vent", event.target.value)
                      }
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        textAlign: "center",
                        alignItems: "center",
                      }}
                    >
                      <FormControlLabel
                        value={"OK"}
                        control={<Radio />}
                        label="OK"
                      />
                      <FormControlLabel
                        value={"FIX"}
                        control={<Radio />}
                        label="FIX"
                      />
                    </RadioGroup>
                  </Box>
                  <Divider sx={{ borderColor: "#EFF1F3", marginTop: "1rem" }} />
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography>LAY PAPER</Typography>
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Yes"
                      sx={{
                        marginTop: "6px",
                      }}
                      value={surveyForm.OutsideForm.layPaper}
                      onChange={(event) =>
                        updateSurveyFormByKey("layPaper", event.target.checked)
                      }
                    />
                  </Box>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography>Remark LAY PAPER</Typography>
                    <TextField
                      value={surveyForm.OutsideForm.remarkLayPaper}
                      onChange={(event) =>
                        updateSurveyFormByKey(
                          "remarkLayPaper",
                          event.target.value
                        )
                      }
                      fullWidth
                    ></TextField>
                  </Box>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography>Status</Typography>
                    <RadioGroup
                      value={surveyForm.OutsideForm.status}
                      onChange={(event) =>
                        updateSurveyFormByKey("status", event.target.value)
                      }
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        textAlign: "center",
                        alignItems: "center",
                      }}
                    >
                      <FormControlLabel
                        value="AV"
                        control={<Radio />}
                        label="AV"
                      />
                      <FormControlLabel
                        value="REWORK"
                        control={<Radio />}
                        label="REWORK"
                      />
                      <FormControlLabel
                        value="REJECT"
                        control={<Radio />}
                        label="REJECT"
                      />
                    </RadioGroup>
                  </Box>
                  {/* <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    gap={2}
                  >
                    <Typography>Chasis</Typography>
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Yes"
                      value={surveyForm.OutsideForm.chasis}
                      onChange={(event) =>
                        updateSurveyFormByKey("chasis", event.target.checked)
                      }
                    />
                  </Box> */}
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    gap={2}
                  >
                    <Typography>Remark</Typography>
                    <TextField
                      value={surveyForm.OutsideForm.remark}
                      onChange={(event) =>
                        updateSurveyFormByKey("remark", event.target.value)
                      }
                      fullWidth
                    ></TextField>
                  </Box>
                </Box>
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  marginTop: "1rem",
                  marginLeft: "1rem",
                }}
              >
                <EditUploadImage
                  surveyImages={surveyForm.SurveyImages}
                  surveyId={surveyForm.id}
                  images={images}
                  setImages={setImages}
                  setPendingDeletions={setPendingDeletions}
                />
              </Box>
            </Grid>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginRight: "1rem",
            marginBottom: "1rem",
          }}
        >
          <Button
            type="submit"
            variant="contained"
            sx={{
              marginLeft: "1rem",
              marginTop: "1rem",
              borderRadius: 4,
              color: "white",
              width: "15%",
              height: "3rem",
            }}
            onClick={submitForm}
          >
            บันทึก
          </Button>
        </Box>
      </Box>
      <Button
        variant="contained"
        component="label"
        startIcon={<ChevronLeftIcon />}
        sx={{
          marginTop: "1rem",
          backgroundColor: "#262527",
          "&:hover": {
            backgroundColor: "#262527",
          },
          color: "white",
          borderRadius: "10px",
          fontSize: "1rem",
        }}
        onClick={previousPage}
      >
        ย้อนกลับ
      </Button>
    </Box>
  );
};

export default ChangeSurveyFormDetailView;
