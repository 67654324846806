import React from "react";

export const UserContext = React.createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = React.useState(
    JSON.parse(localStorage.getItem("user")) || null
  );
  const [isAuth, setIsAuth] = React.useState(!!localStorage.getItem("token"));
  const [token, setToken] = React.useState(
    localStorage.getItem("token") || null
  );

  // Update localStorage when token changes
  React.useEffect(() => {
    if (token) {
      localStorage.setItem("token", token);
      setIsAuth(true);
    } else {
      localStorage.removeItem("token");
      setIsAuth(false);
    }
  }, [token]);

  // Optionally, update localStorage when user changes
  React.useEffect(() => {
    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
    } else {
      localStorage.removeItem("user");
    }
  }, [user]);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        isAuth,
        setIsAuth,
        token,
        setToken,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useAuth = () => {
  return React.useContext(UserContext);
};
