import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import useMediaQuery from "@mui/material/useMediaQuery";
import Divider from "@mui/material/Divider";
import {
  Home,
  Edit,
  Summarize,
  Description,
  Person,
  DriveFileMove,
  Logout,
  ImportExport,
  Outbound,
} from "@mui/icons-material";

import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/user_context";
const drawerWidth = 240;

const pathList = [
  "/",
  "/form-management",
  "/gate-out",
  "/approve-form",
  "/form",
  "/user",
  "/report",
  "/daily-export",
];

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer({ children }) {
  const theme = useTheme();
  const user = useAuth();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  React.useEffect(() => {
    isSmallScreen ? handleDrawerClose() : handleDrawerOpen();
  }, [isSmallScreen]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ bgcolor: "#262527" }}>
        <Toolbar>
          {isSmallScreen ? (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={open ? handleDrawerClose : handleDrawerOpen}
              edge="start"
              sx={{ mr: 2 }}
            >
              {open ? <ChevronLeftIcon /> : <MenuIcon />}
            </IconButton>
          ) : null}
          <Typography variant="h6" noWrap component="div">
            บริษัทมหาพร ทรานสปอร์ต จำกัด
          </Typography>
        </Toolbar>
      </AppBar>
      {open ? (
        <Drawer
          variant="permanent"
          open={open}
          PaperProps={{
            sx: {
              backgroundColor: "#ECBA61",
              display: "flex",
              flexDirection: "column",
            },
          }}
        >
          <DrawerHeader></DrawerHeader>
          <Divider />
          <List>
            {[
              "หน้าหลัก",
              "หน้าการจัดการ",
              "ระบบ Gate out",
              "ระบบ Gate in",
              "ฟอร์ม",
              "ระบบสมาชิก",
              "ระบบ Export",
              "ระบบ Export รายวัน",
            ].map((text, index) => {
              const role = user.user.role;
              if (
                (index === 1 && role === "survey") ||
                (index === 2 && role !== "admin") ||
                (index === 3 && role === "survey") ||
                (index === 3 && role !== "admin") ||
                (index === 4 && role === "general") ||
                (index === 5 && role !== "admin") ||
                (index === 6 && role === "survey") ||
                (index == 7 && role !== "admin")
              ) {
                return null; // Do not render this item
              }

              return (
                <ListItem key={text} disablePadding sx={{ display: "block" }}>
                  {
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                      onClick={() => {
                        if (isSmallScreen) {
                          navigate(pathList[index], { replace: true });
                          handleDrawerClose();
                        } else {
                          navigate(pathList[index], { replace: true });
                        }
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        {index === 0 ? <Home /> : null}
                        {index === 1 ? <Edit /> : null}
                        {index === 2 ? <Outbound /> : null}
                        {index === 3 ? <Summarize /> : null}
                        {index === 4 ? <Description /> : null}
                        {index === 5 ? <Person /> : null}
                        {index === 6 ? <DriveFileMove /> : null}
                        {index === 7 ? <ImportExport /> : null}
                      </ListItemIcon>
                      <ListItemText
                        primary={text}
                        sx={{
                          opacity: open ? 1 : 0,
                        }}
                      />
                    </ListItemButton>
                  }
                </ListItem>
              );
            })}
          </List>
          <Box flexGrow={1}></Box>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => {
                localStorage.removeItem("auth_token");
                navigate("/login", { replace: true });
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <Logout />
              </ListItemIcon>
              <ListItemText
                primary="ออกจากระบบ"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        </Drawer>
      ) : null}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          backgroundColor: "antiquewhite",
          minHeight: "100vh",
          backgroundAttachment: "fixed",
          width: open ? `calc(100% - ${drawerWidth}px)` : "100%",
        }}
      >
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
}
