import React from "react";
import { TextField, InputAdornment } from "@mui/material";

const CustomTextField = ({
  id,
  label,
  name,
  placeholder,
  icon,
  onChange,
  value = null,
}) => {
  return (
    <TextField
      margin="dense"
      value={value}
      id={id}
      label={label}
      name={name}
      placeholder={placeholder}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">{icon}</InputAdornment>
        ),
        sx: {
          borderRadius: 1,
        },
      }}
      onChange={onChange}
      sx={{
        width: "100%",
      }}
    />
  );
};

export default CustomTextField;
