import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import dayjs from "dayjs";

const ExportBookingTable = (props) => {
  const { listBooking, tableColumns, clickViewDetail } = props;

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", margin: 2 }}>
      <TableContainer sx={{ maxHeight: 600 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {tableColumns.map((column) => (
                <TableCell
                  key={column.id}
                  style={{ minWidth: column.minWidth }}
                  align="center"
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {listBooking.map((data, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  {tableColumns.map((column, index) => {
                    const value = data[column.key] ? data[column.key] : "-";
                    return (
                      <TableCell key={column.id} align="center">
                        {column.key === "cyDate"
                          ? dayjs(value).format("DD/MM/YYYY")
                          : column.key === "loadingDate"
                          ? dayjs(value).format("DD/MM/YYYY")
                          : column.key === "Action"
                          ? ""
                          : value}
                        {column.key === "Action" ? (
                          <React.Fragment>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                              }}
                            >
                              <Button
                                variant="contained"
                                style={{
                                  borderRadius: "50%",
                                  minWidth: 30,
                                  width: 30,
                                  height: 30,
                                  marginLeft: "1rem",
                                  backgroundColor: "#036CFB",
                                }}
                                onClick={() => clickViewDetail(data.booking)}
                              >
                                <VisibilityIcon />
                              </Button>
                            </Box>
                          </React.Fragment>
                        ) : null}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default ExportBookingTable;
