import React from "react";
import { Button, Box, Typography, Grid } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import InsideForm from "../approve-form/components/InsideForm";
import OutsideForm from "../approve-form/components/OutsideForm";

const GateoutDetailView = (props) => {
  const { surveyForm, approveButton, notApproveButton, previousPage } = props;

  const openImageInNewWindow = (imageUrl) => {
    window.open(process.env.REACT_APP_API_URL + imageUrl, "_blank");
  };

  return (
    <Box>
      <Box
        sx={{
          flexGrow: 1,
          p: 3,
          backgroundColor: "white",
          minHeight: "100vh",
          overflow: "auto",
        }}
      >
        <Typography
          fontWeight={"bold"}
          fontSize={24}
          borderBottom="2px solid #EFC531"
          display="inline"
        >
          ข้อมูล
        </Typography>
        <Grid
          container
          spacing={2}
          sx={{
            mt: "0.5rem",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grid item xs={12} md={6} lg={4} display={"flex"}>
            <Typography>Container No.</Typography>
            <Typography ml={2} color={"#1364D7"}>
              {surveyForm.containerId ? surveyForm.containerId : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={4} display={"flex"}>
            <Typography>Booking No.</Typography>
            <Typography ml={2} color={"#1364D7"}>
              {surveyForm.booking ? surveyForm.booking : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={4} display={"flex"}>
            <Typography>Location</Typography>
            <Typography ml={2} color={"#1364D7"}>
              {surveyForm.location ? surveyForm.location : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={4} display={"flex"}>
            <Typography>Size</Typography>
            <Typography ml={2} color={"#1364D7"}>
              {surveyForm.size ? surveyForm.size : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={4} display={"flex"}>
            <Typography>Tare Weight</Typography>
            <Typography ml={2} color={"#1364D7"}>
              {surveyForm.tareWeight ? surveyForm.tareWeight : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={4} display={"flex"}>
            <Typography>Seal No.</Typography>
            <Typography ml={2} color={"#1364D7"}>
              {surveyForm.sealNo ? surveyForm.sealNo : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={4} display={"flex"}>
            <Typography>Container Type</Typography>
            <Typography ml={2} color={"#1364D7"}>
              {surveyForm.containerType ? surveyForm.containerType : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={4} display={"flex"}>
            <Typography>ทะเบียนรถ</Typography>
            <Typography ml={2} color={"#1364D7"}>
              {surveyForm.license}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{
            mt: "1rem",
            display: "flex",
          }}
        >
          <InsideForm insideForm={surveyForm.InsideForm} />
          <OutsideForm outsideForm={surveyForm.OutsideForm} />
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{
            mt: "0.5rem",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grid item xs={12} md={6} lg={4} display={"flex"}></Grid>
          <Grid item xs={12} md={6} lg={4} display={"flex"}></Grid>
        </Grid>
        <Box marginTop={"1rem"}>
          <Typography fontWeight={"bold"} fontSize={18}>
            รูปภาพ
          </Typography>
          <Box
            sx={{
              backgroundColor: "white",
              marginTop: "1rem",
              padding: "1rem",
              borderRadius: "8px",
              border: "1px solid #B9C0E0",
            }}
          >
            {surveyForm.SurveyImages.length > 0 ? (
              <Grid container spacing={2}>
                {surveyForm.SurveyImages.map((image, index) => (
                  <Grid item xs={12} sm={6} md={3} lg={2} key={index}>
                    <img
                      src={process.env.REACT_APP_API_URL + image.imageUrl}
                      alt={`Uploaded ${index}`}
                      style={{
                        width: "100%",
                        height: "auto",
                        minWidth: "100px",
                        minHeight: "100px",
                        borderRadius: "4px",
                        cursor: "zoom-in",
                      }}
                      onClick={() => openImageInNewWindow(image.imageUrl)}
                    />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Box>No image</Box>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            component="label"
            sx={{
              marginTop: "1rem",
              width: "10%",
              backgroundColor: "#5FBD24",
              "&:hover": {
                backgroundColor: "#5FBD24",
              },
              color: "white",
              borderRadius: "10px",
              fontSize: "1rem",
            }}
            onClick={approveButton}
          >
            อนุมัติ
          </Button>
          <Button
            variant="contained"
            component="label"
            sx={{
              marginTop: "1rem",
              width: "10%",
              color: "white",
              borderRadius: "10px",
              fontSize: "1rem",
              backgroundColor: "#DB2828",
              "&:hover": {
                backgroundColor: "#DB2828",
              },
            }}
            onClick={notApproveButton}
          >
            ไม่อนุมัติ
          </Button>
        </Box>
      </Box>
      <Button
        variant="contained"
        component="label"
        startIcon={<ChevronLeftIcon />}
        sx={{
          marginTop: "1rem",
          backgroundColor: "#262527",
          "&:hover": {
            backgroundColor: "#262527",
          },
          color: "white",
          borderRadius: "10px",
          fontSize: "1rem",
        }}
        onClick={previousPage}
      >
        ย้อนกลับ
      </Button>
    </Box>
  );
};

export default GateoutDetailView;
