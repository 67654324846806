import React, { useState, useEffect, useRef } from "react";
import { Box, Button, IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import axios from "axios";

const EditUploadImage = (props) => {
  const { surveyImages, images, setImages, setPendingDeletions } = props;

  const fileInputRef = useRef(null);

  useEffect(() => {
    const fetchedImages = surveyImages.map((img) => ({
      url: process.env.REACT_APP_API_URL + img.imageUrl,
      id: img.id,
      isNew: false,
    }));
    setImages(fetchedImages);
  }, []);

  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);
    const newImages = files.map((file) => ({
      url: URL.createObjectURL(file),
      file,
      id: null,
      isNew: true,
    }));
    setImages((prevImages) => [...prevImages, ...newImages]);
  };

  const handleDeleteImage = (index) => {
    const imageToDelete = images[index];
    if (imageToDelete.id && !imageToDelete.isNew) {
      setPendingDeletions((prev) => [...prev, imageToDelete.id]);
    }
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    if (imageToDelete.isNew) {
      URL.revokeObjectURL(imageToDelete.url);
    }
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  return (
    <Box>
      <input
        type="file"
        ref={fileInputRef}
        accept="image/*"
        multiple
        onChange={handleImageChange}
        style={{ display: "none" }}
      />
      <Button
        variant="contained"
        style={{
          backgroundColor: "#ECBA61",
          color: "black",
          marginBottom: "10px",
          fontSize: 18,
          fontWeight: "bold",
        }}
        onClick={handleClick}
      >
        Upload Image
      </Button>
      <Box display="flex" flexWrap="wrap" gap={2}>
        {images.map((image, index) => (
          <Box key={index} position="relative" width={200} height={200}>
            <img
              src={image.url}
              alt={`upload-${index}`}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
            <IconButton
              onClick={() => handleDeleteImage(index)}
              style={{ position: "absolute", top: 0, right: 0 }}
              color="error"
            >
              <ClearIcon />
            </IconButton>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default EditUploadImage;
