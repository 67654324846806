import React, { useEffect } from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import HomeView from "./features/home/HomeView";
import LoginController from "./features/login/LoginController";
import Appbar from "./features/navigation/MiniDrawer";
import UserController from "./features/user/UserController";
import AddUserController from "./features/user/AddUserController";
import FormManagementController from "./features/form-management/FormManagementController";
import ApproveFormController from "./features/approve-form/ApproveFormController";
import ApproveFormDetailController from "./features/approve-form/ApproveFormDetailController";
import RequestFormController from "./features/request-form/RequestFormController";
import FormDetailController from "./features/form-management/FormDetailController";
import SurveyFormDetailView from "./features/form-management/SurveyFormDetailView";
import ChangeFormDetailController from "./features/form-management/ChangeFormDetailController";
import ChangeSurveyFormDetailController from "./features/form-management/ChangeSurveyFormDetailController";
import ChangeUserController from "./features/user/ChangeUserController";
import ExportController from "./features/export/ExportController";
import SurveyFormsDetailView from "./features/form-management/SurveyFormDetailView";
import { useDialog } from "./context/dialog_context";
import DailyExportController from "./features/daily-export/DailyExportController";
import GateoutController from "./features/gate-out/GateoutController";
import GateoutDetailController from "./features/gate-out/GateoutDetailController";

const AuthRoute = ({ children }) => {
  const location = useLocation();
  const { showDialog } = useDialog();

  const token = localStorage.getItem("auth_token");

  const navigate = useNavigate();

  useEffect(() => {
    const handleUnauthorized = () => {
      localStorage.removeItem("auth_token");
      navigate("/login");
      showDialog("เซลชั่นหมดอายุ", "กรุณา Login ใหม่อีกครั้ง");
    };

    window.addEventListener("unauthorized", handleUnauthorized);

    return () => {
      window.removeEventListener("unauthorized", handleUnauthorized);
    };
  }, [navigate]);

  // If not authenticated
  if (!token) {
    // If not already on the login page
    if (location.pathname !== "/login") {
      // Redirect to the login page and pass the original location they tried to access
      return <Navigate to="/login" state={{ from: location }} />;
    }
  }
  return children;
};

function App() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <AuthRoute>
            <Appbar>
              <HomeView />
            </Appbar>
          </AuthRoute>
        }
      />
      <Route
        path="/user"
        element={
          <AuthRoute>
            <Appbar>
              <UserController />
            </Appbar>
          </AuthRoute>
        }
      />
      <Route
        path="/user/add"
        element={
          <AuthRoute>
            <Appbar>
              <AddUserController />
            </Appbar>
          </AuthRoute>
        }
      />
      <Route
        path="/user/:userId/change"
        element={
          <AuthRoute>
            <Appbar>
              <ChangeUserController />
            </Appbar>
          </AuthRoute>
        }
      />
      <Route
        path="/form-management"
        element={
          <AuthRoute>
            <Appbar>
              <FormManagementController />
            </Appbar>
          </AuthRoute>
        }
      />
      <Route
        path="/approve-form"
        element={
          <AuthRoute>
            <Appbar>
              <ApproveFormController />
            </Appbar>
          </AuthRoute>
        }
      />
      <Route
        path="/approve-form/:id"
        element={
          <AuthRoute>
            <Appbar>
              <ApproveFormDetailController />
            </Appbar>
          </AuthRoute>
        }
      />
      <Route
        path="/form"
        element={
          <AuthRoute>
            <Appbar>
              <RequestFormController />
            </Appbar>
          </AuthRoute>
        }
      />
      <Route
        path="/report"
        element={
          <AuthRoute>
            <Appbar>
              <ExportController />
            </Appbar>
          </AuthRoute>
        }
      />
      <Route
        path="/form-management/:bookingId"
        element={
          <AuthRoute>
            <Appbar>
              <FormDetailController />
            </Appbar>
          </AuthRoute>
        }
      />
      <Route
        path="/form-management/:bookingId/change"
        element={
          <AuthRoute>
            <Appbar>
              <ChangeFormDetailController />
            </Appbar>
          </AuthRoute>
        }
      />
      <Route
        path="/form-management/:bookingId/survey/:surveyFormId"
        element={
          <AuthRoute>
            <Appbar>
              <SurveyFormDetailView />
            </Appbar>
          </AuthRoute>
        }
      />
      <Route
        path="/form-management/:bookingId/survey/:surveyFormId/change"
        element={
          <AuthRoute>
            <Appbar>
              <ChangeSurveyFormDetailController />
            </Appbar>
          </AuthRoute>
        }
      />
      <Route
        path="/survey/:surveyFormId"
        element={
          <AuthRoute>
            <Appbar>
              <SurveyFormsDetailView />
            </Appbar>
          </AuthRoute>
        }
      />
      <Route
        path="/daily-export"
        element={
          <AuthRoute>
            <Appbar>
              <DailyExportController />
            </Appbar>
          </AuthRoute>
        }
      />
      <Route
        path="/gate-out"
        element={
          <AuthRoute>
            <Appbar>
              <GateoutController />
            </Appbar>
          </AuthRoute>
        }
      />
      <Route
        path="/gate-out/:bookingId"
        element={
          <AuthRoute>
            <Appbar>
              <FormDetailController />
            </Appbar>
          </AuthRoute>
        }
      />
      <Route
        path="/gate-out-detail/:id"
        element={
          <AuthRoute>
            <Appbar>
              <GateoutDetailController />
            </Appbar>
          </AuthRoute>
        }
      />
      <Route path="/login" element={<LoginController />} />
    </Routes>
  );
}

export default App;
