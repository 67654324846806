import React, { useState, useRef } from "react";
import { Box, Button, IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

const ImageUpload = (props) => {
  const {
    images,
    handleImageChange,
    handleDeleteImage,
    handleClick,
    fileInputRef,
  } = props;

  return (
    <Box>
      <input
        type="file"
        ref={fileInputRef}
        accept="image/*"
        multiple
        onChange={handleImageChange}
        style={{ display: "none" }}
      />
      <Button
        variant="contained"
        style={{
          backgroundColor: "#ECBA61",
          color: "black",
          marginBottom: "10px",
          fontSize: 18,
          fontWeight: "bold",
        }}
        onClick={handleClick}
      >
        Upload Image
      </Button>
      <Box display="flex" flexWrap="wrap" gap={2}>
        {images.map((image, index) => (
          <Box key={index} position="relative" width={200} height={200}>
            <img
              src={image.url}
              alt={`upload-${index}`}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
            <IconButton
              onClick={() => handleDeleteImage(index)}
              style={{ position: "absolute", top: 0, right: 0 }}
              color="error"
            >
              <ClearIcon />
            </IconButton>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ImageUpload;
