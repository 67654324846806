import React, { useState, useEffect } from "react";
import ApproveFormDetailView from "./ApproveFormDetailView";
import { useParams, useNavigate } from "react-router-dom";
import ApiHelper from "../../api/api_helper";
import LoadingIndicator from "../loading/LoadingIndicator";

const ApproveFormDetailController = () => {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [surveyForm, setSurveyForm] = useState(null);
  const [apiError, setApiError] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    getSurveyFormDetail();
  }, []);

  const getSurveyFormDetail = async () => {
    try {
      setIsLoading(true);
      const response = await ApiHelper.get(`/survey/${id}`);
      if (response.status !== 200) {
        throw response;
      }
      setSurveyForm(response.data.data);
    } catch (error) {
      setApiError(error.data);
    } finally {
      setIsLoading(false);
    }
  };

  const approveButton = async () => {
    try {
      setIsLoading(true);
      let updateSurveyData = surveyForm;
      updateSurveyData.updateFrom = "approve";
      updateSurveyData.isConfirm = "accepted";
      const response = await ApiHelper.put(
        `survey/${id}/change`,
        updateSurveyData
      );

      if (response.status !== 200) {
        throw response;
      }
      navigate(-1);
    } catch (error) {
      setApiError(error.data);
    } finally {
      setIsLoading(false);
    }
  };

  const notApproveButton = async () => {
    try {
      setIsLoading(true);
      let updateSurveyData = surveyForm;
      updateSurveyData.isConfirm = "rejected";
      const response = await ApiHelper.put(
        `survey/${id}/change`,
        updateSurveyData
      );

      if (response.status !== 200) {
        throw response;
      }
      navigate(-1);
    } catch (error) {
      setApiError(error.data);
    } finally {
      setIsLoading(false);
    }
  };

  const previousPage = () => {
    navigate(-1);
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <ApproveFormDetailView
      surveyForm={surveyForm}
      approveButton={approveButton}
      notApproveButton={notApproveButton}
      previousPage={previousPage}
    />
  );
};

export default ApproveFormDetailController;
