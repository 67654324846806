import React from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  TextField,
  Autocomplete,
} from "@mui/material";
import SurveyFormTable from "../approve-form/components/SurveyFormTable";

const GateoutView = (props) => {
  const {
    listBooking,
    handleBookingChange,
    clickViewDetailOnSurveyWaiting,
    listSurveyForm,
    tableColumns,
    clickSearchBooking,
    onClickApprove,
    onClickReject,
  } = props;

  return (
    <Box
      sx={{
        flexGrow: 1,
        p: 3,
        backgroundColor: "white",
        minHeight: "100vh",
        overflow: "auto",
      }}
    >
      <Typography
        fontWeight={"bold"}
        fontSize={24}
        borderBottom="2px solid #EFC531"
        display="inline"
      >
        ระบบ Gate out
      </Typography>
      <Box>
        <Grid
          container
          spacing={2}
          sx={{
            marginTop: "0.5rem",
            display: "flex",
            alignItems: "center",
            marginBottom: "1%",
          }}
        >
          <Grid item xs={12} display={"flex"}>
            <Autocomplete
              onInputChange={handleBookingChange}
              options={listBooking}
              getOptionLabel={(option) => option.booking}
              renderInput={(params) => (
                <TextField {...params} label="Booking" />
              )}
              sx={{
                width: "50%",
                "@media (max-width: 960px)": {
                  width: "100%",
                },
              }}
            />
            <Button
              variant="contained"
              sx={{
                marginLeft: "1rem",
                borderRadius: 4,
                color: "black",
                backgroundColor: "#EFC531",
                "&:hover": {
                  backgroundColor: "#EFC531",
                },
                "@media (max-width: 960px)": {
                  marginLeft: "0",
                  marginTop: "1rem",
                },
              }}
              onClick={clickSearchBooking}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
        <SurveyFormTable
          listSurveyForm={listSurveyForm}
          tableColumns={tableColumns}
          clickViewDetail={clickViewDetailOnSurveyWaiting}
          openFrom={"gateOut"}
          approveGateOut={onClickApprove}
          rejectGateOut={onClickReject}
        />
      </Box>
    </Box>
  );
};

export default GateoutView;
