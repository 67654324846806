import React from "react";
import { Grid, Box, Typography, Divider } from "@mui/material";

const InsideForm = (props) => {
  const { insideForm } = props;

  return (
    <Grid item xs={12} lg={6} display={"flex"}>
      <Box
        sx={{
          backgroundColor: "white",
          margin: "0 1%",
          padding: "1rem",
          borderRadius: "8px",
          border: "1px solid #B9C0E0",
          width: "100%",
        }}
      >
        <Typography
          fontWeight={"bold"}
          fontSize={16}
          margin={"1rem"}
          align="center"
        >
          INSIDE
        </Typography>
        <Divider sx={{ borderColor: "#B9C0E0" }} />
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          marginTop={"1rem"}
        >
          <Typography>DRY (แห้งสะอาด)</Typography>
          <Typography color={"#1364D7"}>{insideForm.dry}</Typography>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          marginTop={"1rem"}
        >
          <Typography>GOUGE (เสี้ยน)</Typography>
          <Typography color={"#1364D7"}>{insideForm.gouge}</Typography>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          marginTop={"1rem"}
        >
          <Typography>LIGHT (รอยรั่ว)</Typography>
          <Typography color={"#1364D7"}>{insideForm.light}</Typography>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          marginTop={"1rem"}
        >
          <Typography>DIRTY (คราบสกปรก)</Typography>
          <Typography color={"#1364D7"}>{insideForm.dirty}</Typography>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          marginTop={"1rem"}
        >
          <Typography>RUSTY (สนิม)</Typography>
          <Typography color={"#1364D7"}>{insideForm.rusty}</Typography>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          marginTop={"1rem"}
        >
          <Typography>ODOR (กลิ่นเหม็น)</Typography>
          <Typography color={"#1364D7"}>{insideForm.odor}</Typography>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          marginTop={"1rem"}
        >
          <Typography>NAIL (ตะปู)</Typography>
          <Typography color={"#1364D7"}>{insideForm.nail}</Typography>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          marginTop={"1rem"}
        >
          <Typography>INSECT (ซากสัตว์)</Typography>
          <Typography color={"#1364D7"}>{insideForm.insect}</Typography>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          marginTop={"1rem"}
        >
          <Typography>OLD GOODS (สินค้าเก่า)</Typography>
          <Typography color={"#1364D7"}>{insideForm.oldGoods}</Typography>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          marginTop={"1rem"}
        >
          <Typography>DENT (บุบ ยุบ)</Typography>
          <Typography color={"#1364D7"}>{insideForm.dentInside}</Typography>
        </Box>
      </Box>
    </Grid>
  );
};

export default InsideForm;
