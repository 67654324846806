import React, { useEffect, useState } from "react";
import FormDetailView from "./FormDetailView";
import { useParams, useNavigate } from "react-router-dom";
import ApiHelper from "../../api/api_helper";
import LoadingIndicator from "../loading/LoadingIndicator";
import { useDialog } from "../../context/dialog_context";
import {
  Dialog,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Typography,
  Autocomplete,
} from "@mui/material";

const FormDetailController = () => {
  const { bookingId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [booking, setBooking] = useState(null);
  const [apiError, setApiError] = useState(null);
  const [page, setPage] = useState(0);
  const { showDialog } = useDialog();
  const [openGateOutPopup, setOpenGatOutPopup] = useState(false);
  const [trailer, setTrailer] = useState();
  const [truckLicense, setTruckLicense] = useState("");
  const [selectSurvey, setSelectSurvey] = useState();
  const [openConfirmCancel, setOpenConfirmCancel] = useState(false);
  const [openSwapDialog, setOpenSwapDialog] = useState(false);
  const [searchBooking, setSearchBooking] = useState("");
  const [openConfirmGateOutPopup, setOpenConfirmGateOutPopup] = useState(false);
  const [openRejectedGateOutPopup, setOpenRejectedGateOutPopup] =
    useState(false);

  const handleOpenGatOutPopup = (surveyForm, functionKey) => {
    setSelectSurvey(surveyForm);
    if (surveyForm.gateStatus === "out") {
      setOpenConfirmCancel(true);
    } else if (
      surveyForm.gateStatus === "waiting" &&
      functionKey === "confirmGateOut"
    ) {
      setOpenConfirmGateOutPopup(true);
    } else if (
      surveyForm.gateStatus === "waiting" &&
      functionKey === "rejectedGateOut"
    ) {
      setOpenRejectedGateOutPopup(true);
    } else {
      if (
        surveyForm.OutsideForm.status !== "AV" &&
        surveyForm.gateStatus !== "out"
      ) {
        showDialog(
          "Error",
          "จะเปลี่ยนสถานะ Gateout ได้ ตู้นี้ต้องเป็น AV ก่อน"
        );
        return;
      }
      setOpenGatOutPopup(true);
    }
  };

  const handleCloseGatOutPopup = () => {
    setOpenGatOutPopup(false);
  };

  const handleCloseSwapDialog = () => {
    setOpenSwapDialog(false);
  };

  const handleCloseConfirmGateOupPopup = () => {
    setOpenConfirmGateOutPopup(false);
  };

  const handleCloseRejectedGateOupPopup = () => {
    setOpenRejectedGateOutPopup(false);
  };

  const handleSubmitCancelGateOut = async () => {
    try {
      setIsLoading(true);

      let updateSurveyData = {
        ...selectSurvey,
        gateStatus: "in",
        gateOutDate: null,
        trailer: null,
        truckLicense: null,
      };
      const response = await ApiHelper.put(
        `survey/${updateSurveyData.id}/change`,
        updateSurveyData
      );

      if (response.status !== 200) {
        throw new Error("Failed to update status");
      }

      showDialog("อัพเดทข้อมูลสำเร็จ", "อัพเดทข้อมูลเรียบร้อย");
      getBookingDetail();
    } catch (error) {
      showDialog("Error", error.message || "An error occurred");
      setApiError(error.data);
    } finally {
      setIsLoading(false);
      setOpenConfirmCancel(false); // Close the modal on success or failure
    }
  };

  const handleSubmitGateOut = async () => {
    try {
      setIsLoading(true);

      let updateSurveyData = {
        ...selectSurvey,
        trailer,
        truckLicense,
        gateStatus: "waiting",
        updateFrom: "gateOut",
      };
      const response = await ApiHelper.put(
        `survey/${updateSurveyData.id}/change`,
        updateSurveyData
      );

      if (response.status !== 200) {
        throw new Error("Failed to update status");
      }

      showDialog("อัพเดทข้อมูลสำเร็จ", "อัพเดทข้อมูลเรียบร้อย");
      getBookingDetail();
    } catch (error) {
      showDialog("Error", error.message || "An error occurred");
      setApiError(error.data);
    } finally {
      setIsLoading(false);
      handleCloseGatOutPopup(false); // Close the modal on success or failure
      setTrailer("");
      setTruckLicense("");
    }
  };

  // Use Navigate
  const navigate = useNavigate();

  useEffect(() => {
    getBookingDetail();
  }, []);

  // Define Table Columns
  const tableColumns = [
    { id: "1", label: "ลำดับ", minWidth: 50, key: "id" },
    { id: "3", label: "Container ID", minWidth: 100, key: "containerId" },
    { id: "10", label: "Size", minWidth: 100, key: "size" },
    { id: "5", label: "Status", minWidth: 100, key: "status" },
    { id: "11", label: "Tare Weight", minWidth: 100, key: "tareWeight" },
    { id: "12", label: "Seal No", minWidth: 100, key: "sealNo" },
    { id: "13", label: "Cy Place", minWidth: 100, key: "surveyCyPlace" },
    { id: "2", label: "วันที่ Gate In", minWidth: 100, key: "approveDate" },
    { id: "4", label: "วันที่ Gate Out", minWidth: 100, key: "gateOutDate" },
    { id: "9", label: "Truck License", minWidth: 100, key: "truckLicense" },
    { id: "8", label: "Trailer", minWidth: 100, key: "trailer" },
    { id: "6", label: "ดูรายละเอียด", minWidth: 100, key: "Detail" },
    { id: "7", label: "การจัดการ", minWidth: 100, key: "Action" },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const previousPage = () => {
    navigate(-1);
  };

  const getBookingDetail = async () => {
    try {
      setIsLoading(true);
      const response = await ApiHelper.get(
        `/booking/check?booking=${bookingId}`
      );
      if (response.status !== 200) {
        throw response;
      }
      setBooking(response.data.data);
    } catch (error) {
      setApiError(error.data);
    } finally {
      setIsLoading(false);
    }
  };

  const changeSurveyFormDetail = (id) => {
    const encodeBookingId = encodeURIComponent(id);
    navigate(`/form-management/${encodeBookingId}/survey/${id}/change`);
  };

  const clickViewSurveyFormDetail = (id) => {
    const encodeBookingId = encodeURIComponent(id);
    navigate(`/form-management/${encodeBookingId}/survey/${id}`);
  };

  const clickGateInAndGateOut = (surveyForm, functionKey = "") => {
    handleOpenGatOutPopup(surveyForm, functionKey);
  };

  const clickViewDetailOnSurveyWaiting = (id) => {
    navigate(`/gate-out-detail/${id}`);
  };

  const [listBooking, setListBooking] = useState([]);

  useEffect(() => {
    if (searchBooking) {
      getListBooking();
    }
  }, [searchBooking]);

  const getListBooking = async () => {
    try {
      const response = await ApiHelper.get(
        `/booking/bookings?cyDate=null&loadingDate=null&ci=null&booking=${searchBooking}&bookingStatus=all`
      );
      setIsLoading(true);
      if (response.status !== 200) {
        throw response;
      }
      setListBooking(response.data.data);
    } catch (error) {
      setApiError(error.data);
    } finally {
      setIsLoading(false);
    }
  };

  const onClickSwap = (surveyForm) => {
    setSelectSurvey(surveyForm);
    setOpenSwapDialog(true);
  };

  const handleSearchBooking = (event, newValue) => {
    setSearchBooking(newValue);
  };

  const submitSwap = async () => {
    try {
      let updateSurveyData = {
        ...selectSurvey,
        booking: searchBooking,
      };
      setIsLoading(true);
      const response = await ApiHelper.put(
        `survey/${selectSurvey.id}/change`,
        updateSurveyData
      );

      if (response.status !== 200) {
        throw new Error("Failed to update status");
      }

      showDialog("อัพเดทข้อมูลสำเร็จ", "อัพเดทข้อมูลเรียบร้อย");
      getBookingDetail();
    } catch (error) {
      showDialog("Error", error.message || "An error occurred");
      setApiError(error.data);
    } finally {
      setIsLoading(false);
      handleCloseSwapDialog(false);
    }
  };

  const approveButton = async () => {
    try {
      setIsLoading(true);
      let updateSurveyData = selectSurvey;
      updateSurveyData.updateFrom = "approveGateOut";
      updateSurveyData.gateStatus = "out";
      const response = await ApiHelper.put(
        `survey/${updateSurveyData.id}/change`,
        updateSurveyData
      );

      if (response.status !== 200) {
        throw response;
      }
      showDialog("อัพเดทข้อมูลสำเร็จแล้ว", "");
      getBookingDetail();
    } catch (error) {
      showDialog("เกิดข้อผิดพลาด", error.message);
      setApiError(error.data);
    } finally {
      setIsLoading(false);
      handleCloseConfirmGateOupPopup();
    }
  };

  const notApproveButton = async () => {
    try {
      setIsLoading(true);
      let updateSurveyData = selectSurvey;
      updateSurveyData.gateStatus = "in";
      updateSurveyData.requestGateOutDate = null;
      const response = await ApiHelper.put(
        `survey/${updateSurveyData.id}}/change`,
        updateSurveyData
      );

      if (response.status !== 200) {
        throw response;
      }
      showDialog("อัพเดทข้อมูลสำเร็จแล้ว", "");
      getBookingDetail();
      handleCloseRejectedGateOupPopup();
    } catch (error) {
      showDialog("เกิดข้อผิดพลาด", error.message);
      setApiError(error.data);
    } finally {
      setIsLoading(false);
      handleCloseRejectedGateOupPopup();
    }
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <div>
      <FormDetailView
        clickGateInAndGateOut={clickGateInAndGateOut}
        booking={booking}
        tableColumns={tableColumns}
        changeSurveyFormDetail={changeSurveyFormDetail}
        clickViewSurveyFormDetail={clickViewSurveyFormDetail}
        previousPage={previousPage}
        handleChangePage={handleChangePage}
        page={page}
        onClickSwap={onClickSwap}
        clickViewDetailOnSurveyWaiting={clickViewDetailOnSurveyWaiting}
      />
      <Dialog open={openGateOutPopup} onClose={handleCloseGatOutPopup}>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Trailer"
            type="text"
            fullWidth
            variant="standard"
            value={trailer}
            onChange={(e) => setTrailer(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Truck License"
            type="text"
            fullWidth
            variant="standard"
            value={truckLicense}
            onChange={(e) => setTruckLicense(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              marginTop: "1rem",
              width: "10%",
              color: "white",
              borderRadius: "10px",
              fontSize: "1rem",
              backgroundColor: "#ED5768",
              "&:hover": {
                backgroundColor: "#ED5768",
              },
            }}
            onClick={handleCloseGatOutPopup}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              marginTop: "1rem",
              width: "10%",
              color: "white",
              borderRadius: "10px",
              fontSize: "1rem",
              backgroundColor: "#4AAEAF",
              "&:hover": {
                backgroundColor: "#4AAEAF",
              },
            }}
            onClick={handleSubmitGateOut}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openConfirmCancel} onClose={handleSubmitCancelGateOut}>
        <DialogContent>
          <Typography sx={{ fontWeight: "bold", fontSize: 32 }}>
            คุณต้องการที่จะดึงตู้กลับ ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              marginTop: "1rem",
              width: "10%",
              color: "white",
              borderRadius: "10px",
              fontSize: "1rem",
              backgroundColor: "#ED5768",
              "&:hover": {
                backgroundColor: "#ED5768",
              },
            }}
            onClick={() => setOpenConfirmCancel(false)}
          >
            ยกเลิก
          </Button>
          <Button
            variant="contained"
            sx={{
              marginTop: "1rem",
              width: "10%",
              color: "white",
              borderRadius: "10px",
              fontSize: "1rem",
              backgroundColor: "#4AAEAF",
              "&:hover": {
                backgroundColor: "#4AAEAF",
              },
            }}
            onClick={handleSubmitCancelGateOut}
          >
            ยืนยัน
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openSwapDialog} onClose={handleCloseSwapDialog}>
        <DialogContent>
          <Autocomplete
            onInputChange={handleSearchBooking}
            options={listBooking}
            getOptionLabel={(option) => option.booking}
            renderInput={(params) => (
              <TextField {...params} label="Select Booking" />
            )}
            sx={{
              width: "500px",
              "@media (max-width: 960px)": {
                width: "100%",
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              marginTop: "1rem",
              width: "10%",
              color: "white",
              borderRadius: "10px",
              fontSize: "1rem",
              backgroundColor: "#ED5768",
              "&:hover": {
                backgroundColor: "#ED5768",
              },
            }}
            onClick={handleCloseSwapDialog}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              marginTop: "1rem",
              width: "10%",
              color: "white",
              borderRadius: "10px",
              fontSize: "1rem",
              backgroundColor: "#4AAEAF",
              "&:hover": {
                backgroundColor: "#4AAEAF",
              },
            }}
            onClick={submitSwap}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openConfirmGateOutPopup}
        onClose={handleCloseConfirmGateOupPopup}
      >
        <DialogContent>
          <Typography sx={{ fontWeight: "bold", fontSize: 32 }}>
            คุณต้องการที่จะ Gate out ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              marginTop: "1rem",
              width: "10%",
              color: "white",
              borderRadius: "10px",
              fontSize: "1rem",
              backgroundColor: "#ED5768",
              "&:hover": {
                backgroundColor: "#ED5768",
              },
            }}
            onClick={handleCloseConfirmGateOupPopup}
          >
            ยกเลิก
          </Button>
          <Button
            variant="contained"
            sx={{
              marginTop: "1rem",
              width: "10%",
              color: "white",
              borderRadius: "10px",
              fontSize: "1rem",
              backgroundColor: "#4AAEAF",
              "&:hover": {
                backgroundColor: "#4AAEAF",
              },
            }}
            onClick={approveButton}
          >
            ยืนยัน
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openRejectedGateOutPopup}
        onClose={handleCloseRejectedGateOupPopup}
      >
        <DialogContent>
          <Typography
            sx={{ fontWeight: "bold", fontSize: 32, textAlign: "center" }}
          >
            คุณต้องการที่จะยกเลิกการรอการ Gate out ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              marginTop: "1rem",
              width: "10%",
              color: "white",
              borderRadius: "10px",
              fontSize: "1rem",
              backgroundColor: "#ED5768",
              "&:hover": {
                backgroundColor: "#ED5768",
              },
            }}
            onClick={handleCloseRejectedGateOupPopup}
          >
            ยกเลิก
          </Button>
          <Button
            variant="contained"
            sx={{
              marginTop: "1rem",
              width: "10%",
              color: "white",
              borderRadius: "10px",
              fontSize: "1rem",
              backgroundColor: "#4AAEAF",
              "&:hover": {
                backgroundColor: "#4AAEAF",
              },
            }}
            onClick={notApproveButton}
          >
            ยืนยัน
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FormDetailController;
